import useAppDispatch from "hooks/useAppDispatch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  FormLayout,
  Select,
  TextField,
} from "@shopify/polaris";
import Segmented from "components/Segmented";
import Modal from "components/Modal";
import { addBankToList, editBankInList } from '../../../../redux/features/documents/documentsSlice'
import { currencies } from "./constants";

const initialBankingInfo = {
  location: "local",
};

const BankingInfo = (props) => {
  const dispatch = useAppDispatch();
  const { data, onClose: handleClose, isOpen } = props;
  const [bankingInfo, setBankingInfo] = useState(initialBankingInfo);
  const { addingBankLoading, editingBankLoading } = useSelector(
    (state) => state.documents
  );

  useEffect(() => {
    const { type, ...bankingInfo } = data;

    if (type === "update") {
      setBankingInfo(bankingInfo);
    }
  }, [data]);

  const clearBankingInfo = () => setBankingInfo(initialBankingInfo);

  const getModalPropertiesByType = (type) => {
    switch (type) {
      case "create":
        return {
          loading: addingBankLoading,
          title: "Add new banking info",
          submitAction: addBankToList,
          submitButton: "Submit",
        };
      case "update":
        return {
          loading: editingBankLoading,
          title: `Edit ${data?.name}`,
          submitAction: editBankInList,
          submitButton: "Edit",
        };
      default:
        return {};
    }
  };

  const getCurrentBankingInfo = () => {
    const { bank_currency, location } = bankingInfo;
    const isGlobalSegment = location === "global";

    if (bank_currency) {
      if (isGlobalSegment) {
        return currencies[location];
      }

      return currencies[location][bank_currency];
    }

    return [];
  };

  const { loading, title, submitAction, submitButton } =
    getModalPropertiesByType(data?.type);

  const onClose = () => {
    handleClose();
    clearBankingInfo();
  };

  const onSubmit = () => {
    dispatch(submitAction(bankingInfo)).then(() => {
      onClose();
    });
  };
  const onChange = (id, newValue) => {
    setBankingInfo((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  const setBankCurrency = (newValue) => {
    setBankingInfo((prev) => ({
      location: prev.location,
      bank_currency: newValue,
    }));
  };

  return (
    <Modal
      title={title}
      infoIconVisible={false}
      visible={isOpen}
      onClose={onClose}
      content={
        <>
          <FormLayout>
            <Select
              id='bank_currency'
              label='Currency'
              options={[
                { value: "", label: "" },
                { value: "eur", label: "EUR" },
                { value: "usd", label: "USD" },
              ]}
              value={bankingInfo.bank_currency}
              onChange={(newValue) => setBankCurrency(newValue)}
            />
            <Segmented
              segments={[
                { value: "local", label: "Local" },
                { value: "global", label: "Global" },
              ]}
              activeSegment={bankingInfo.location}
              onSegmentClick={(value) => onChange("location", value)}
              label='Currency details'
            />
            {getCurrentBankingInfo().map((templateElement, index) => {
              const { type, props } = templateElement;
              // console.log('props.id', props.id)
              return (
                <div key={index}>
                  {type === "input" && (
                    <TextField
                      {...props}
                      value={bankingInfo[props.id]}
                      onChange={(value) => onChange(props.id, value)}
                    />
                  )}
                    {type === "select" && (
                      <Select
                        {...props}
                        options={props.options} // передаем опции для выпадающего списка
                        value={bankingInfo[props.id]}
                        onChange={(value) => onChange(props.id, value)}
                      />
                    )}
                </div>
              );
            })}
          </FormLayout>
        </>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={onSubmit} loading={loading}>
            {submitButton}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default BankingInfo;
