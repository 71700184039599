import {
  AlphaStack, Box,
  Card,
  DropZone,
  FormLayout,
  Tag,
  Text,
  TextField,
} from "@shopify/polaris";
import {
  calculateSubtotals,
  calculateTotalTaxAmount,
} from "containers/DocumentTemplate/common/utils";
import _, { uniqueId } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "utils/currency";
import { formatMonth } from "utils/dates";
import { formatMoney } from "utils/numbers";
import { DocumentType } from "../../../../common/constants";
import InvoicePreviewSaveBar from "containers/DocumentTemplate/components/InvoicePreviewSaveBar";
import ExemptionPreviewSaveBar from "containers/DocumentTemplate/components/ExemptionPreviewSaveBar";

const documentTypeStrings = {
  [DocumentType.InvoiceVAT]: "Invoice",
  [DocumentType.InvoiceSalesTax]: "Invoice",
  [DocumentType.Exemption]: "Exemption",
};

const Preview = ({ setStepIndex, data, documentType }) => {
  const { line_items, taxId } = data;
  const documentTypeString = documentTypeStrings[documentType];
  const {
    invoice: { previewUrl: invoicePreviewUrl },
    exemption: { previewUrl: exemptionPreviewUrl },
    customers,
    taxes,
  } = useSelector((state) => state.documents);
  const { user } = useSelector((state) => state.user);
  const [previewInfo, setPreviewInfo] = useState({});
  const [attachment, setAttachment] = useState({});

  const onChange = (id, newValue) => {
    setPreviewInfo((prev) => ({
      ...prev,
      [id]: newValue,
    }));
  };

  const subtotals = useMemo(() => {
    return calculateSubtotals(line_items);
  }, [line_items]);

  const totalTaxAmount = useMemo(() => {
    return calculateTotalTaxAmount(line_items, taxId, taxes);
  }, [line_items, taxId, taxes]);

  useEffect(() => {
    getInitialValues();
  }, []);

  const getInitialValues = () => {
    const { customerId, invoice_number, due_date, currency } = data;
    const customer = customers.find((c) => String(c.id) === customerId);
    const formattedDueDate = formatMonth(due_date);
    const companyName = user.company.name;
    const amountDue = `${getCurrencySymbol(currency)} ${formatMoney(
      subtotals + totalTaxAmount
    )}`;
    const subject = `${documentTypeString} ${invoice_number} due ${formattedDueDate} | ${companyName}`;
    const body = `${
      customer.name
    }, here is your ${documentTypeString.toLowerCase()} from ${companyName}.
${documentTypeString} ${invoice_number}
Amount due: ${amountDue}
Due: ${formattedDueDate}

Thanks,
${companyName}

Attached is a PDF copy for your records`;

    setPreviewInfo({
      send_to: customer.email,
      reply_to: user.company.email,
      subject,
      body,
    });
  };

  const onDropFile = (files) => {
    setAttachment(() => {
      return files.map((file) => ({
        id: uniqueId(),
        file,
      }));
    });
  };

  const onRemoveFile = (id) => {
    setAttachment((prev) => {
      return prev.filter((file) => file.id !== id);
    });
  };

  return (
    <>
      <FormLayout>
        <FormLayout.Group>
          <Card sectioned>
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              width="100%"
            >
              <TextField
                id='send_to'
                type='text'
                inputMode='text'
                placeholder='Enter email address'
                label='To'
                value={previewInfo.send_to}
                onChange={(newValue) => onChange("send_to", newValue)}
              />

              <TextField
                id='reply_to'
                type='email'
                inputMode='email'
                placeholder='Enter email address'
                label='Reply to'
                value={previewInfo.reply_to}
                onChange={(newValue) => onChange("reply_to", newValue)}
              />

              <TextField
                id='subject'
                type='text'
                inputMode='text'
                placeholder='Enter subject'
                label='Subject'
                value={previewInfo.subject}
                onChange={(newValue) => onChange("subject", newValue)}
              />

              <TextField
                id='body'
                type='text'
                inputMode='text'
                placeholder='Enter email body'
                label='Body'
                multiline={10}
                value={previewInfo.body}
                onChange={(newValue) => onChange("body", newValue)}
              />

              <>
                <Text>Attachments</Text>
                {_.isEmpty(attachment) ? (
                  <DropZone onDrop={onDropFile}>
                    <DropZone.FileUpload actionTitle='Upload file' />
                  </DropZone>
                ) : (
                  attachment.map(({ id, file }) => {
                    return (
                      <Tag onRemove={() => onRemoveFile(id)} key={id}>
                        {file.name}
                      </Tag>
                    );
                  })
                )}
              </>
            </Box>
          </Card>
          <iframe
            src={`${
              documentType === DocumentType.Exemption
                ? exemptionPreviewUrl
                : invoicePreviewUrl
            }#zoom=FitW`}
            style={{ width: "100%", height: 700 }}
            title='invoice preview'
          ></iframe>
        </FormLayout.Group>
      </FormLayout>
      {(documentType === DocumentType.InvoiceSalesTax ||
        documentType === DocumentType.InvoiceVAT) && (
        <InvoicePreviewSaveBar
          data={data}
          setStepIndex={setStepIndex}
          attachment={attachment}
          previewInfo={previewInfo}
        />
      )}
      {documentType === DocumentType.Exemption && (
        <ExemptionPreviewSaveBar
          data={data}
          setStepIndex={setStepIndex}
          attachment={attachment}
          previewInfo={previewInfo}
        />
      )}
    </>
  );
};

export default Preview;
