import React, { Component } from "react";
import { Card, Checkbox, Text } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import {
  fetchUndisclosedScheme,
  switchUndisclosedScheme,
} from "../../redux/features/uk-settings/ukSettingsSlice";
import { createCustomDispatch } from "helpers/customDispatch";

class UKSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { undisclosed, fetchingUndisclosed } = this.props;

    return (
      <Card sectioned>
        <Checkbox
          label={"Undisclosed agent scheme"}
          disabled={fetchingUndisclosed}
          helpText={
            <Text>
              Acting in your own name (OMP are treated as principal for VAT
              purposes and seen as taking a full part in the underlying supply
              of any goods or services).
            </Text>
          }
          checked={undisclosed}
          onChange={(value) => {
            this.props
              .switchUndisclosedScheme({ undisclosed_agent_scheme: value })
              .then(() => this.props.fetchUndisclosedScheme())
              .catch(_.noop);
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  undisclosed: state.ukSettings.undisclosed,
  fetchingUndisclosed: state.ukSettings.fetchingUndisclosed,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUndisclosedScheme: () => dispatch(fetchUndisclosedScheme()),
    switchUndisclosedScheme: (params) =>
      dispatch(switchUndisclosedScheme(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UKSettings))
);
