import { Card, Checkbox, } from '@shopify/polaris'
import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/dates'

const CancelSubscriptionStepThree = ({
	subsList,
	subsForDel,
	subsForDelAddons
}) => {
	const navigate = useNavigate()
	return (
		<div className='cancelSubscriptionBuilder-description'>
			<h2 className='cancelSubscriptionBuilder-description_topTitle'>
				Confirm your subscription cancellation
			</h2 >
      <p className='cancelSubscriptionBuilder-description_subtitle'>
        {`Your subscription will end on ${subsList?.[0]?.finishDate ? formatDate(subsList?.[0]?.finishDate) : ''}. After that, you will lose access to tax calculation and reporting.`}
			</p >
			
			<div className='cancelSubscriptionBuilder-description_cancel'>
				<h2 className='cancelSubscriptionBuilder-description_title'>
					Cancellation information
				</h2 >
				<p className='cancelSubscriptionBuilder-description_subtitle'>
	        <span className='cancelSubscriptionBuilder-description_bull'>&bull;</span >
					{`If you do nothing, your subscription will automatically end on ${subsList?.[0]?.finishDate ? formatDate(subsList?.[0]?.finishDate) : ''}.`}
				</p >
	      <p className='cancelSubscriptionBuilder-description_subtitle'>
	         <span className='cancelSubscriptionBuilder-description_bull'>&bull;</span >
		      You can renew your <span
		      onClick={() => navigate("/subscription")}
		      className='cancelSubscriptionBuilder-description_link'
	      >subscription</span > {`to keep using all the features of your plan.`}
				</p >
			</div >
			
			<div className='cancelSubscriptionBuilder-description_cancel'>
	      <h2 className='cancelSubscriptionBuilder-description_topTitle'>
	        {`${t('profileMemb.cancelSub')}?`}
	      </h2 >
	      <div className='cancelSubscriptionBuilder-cards three-step'>
          {subsList
	          .filter((subscription) => {
		          const matchingAddons = subsForDelAddons.find(
			          (delAddon) => delAddon.subscription === subscription.value
		          )?.addons || [];
							
		          const isSubscriptionChecked = subsForDel.some(
			          (item) => item.value === subscription.value && item.checked
		          );
		          
		          return matchingAddons.some((addon) => addon.checked) || isSubscriptionChecked;
	          })
	          .map((subscription, index) => {
		          const matchingAddons = subsForDelAddons.find(
			          (delAddon) => delAddon.subscription === subscription.value
		          )?.addons || [];
							
		          const selectedAddons = matchingAddons.filter((addon) => addon.checked);
							
		          const isSubscriptionChecked = subsForDel.some(
			          (item) => item.value === subscription.value && item.checked
		          );
		          
		          return (
			          <div
				          className='cancelSubscriptionBuilder-cards_item three-step_item'
				          key={index}
			          >
				          <Card
					          key={index}
					          title={
						          <h2 className='cancelSubscriptionBuilder-cards_item-label'>
							          <Checkbox
								          label={subscription.label}
								          checked={isSubscriptionChecked}
								          disabled
							          />
											</h2 >
					          }
					          sectioned
				          >
					          <div style={{display: 'flex', flexDirection: 'column', gap: 'tight'}}>
					            <div className='cancelSubscriptionBuilder-cards_item-addons'>
					              {selectedAddons.map((addon) => (
						              <Checkbox
							              key={addon.body.addons_id}
							              label={
								              <div className='cancelSubscriptionBuilder-cards_item-addons_line'>
					                      <span >{addon.body.name}</span >
					                    </div >
							              }
							              disabled
							              checked={true}
						              />
					              ))}
					            </div >
					          </div>
									</Card >
								</div >
		          );
	          })}
	      </div >
	    </div >
			
			<div className='cancelSubscriptionBuilder-description_refund'>
				<h2 className='cancelSubscriptionBuilder-description_title'>
					Refund information
				</h2 >
				<p className='cancelSubscriptionBuilder-description_subtitle'>
					You will not get a refund since it is a pre-paid membership. The products and services LOVAT provides will remain until your subscription plan expires. Please review our
					<a
						href='https://vatcompliance.co/cancellation-and-refund-policy'
						className='cancelSubscriptionBuilder-description_link'
					>Terms and Conditions</a >.
				</p >
			</div >
		</div >
	)
}

export default CancelSubscriptionStepThree