import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import moment from "moment";
import {
  Page,
  Layout,
  Card,
  Tabs,
  TextStyle,
  Spinner,
  Badge,
  Button,
  ButtonGroup,
  FormLayout,
  Select,
  Banner,
  Text,
} from "@shopify/polaris";
import {
  fetchCalculateId,
  fetchCalculateThresholds,
  fetchThresholdEU,
  fetchThresholdFederal,
  fetchThresholdFederalCountries,
  fetchThresholdRow,
  fetchThresholdUS,
} from "../../redux/features/thresholds/thresholdsSlice";
import Modal from "components/Modal";
import { formatMoney } from "utils/numbers";
import _ from "lodash";
import NoDataMessage from "components/NoDataMessage";
import { AlertIcon } from "icons";
import InfoTooltip from "components/InfoTooltip";
import Datepicker from "components/Datepicker";
import { formatDate, formatMonth } from "utils/dates";
import PageHelmet from "components/PageHelmet";
import HighlightableDataTable from "components/HighlightableDataTable";
import formatRow from "utils/formatRow";
import { createCustomDispatch } from "helpers/customDispatch";

const AttentionIcon = ({ fill = "#fff", stroke, border }) => (
  <span
    style={{
      position: "absolute",
      right: "5px",
      width: "28px",
      height: "28px",
      backgroundColor: stroke,
      border: `4px solid ${border}`,
      borderRadius: "50%",
    }}
  >
    <svg viewBox='0 0 20 20' width='20px' height='20px'>
      <path
        d='M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  </span>
);

class Threshold extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      calcYear: moment().year(),
      calcMonth: moment().format("M"),

      actionDialogs: {
        calculate: { open: false },
      },
    };

    this.picker = React.createRef();
  }

  componentDidMount() {
    this.fetchThresholds();

    this.props.fetchThresholdFederalCountries();
  }

  fetchThresholds() {
    this.props.fetchThresholdUS({ year: moment().year() });
    this.props.fetchThresholdEU({ year: moment().year() });
    this.props.fetchThresholdRow({ year: moment().year() });
    this.props.fetchThresholdFederal({ year: moment().year() });
  }

  getAttentionIcon = (color) => {
    let stroke = "";
    let border = "";
    if (color === "red") {
      stroke = "#DE3618";
      border = "#F59C08";
    }
    if (color === "yellow") {
      stroke = "#F59C08";
      border = "#FFEA8A";
    }
    return <AttentionIcon stroke={stroke} border={border} />;
  };

  getPickerLang = () => {
    const { t } = this.props;
    return {
      months: [
        t("threshold.janSh"),
        t("threshold.febSh"),
        t("threshold.marSh"),
        t("threshold.aprSh"),
        t("threshold.maySh"),
        t("threshold.juneSh"),
        t("threshold.julySh"),
        t("threshold.augSh"),
        t("threshold.septSh"),
        t("threshold.octSh"),
        t("threshold.novSh"),
        t("threshold.decSh"),
      ],
    };
  };

  getCalcOptions = () => {
    const { selected } = this.state;

    switch (selected) {
      case 0:
        return _.uniqBy(
          [
            {
              label: "All",
              value: "",
            },
            ...(this.props.thresholdUS ? this.props.thresholdUS.items : []).map(
              (item) => ({
                label: item.state,
                value: item.state_code,
              })
            ),
          ],
          (item) => item.value
        );
      case 1:
        return [
          {
            label: "EU",
            value: "FRA",
          },
        ];
      case 2:
        return [
          {
            label: "All",
            value: "federal",
          },
          ...(this.props.federalCountries
            ? this.props.federalCountries.map((country) => ({
                label: country.name,
                value: country.code,
              }))
            : []),
        ];
      case 3:
        return [
          {
            label: "All",
            value: "ROW",
          },
          ..._.uniqBy(
            (this.props.thresholdRow ? this.props.thresholdRow.items : []).map(
              (item) => ({
                label: item.country,
                value: item.country_id,
              })
            ),
            (item) => item.value
          ),
        ];

      default:
        return [];
    }
  };

  setColorFormatter = (value, { rowData }, icon = false, err) => {
    let color = "";
    if (rowData.color === "red") {
      color = "#FBEAE5";
    }
    if (rowData.color === "yellow") {
      color = "#FFFAEB";
    }

    return (
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "7px 2rem",
          whiteSpace: "nowrap",
          backgroundColor: color,
        }}
      >
        {value || value === 0 ? value : "-"}
        {icon &&
          rowData.color &&
          rowData.color !== "green" &&
          this.getAttentionIcon(rowData.color)}
        {err && (
          <span style={{ position: "absolute" }}>
            <span>{value}</span>{" "}
            <Badge size='small' status='critical'>
              Error
            </Badge>
          </span>
        )}
      </span>
    );
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  getType = (value) => {
    let type = "";
    switch (value) {
      case 1:
        type = "unified";
        break;
      case 2:
        type = "digital";
        break;
      case 3:
        type = "goods";
        break;
      default:
    }

    return type;
  };

  calculateThresholds() {
    const { selected, calcName, calcYear, calcMonth } = this.state;
    const params = {
      code: selected === 0 ? "USA" : calcName,
      year: calcYear,
      month: +calcMonth,
      state: selected === 0 ? calcName || "" : null,
    };
    this.props
      .fetchCalculateId(params)
      .then((data) => {
        if (data.message_id) {
          this.props.fetchCalculateThresholds(data.message_id);
        }
      })
      .then(() => {
        this.setState({
          successCalc: true,
        });
        this.fetchThresholds();
      })
      .catch(() => {
        this.setState({ errorCalc: true });
      });
  }

  resetDialogData = () => {
    this.setState({
      calcYear: moment().year(),
      calcMonth: moment().format("M"),
      calcName: "",
      errorCalc: null,
      successCalc: null,
    });
  };

  renderCalculateDialog() {
    const { fetchingCalcId, t } = this.props;
    const {
      actionDialogs,
      calcDate,
      selected,
      calcName,
      errorCalc,
      successCalc,
    } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("calculate");
      this.resetDialogData();
    };

    return (
      <Modal
        title={t("threshold.calc")}
        onClose={onClose}
        visible={actionDialogs.calculate.open}
        content={
          <>
            <br />
            {errorCalc && (
              <div>
                <Banner status='critical'>Error</Banner>
                <br />
              </div>
            )}
            {successCalc && (
              <div>
                <Banner status='success'>The calculation was successful</Banner>
                <br />
              </div>
            )}
            <FormLayout>
              <FormLayout.Group condensed>
                <Select
                  id={`${selected}name`}
                  name='name'
                  label={
                    selected === 0
                      ? `${t("threshold.state")}:`
                      : `${t("threshold.country")}:`
                  }
                  options={this.getCalcOptions()}
                  onChange={(v) => this.setState({ calcName: v })}
                  value={calcName}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Datepicker
                  label={"Period till"}
                  datepickerProps={{
                    showMonthYearPicker: true,
                    showFullMonthYearPicker: true,
                    minDate: new Date(
                      moment().subtract(1, "year").startOf("year").format()
                    ),
                    maxDate: new Date(moment().format()),
                  }}
                  allowRange={false}
                  customFormatDate={(date) => formatMonth(date)}
                  value={calcDate}
                  onChange={(date) => {
                    const year = moment(date).year();
                    const month = moment(date).month() + 1;

                    this.setState({
                      calcYear: year,
                      calcMonth: month,
                      calcDate: date,
                    });
                  }}
                />
              </FormLayout.Group>
            </FormLayout>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button disabled={fetchingCalcId} onClick={onClose} size='large'>
              Close
            </Button>
            <Button
              primary
              loading={fetchingCalcId}
              onClick={() => this.calculateThresholds()}
              size='large'
            >
              {t("threshold.calc")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderThresholdUS = () => {
    const { thresholdUS, t } = this.props;
    const rows = thresholdUS ? thresholdUS.items : [];

    const columns = [
      {
        property: "state",
        header: {
          label: t("threshold.state"),
        },
      },
      {
        property: "threshold_sum",
        header: {
          label: t("threshold.thresholdSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "fact_sum",
        header: {
          label: t("threshold.factSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "threshold_transaction",
        header: {
          label: t("threshold.thresholdTrans"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "number_of_transactions",
        header: {
          label: t("threshold.factTrans"),
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              rowData.number_of_transactions ||
              rowData.number_of_transactions === 0
                ? formatMoney(rowData.number_of_transactions)
                : "-",
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return rowData.color === "red" || rowData.color === "yellow" ? (
                <AlertIcon
                  stroke={rowData.color === "red" ? "#F97066" : "#B8821B"}
                />
              ) : (
                ""
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card sectioned={rows.length === 0}>
        {!_.isEmpty(rows) && (
          <HighlightableDataTable
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            rawItems={rows}
            rowClassNameCallback={(row, rowData) => ({
              "bg-red": rowData.color === "red",
              "bg-yellow": rowData.color === "yellow",
            })}
          />
        )}

        {_.isEmpty(rows) && (
          <Card.Section>
            <NoDataMessage
              title={t("threshold.notFilled")}
              style={{ height: "auto" }}
            />
          </Card.Section>
        )}
      </Card>
    );
  };

  renderThresholdEU = () => {
    const { thresholdEU, t } = this.props;
    const rows = [
      {
        region: "European Union",
        color: thresholdEU.color,
        threshold_sum: "10000",
        fact_sum: thresholdEU.sum || 0,
      },
    ];

    const columns = [
      {
        property: "region",
        header: {
          label: t("threshold.countryReg"),
        },
      },
      {
        property: "threshold_sum",
        header: {
          label: t("threshold.thresholdSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "fact_sum",
        header: {
          label: t("threshold.factSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return rowData.color === "red" || rowData.color === "yellow" ? (
                <AlertIcon
                  stroke={rowData.color === "red" ? "#F97066" : "#B8821B"}
                />
              ) : (
                ""
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card sectioned={rows.length === 0}>
        {!_.isEmpty(rows) && (
          <HighlightableDataTable
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            rawItems={rows}
            rowClassNameCallback={(row, rowData) => ({
              "bg-red": rowData.color === "red",
              "bg-yellow": rowData.color === "yellow",
            })}
          />
        )}

        {_.isEmpty(rows) && (
          <Card.Section>
            <NoDataMessage
              title={t("threshold.notFilled")}
              style={{ height: "auto" }}
            />
          </Card.Section>
        )}
      </Card>
    );
  };

  renderThresholdRow = () => {
    const { thresholdRow, t } = this.props;
    const rows = thresholdRow
      ? thresholdRow.items.map((item, ind) => ({
          ...item,
          table_id: ind,
        }))
      : [];

    const columns = [
      {
        property: "country",
        header: {
          label: t("threshold.country"),
        },
      },
      {
        property: "type",
        header: {
          label: t("threshold.type"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return this.getType(rowData.type);
            },
          ],
        },
      },
      {
        property: "max_sum_of_transactions",
        header: {
          label: t("threshold.thresholdSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "sum",
        header: {
          label: t("threshold.factSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "max_number_of_transactions",
        header: {
          label: t("threshold.thresholdTrans"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (value ? formatMoney(value) : ""),
          ],
        },
      },
      {
        property: "number_of_transactions",
        header: {
          label: t("threshold.factTrans"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return rowData.color === "red" || rowData.color === "yellow" ? (
                <AlertIcon
                  stroke={rowData.color === "red" ? "#F97066" : "#B8821B"}
                />
              ) : (
                ""
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card sectioned={rows.length === 0}>
        {!_.isEmpty(rows) && (
          <HighlightableDataTable
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            rawItems={rows}
            rowClassNameCallback={(row, rowData) => ({
              "bg-red": rowData.color === "red",
              "bg-yellow": rowData.color === "yellow",
            })}
          />
        )}

        {_.isEmpty(rows) && (
          <Card.Section>
            <NoDataMessage
              title={t("threshold.notFilled")}
              style={{ height: "auto" }}
            />
          </Card.Section>
        )}
      </Card>
    );
  };

  renderThresholdFederal = () => {
    const { thresholdFederal, t } = this.props;
    const rows = thresholdFederal
      ? thresholdFederal.items.map((item, ind) => ({
          ...item,
          table_id: ind,
        }))
      : [];

    const columns = [
      {
        property: "country",
        header: {
          label: t("threshold.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return `${value} ${rowData.state ? `(${rowData.state})` : ""}`;
            },
          ],
        },
      },
      {
        property: "type",
        header: {
          label: t("threshold.type"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return this.getType(rowData.type);
            },
          ],
        },
      },
      {
        property: "max_sum_of_transactions",
        header: {
          label: t("threshold.thresholdSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "sum",
        header: {
          label: t("threshold.factSum"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "max_number_of_transactions",
        header: {
          label: t("threshold.thresholdTrans"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (value ? formatMoney(value) : ""),
          ],
        },
      },
      {
        property: "number_of_transactions",
        header: {
          label: t("threshold.factTrans"),
        },
        cell: {
          formatters: [(value, { rowData }) => formatMoney(value)],
        },
      },
      {
        property: "surpassed_date",
        header: {
          label: "Surpassed date",
        },
        cell: {
          formatters: [(value, { rowData }) => value && formatDate(value)],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return rowData.color === "red" || rowData.color === "yellow" ? (
                <AlertIcon
                  stroke={rowData.color === "red" ? "#F97066" : "#B8821B"}
                />
              ) : (
                ""
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card sectioned={rows.length === 0}>
        {!_.isEmpty(rows) && (
          <HighlightableDataTable
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            rawItems={rows}
            rowClassNameCallback={(row, rowData) => ({
              "bg-red": rowData.color === "red",
              "bg-yellow": rowData.color === "yellow",
            })}
          />
        )}

        {_.isEmpty(rows) && (
          <Card.Section>
            <NoDataMessage
              title={t("threshold.notFilled")}
              style={{ height: "auto" }}
            />
          </Card.Section>
        )}
      </Card>
    );
  };

  render() {
    const {
      thresholdUS,
      thresholdEU,
      thresholdRow,
      thresholdFederal,
      fetchingThresholdUS,
      fetchingThresholdEU,
      fetchingThresholdRow,
      fetchingThresholdFederal,
      t,
    } = this.props;
    const { selected } = this.state;

    const tabs = [
      {
        id: "us",
        content: t("threshold.us"),
        accessibilityLabel: "US",
        panelID: "us",
      },
      {
        id: "eu",
        content: t("threshold.eu"),
        accessibilityLabel: "EU",
        panelID: "eu",
      },
      {
        id: "fed",
        content: "CAN",
        accessibilityLabel: "Federal",
        panelID: "fed",
      },
      {
        id: "rest",
        content: (
          <InfoTooltip
            content={t("threshold.rowFull")}
            style={{ marginTop: -3, marginLeft: 4 }}
          >
            {t("threshold.row")}
          </InfoTooltip>
        ),
        accessibilityLabel: "ROW",
        panelID: "rest",
      },
    ];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            {`${t("threshold.thresholdsFor1")}${new Date().getFullYear()}${t(
              "threshold.thresholdsFor2"
            )}`}
          </Text>
        }
        fullWidth
        separator
        primaryAction={{
          content: t("threshold.calc"),
          disabled:
            selected === 0 /* for USA */
              ? this.getCalcOptions().length <= 1
              : _.isEmpty(this.getCalcOptions()),
          onAction: () => {
            this.handleActionDialogsOpen("calculate");
            this.setState({
              calcName: this.getCalcOptions()[0].value,
            });
          },
        }}
      >
        <PageHelmet title={"Thresholds"} />

        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={(v) => this.setState({ selected: v })}
        />
        <br />
        <Layout>
          {selected === 0 && (
            <Layout.Section>
              {!fetchingThresholdUS &&
                thresholdUS &&
                !_.isEmpty(thresholdUS.items) &&
                !thresholdUS.message &&
                this.renderThresholdUS()}
              {!fetchingThresholdUS && thresholdUS && thresholdUS.message && (
                <Card sectioned>
                  <Text>{thresholdUS.message}</Text>
                </Card>
              )}
              {fetchingThresholdUS && (
                <Card sectioned>
                  <Spinner />
                </Card>
              )}
            </Layout.Section>
          )}

          {selected === 1 && (
            <Layout.Section>
              {!fetchingThresholdEU && thresholdEU && this.renderThresholdEU()}
              {fetchingThresholdEU && <Spinner />}
            </Layout.Section>
          )}

          {selected === 2 && (
            <Layout.Section>
              {!fetchingThresholdFederal &&
                thresholdFederal &&
                !thresholdFederal.message &&
                this.renderThresholdFederal()}
              {!fetchingThresholdFederal &&
                thresholdFederal &&
                thresholdFederal.message && (
                  <Card sectioned>
                    <Text>{thresholdFederal.message}</Text>
                  </Card>
                )}
              {fetchingThresholdFederal && <Spinner />}
            </Layout.Section>
          )}

          {selected === 3 && (
            <Layout.Section>
              {!fetchingThresholdRow &&
                thresholdRow &&
                !thresholdRow.message &&
                this.renderThresholdRow()}
              {!fetchingThresholdRow &&
                thresholdRow &&
                thresholdRow.message && (
                  <Card sectioned>
                    <Text>{thresholdRow.message}</Text>
                  </Card>
                )}
              {fetchingThresholdRow && <Spinner />}
            </Layout.Section>
          )}
        </Layout>
        <br />
        {this.renderCalculateDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  thresholdUS: state.thresholds.thresholdUS,
  thresholdEU: state.thresholds.thresholdEU,
  thresholdRow: state.thresholds.thresholdRow,
  thresholdFederal: state.thresholds.thresholdFederal,
  fetchingThresholdEU: state.thresholds.fetchingThresholdEU,
  fetchingThresholdUS: state.thresholds.fetchingThresholdUS,
  fetchingThresholdRow: state.thresholds.fetchingThresholdRow,
  fetchingThresholdFederal: state.thresholds.fetchingThresholdFederal,
  defaultLanguage: state.user.defaultLanguage,
  fetchingCalcId: state.thresholds.fetchingCalcId,
  federalCountries: state.thresholds.federalCountries,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchThresholdUS: (params) => dispatch(fetchThresholdUS(params)),
    fetchThresholdEU: (params) => dispatch(fetchThresholdEU(params)),
    fetchThresholdRow: (params) => dispatch(fetchThresholdRow(params)),
    fetchThresholdFederal: (params) => dispatch(fetchThresholdFederal(params)),
    fetchCalculateId: (params) => dispatch(fetchCalculateId(params)),
    fetchCalculateThresholds: (id) => dispatch(fetchCalculateThresholds(id)),
    fetchThresholdFederalCountries: () =>
      dispatch(fetchThresholdFederalCountries()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Threshold))
);
