import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import withRouter from "helpers/withRouter";
import AdvancedSettings from './AdvancedSettings/AdvancedSettings'
import BillingContact from './BillingContact'
import ProfileCompany from './Company/Company'
import ProfileUsers from './Users/Users'
import ProfileMembership from './Membership/Membership'


class CompanySettings extends Component {
  render() {
    return (
      <>
        <ProfileCompany {...this.props} />
        <BillingContact />
        <ProfileUsers {...this.props} />
        <ProfileMembership {...this.props} />
        <AdvancedSettings {...this.props} />
      </>
    );
  }
}

export default withTranslation()(withRouter(CompanySettings));
