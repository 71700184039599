import { Button, ButtonGroup } from '@shopify/polaris'
import { t } from 'i18next'
import React from 'react'
import Modal from '../../../../components/Modal'

const ModalForGoBack = ({goBack, goBackModalStatus, toggleGoBackModalStatus}) => {
	return (
		<Modal
			contentOnCenter
			title={t("vatReturns.warning")}
			iconType={"warning"}
			description={'Do you really want to go back? All data will be lost.'}
			onClose={goBack}
			visible={goBackModalStatus}
			footer={
				<ButtonGroup
					fullWidth
					distribution='center'
				>
          <Button
	          onClick={goBack}
	          size='large'
          >
            Yes
          </Button >
          <Button
	          primary
	          onClick={toggleGoBackModalStatus}
	          size='large'
          >
            No
          </Button >
        </ButtonGroup >
			}
		/>
	)
}

export default ModalForGoBack