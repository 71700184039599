import useAppDispatch from "hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addIntegrateStripeParams,
  clearIntegrateStripeParams,
} from "../../redux/features/user/userSlice";
import { integrateStripe } from "../../redux/features/websites/websitesSlice";

const useIntegrateStripeIfNeeded = (isLogged) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userState = useSelector((state) => state.user);
  const { wantIntegrateStripe, integrateStripeParams } = userState;

  useEffect(() => {
    if (isLogged && wantIntegrateStripe) {
      dispatch(
        integrateStripe({ ...integrateStripeParams, good_or_service: [] })
      ).then((responseLocation) => {
        window.location = responseLocation;
      });

      dispatch(clearIntegrateStripeParams());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    const getIntegrateStripeParams = () => {
      const { search } = location;
      const urlSearchParams = new URLSearchParams(search);

      if (urlSearchParams.get("integrateStripe") === "true") {
        const params = {
          website: urlSearchParams.get("website"),
          user_id: urlSearchParams.get("user_id"),
        };

        dispatch(addIntegrateStripeParams(params));
      }
    };

    getIntegrateStripeParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useIntegrateStripeIfNeeded;
