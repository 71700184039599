import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import ReactSelect from "react-select";
import _ from "lodash";
import {
  Page,
  Layout,
  FormLayout,
  Card,
  TextField,
  Select,
  Button,
  ButtonGroup,
  Banner,
  Text,
  DataTable,
  Tooltip,
  Box,
} from "@shopify/polaris";
import {
  createChargeInvoice,
  fetchBanksList,
  fetchListForChargeInvoice,
} from "../../redux/features/manager/managerSlice";
import { fetchCalculateChargeInvoice } from "../../redux/features/subscription/subscriptionSlice";
import { fetchAllCountries } from "../../redux/features/other/otherSlice";
import { fetchStates } from "../../redux/features/tax-settings/taxSettingsSlice";
import Modal from "components/Modal";
import formatRow from "utils/formatRow";

import deleteIMG from "img/delete.svg";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Datepicker from "components/Datepicker/Datepicker";
import moment from "moment";
import { formatMonth } from "utils/dates";
import { fetchFiltersEPR } from "../../redux/features/declarations/declarationsSlice";
import { getCurrencySymbol } from "utils/currency";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";
import { getYears } from "utils/years";
import { isUserTaxAgentOrManager } from "../../redux/selectors";
import SearchingCountry from '../../components/SearchingCountry/SearchingCountry'

const years = getYears({ yearsBefore: 3, yearsAfter: 1 });

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class AddInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      service: "",
      services: [],
      declarationTypes: [],
      declarationType: "",
      country: "",
      clientId: "",

      other: "",
      unit: "%",
      discountManager: 0,
      amountServ: 1,

      actionDialogs: {
        subscribe: { open: false },
      },
    };
  }

  componentDidMount() {
    this.props.fetchListForChargeInvoice();
    this.props.fetchAllCountries();
    this.props.fetchStates();
    this.props.fetchBanksList();
  }

  componentDidUpdate(prevProps) {
    const { selected_company_id: currentCompanyId } = this.props.taxListCompany;
    const { selected_company_id: prevCompanyId } = prevProps.taxListCompany;

    if (currentCompanyId !== prevCompanyId) {
      this.setState({ clientId: String(currentCompanyId) });
    }
  }

  monthsArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  doPrepareValues = (type) => {
    const { services } = this.state;

    return services
      .filter((serv) => serv.type === type)
      .map((item) => {
        const values = {
          id: +item.id,
          type: item.type,
        };

        if (item.yearFrom) {
          values.year_from = item.yearFrom;
          if (item.monthFrom) {
            values.month_from = item.monthFrom - 1;
          }
        }
        if (item.yearTill) {
          values.year_till = item.yearTill;
          if (item.monthTill) {
            values.month_till = item.monthTill - 1;
          }
        }
        if (item.country) {
          values.country = item.country;
        }
        if (item.amount === "true") {
          values.amount = +item.amountServ;
        }

        if (item.additionalType) {
          values.additional_type = +item.additionalType;
        }

        return values;
      });
  };

  doCloseSubscriptionDialog = () => {
    this.handleActionDialogsClose("subscribe");
    this.setState({
      bank: "",
      other: "",
      unit: "%",
      discountManager: 0,
    });
  };

  doPrepareDataForCalculate = () => {
    const data = {
      additional_services: [
        ...this.doPrepareValues("additional"),
        ...this.doPrepareValues("epr"),
      ],
      addons: [
        ...this.doPrepareValues("addon"),
        ...this.doPrepareValues("addon_st"),
        ...this.doPrepareValues("addon_omp"),
        ...this.doPrepareValues("addon_epr"),
        ...this.doPrepareValues("license_epr"),
      ],
      subscription: [
        ...this.doPrepareValues("subs"),
        ...this.doPrepareValues("subs_st"),
        ...this.doPrepareValues("subs_omp"),
        ...this.doPrepareValues("subscription_epr"),
        ...this.doPrepareValues("subscription_discount_epr"),
      ],
      vat_registration: [
        ...this.doPrepareValues("vat_reg"),
        ...this.doPrepareValues("reg_epr"),
      ],
    };

    this.props
      .fetchCalculateChargeInvoice({ data })
      .then((resp) => {
        this.setState({ calculate: resp });
        this.handleActionDialogsOpen("subscribe");
      })
      .catch((resp) => {
        this.setState({ calculateError: resp });
      });
  };

  doAddTheInvoice = () => {
    const { user } = this.props;
    const { calculate, other, bank, unit, discountManager, clientId } =
      this.state;

    const data = {
      ...calculate,
      discount: Number(discountManager),
      bank: Number(bank),
      unit,
      other,
    };

    if (isUserTaxAgentOrManager(user)) {
      data.client_id = Number(clientId);
    }

    this.props
      .createChargeInvoice(data)
      .then(() => {
        this.handleActionDialogsClose("subscribe");
        this.props.navigate("/billing");
      })
      .catch((err) => {
        this.setState({
          orderingError: err.error || "Error",
        });
      });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  formatAdditionalTypes = (types) => {
    return [
      {
        label: "",
        value: "",
      },
      ...types.map((type) => ({
        value: type.id.toString(),
        label: type.name,
        key: type.id,
      })),
    ];
  };

  renderSubscribeDialog() {
    const {
      calculate,
      actionDialogs,
      bank,
      unit,
      discountManager,
      orderingError,
      clientId,
    } = this.state;
    const { user, taxListCompany } = this.props;

    const banksList = [
      {
        value: "",
        label: "",
      },
      ...this.props.banksList,
    ];

    let sum = 0;
    let discount = Math.round(discountManager);
    let currencyIcon = "";

    if (calculate) {
      currencyIcon = getCurrencySymbol(calculate.currency);
      if (unit === "%") {
        sum =
          calculate.sum - Math.round((calculate.sum * discountManager) / 100);
        discount = Math.round((calculate.sum * discountManager) / 100);
      } else {
        sum = calculate.sum - discountManager;
      }
    }

    const columns = [
      {
        property: "name",
        header: {
          label: "Name",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <>
                  {`${value[0].toUpperCase() + value.slice(1)} ${
                    rowData.country && !_.isArray(rowData.country)
                      ? rowData.country.name
                      : ""
                  }`}
                  {rowData.year_from
                    ? ` from ${this.monthsArr[rowData.month_from]} ${
                        rowData.year_from
                      } till ${this.monthsArr[rowData.month_till]} ${
                        rowData.year_till
                      }`
                    : ""}
                </>
              );
            },
          ],
        },
      },
      {
        property: "sum_one_month",
        header: {
          label: "Price",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return `${value}${currencyIcon}`;
            },
          ],
        },
      },
      {
        property: "month",
        header: {
          label: "Quantity",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return value || "-";
            },
          ],
        },
      },
      {
        property: "sum",
        header: {
          label: "Total",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return `${value} ${currencyIcon}`;
            },
          ],
        },
      },
    ];

    const toRow = (key) => {
      return calculate?.[key] || [];
    };

    const manyRowsToOne = [
      ...toRow("subscription"),
      ...toRow("additional_services"),
      ...toRow("addons"),
      ...toRow("vat_registration"),
    ];

    const rows = formatRow(manyRowsToOne, columns);

    rows.push(["Discount", "", "", `- ${discount} ${currencyIcon}`]);

    return (
      <Modal
        title={"Charge invoice"}
        visible={actionDialogs.subscribe.open}
        onClose={() => this.doCloseSubscriptionDialog()}
        content={
          <>
            <br />
            {orderingError && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ orderingError: false })}
                  title={orderingError}
                  status='critical'
                />
                <br />
              </div>
            )}
            {isUserTaxAgentOrManager(user) && (
              <>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label={"Client"}
                      options={taxListCompany.companies?.map((company) => ({
                        value: String(company.id),
                        label: company.name,
                      }))}
                      onChange={(clientId) => this.setState({ clientId })}
                      value={clientId}
                    />
                    <br />
                  </FormLayout.Group>
                </FormLayout>
                <br />
              </>
            )}
            {calculate && (
              <DataTable
                rows={rows}
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "numeric",
                ]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                showTotalsInFooter
                totals={["Subtotal", "", "", Math.round(sum) + currencyIcon]}
                totalsName={{
                  singular: "Subtotal",
                  plural: "Subtotal",
                }}
              />
            )}
            <br />
            <form autoComplete='off' onSubmit={this.checkPromo}>
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label={"Banks"}
                    options={banksList.map((bankItem) => ({
                      key: bankItem.id,
                      label: bankItem.bank_name,
                      value: `${bankItem.id}`,
                    }))}
                    onChange={(value) => this.setState({ bank: value })}
                    value={bank}
                    error={!bank}
                  />
                  <TextField
                    type='number'
                    error={
                      unit === "%"
                        ? discountManager > 100
                        : discountManager > calculate.sum
                    }
                    name={"disc"}
                    label={"Discount"}
                    value={discountManager.toString()}
                    onChange={(value) =>
                      this.setState({ discountManager: value })
                    }
                    connectedRight={
                      <Select
                        options={[
                          {
                            key: "%",
                            label: "%",
                            value: "%",
                          },
                          {
                            key: "sum",
                            label: "Sum",
                            value: "sum",
                          },
                        ]}
                        value={unit}
                        onChange={(value) => this.setState({ unit: value })}
                      />
                    }
                  />
                </FormLayout.Group>
                <Text variant="bodyMd" color="critical">
                  {bank && _.find(banksList, ["id", +bank]).description}
                </Text>

                <TextField
                  name={"other"}
                  label={"Comment"}
                  value={this.state.other}
                  onChange={(other) => this.setState({ other })}
                />
              </FormLayout>
            </form>
          </>
        }
        footer={
          <ButtonGroup>
            <div>
              <Button
                plain
                disabled={!bank}
                onClick={() => {
                  this.doAddTheInvoice();
                }}
              >
                Order an invoice
              </Button>
            </div>
          </ButtonGroup>
        }
      />
    );
  }

  render() {
    const {
      service,
      country,
      yearFrom,
      monthFrom,
      yearTill,
      monthTill,
      dateFrom,
      dateTill,
      services,
      amountServ,
      declarationType,
      declarationTypes,
      additionalType = "",
    } = this.state;
    const {
      listForChargeInvoice,
      countries: countriesList,
      usStates,
      user,
    } = this.props;
    const selected = listForChargeInvoice.find(
      ({ name }) => name === service.split("--")[1]
    );
    const isEPRReporting = service.toLowerCase().includes("epr reporting");
    const countries = isEPRReporting
      ? countriesList.filter((a) => a.name === "France" || a.name === "Germany")
      : countriesList;
    const additionalTypesOptions = this.formatAdditionalTypes(
      selected?.additional_type ?? []
    );
    const pickerLang = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const getCountriesOptions = (s) => {
      const eprTypes = ["addon_epr", "subscription_epr"];
      const type = s.split("--")[2];
      const emptyOption = [{ code: "null", name: "" }];
      let selectedCountries = [];

      switch (type) {
        case eprTypes[0]:
        case eprTypes[1]:
          selectedCountries = selected?.countries ?? [];
          break;

        case "addon_st":
          selectedCountries = usStates;
          break;

        default:
          selectedCountries = countries;
      }

      const filteredCountries = selectedCountries.filter(({ code }) => {
        if (!eprTypes.includes(type) && selected && selected.countries) {
          return selected.countries.some(
            (selectedCountry) => selectedCountry.code === code
          );
        }
        return true;
      });

      return [emptyOption, ...filteredCountries].map((item) => ({
        key: item.code,
        label: item.name,
        value: item.code,
      }));
    };

    const columns = [
      {
        property: "name",
        header: {
          label: "Name",
        },
      },
      {
        property: "",
        header: {
          label: "From",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const month = pickerLang[rowData?.monthFrom - 1];
              return (
                `${month ? month + " - " : ""}` + (rowData?.yearFrom || "-")
              );
            },
          ],
        },
      },
      {
        property: "",
        header: {
          label: "Till",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const month = pickerLang[rowData?.monthTill - 1];
              return (
                `${month ? month + " - " : ""}` + (rowData?.yearTill || "-")
              );
            },
          ],
        },
      },
      {
        property: "amount",
        header: {
          label: "Amount",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return <>{value === "true" ? rowData.amountServ : "-"}</>;
            },
          ],
        },
      },
      {
        property: "",
        header: {
          label: "Country/State",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <>
                  {(rowData?.type === "addon_st" ? usStates : countries).find(
                    (cnt) => cnt.code === rowData?.country
                  )
                    ? (rowData?.type === "addon_st"
                        ? usStates
                        : countries
                      ).find((cnt) => cnt.code === rowData.country).name
                    : "-"}
                </>
              );
            },
          ],
        },
      },
      {
        property: "price",
        header: {
          label: "Price/unit",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <>
                  {value &&
                    `${value}${getCurrencySymbol(user.company.currency_code)}`}
                </>
              );
            },
          ],
        },
      },
      {
        property: "price",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData, index }) => {
              return (
                <div className='ButtonOnHover'>
                  <Tooltip content='Delete'>
                    <img
                      src={deleteIMG}
                      alt='icon delete'
                      onClick={() => {
                        services.map((row) => {
                          if (row.name === rowData.name) {
                            services.splice(index, 1);
                            this.setState({ services });
                          }
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </div>
              );
            },
          ],
        },
      },
    ];

    const rows = formatRow(services || [], columns);

    const onAddServiceClick = () => {
      const values = service.split("--");
      const [id, name, type, period, priceString, amount] = values;
      let price = Number(priceString);

      if (!price) {
        const foundElement = listForChargeInvoice.find(
          (item) => item.name === name
        );

        if (foundElement) {
          price =
            foundElement?.tariff?.find((tariff) => tariff.country === country)
              .price || 0;
        }
      }

      const eprReportingServiceData = isEPRReporting
        ? {
            type: "epr",
            id: declarationTypes.find(({ value }) => declarationType === value)
              .id,
          }
        : {};

      const newService = {
        id,
        name,
        period,
        price,
        amount,
        amountServ,
        monthFrom,
        monthTill,
        country,
        additionalType,
        type,
        yearTill: yearTill,
        yearFrom: yearFrom,
        ...eprReportingServiceData,
      };

      this.setState((prevState) => {
        return {
          services: [...prevState.services, newService],
          service: "",
          country: "",
          declarationType: "",
          amountServ: 1,
          yearFrom: null,
          monthFrom: null,
          yearTill: null,
          monthTill: null,
          additionalType: "",
        };
      });
    };

    const servicesOptions = [
      { key: "null", label: "", value: "" },
      ...listForChargeInvoice.map((item, index) => {
        return {
          key: index,
          label: item.name,
          value: `${item.id}--${item.name}--${item.type}--${item.period}--${item.price}--${item.amount}`,
        };
      }),
    ];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            Add invoice
          </Text>
        }
        fullWidth
        separator
      >
        <PageHelmet title={"Add invoice"} />

        <Layout>
          <Layout.AnnotatedSection
            title='Add invoice'
            description={
              <Text variant="bodyMd" color="critical">
                Please enter invoice information
              </Text>
            }
          >
            <Layout.Section>
              <Card
                title='Charge invoice'
                actions={[
                  {
                    disabled: _.isEmpty(services),
                    content: "Generate an invoice",
                    onAction: () => this.doPrepareDataForCalculate(),
                  },
                ]}
              >
                <br />
                <DataTable
                  rows={rows}
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "numeric",
                  ]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                />
                {_.isEmpty(services) && (
                  <NoDataMessage
                    title='No any service'
                    style={{ height: "auto", padding: "0.8rem 0" }}
                  />
                )}
              </Card>

              <Card sectioned>
                <FormLayout>
                  <FormLayout.Group>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="1"
                      width="100%"
                    >
                      <Text>Select Service</Text>
                      <ReactSelect
                        placeholder=''
                        options={servicesOptions}
                        onChange={(selectedOption) => {
                          this.setState({
                            service: selectedOption.value,
                            monthFrom: null,
                            monthTill: null,
                            yearFrom: null,
                            yearTill: null,
                          });
                        }}
                        value={
                          servicesOptions.find(
                            ({ value }) => value === service
                          ) ?? ""
                        }
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                      />
                    </Box>
                    {/*{*/}
                    {/*  getCountriesOptions(service) && getCountriesOptions(service).length >= 2*/}
                    {/*  &&*/}
                    {/*  <Select*/}
                    {/*    label={*/}
                    {/*      service && service.split("--")[2] === "addon_st"*/}
                    {/*        ? "State"*/}
                    {/*        : "Country"*/}
                    {/*    }*/}
                    {/*    options={getCountriesOptions(service)}*/}
                    {/*    onChange={(code) => {*/}
                    {/*      this.setState({ country: code });*/}
                    {/*      */}
                    {/*      if (isEPRReporting) {*/}
                    {/*        this.props.fetchFiltersEPR().then((res) => {*/}
                    {/*          this.setState({*/}
                    {/*            declarationTypes: [*/}
                    {/*              { value: "", label: "" },*/}
                    {/*              ...res*/}
                    {/*                .find((c) => c.code === code)*/}
                    {/*                .categories.map((category) => {*/}
                    {/*                  return {*/}
                    {/*                    id: category.id,*/}
                    {/*                    value: category.name,*/}
                    {/*                    label: category.name,*/}
                    {/*                  };*/}
                    {/*                }),*/}
                    {/*            ],*/}
                    {/*          });*/}
                    {/*        });*/}
                    {/*      }*/}
                    {/*    }}*/}
                    {/*    value={country}*/}
                    {/*  />*/}
                    {/*}*/}
                    {
                      getCountriesOptions(service) && getCountriesOptions(service).length >= 2
                      &&
                      <SearchingCountry
                        title={
                          service && service.split("--")[2] === "addon_st"
                          ? "State"
                          : "Country"
                        }
                        placeholder={`Enter ${service && service.split("--")[2] === "addon_st"
                          ? "State"
                          : "Country"}`}
                        onChange={(code) => {
                          this.setState({ country: code });
                          
                          if (isEPRReporting) {
                            this.props.fetchFiltersEPR().then((res) => {
                              this.setState({
                                declarationTypes: [
                                  { value: "", label: "" },
                                  ...res
                                    .find((c) => c.code === code)
                                    .categories.map((category) => {
                                      return {
                                        id: category.id,
                                        value: category.name,
                                        label: category.name,
                                      };
                                    }),
                                ],
                              });
                            });
                          }
                        }}
                        dataCountriesList={getCountriesOptions(service)}
                        changeCountry={country}
                      />
                    }
                  </FormLayout.Group>

                  {service && service.split("--")[2] === "addon_epr" && (
                    <Select
                      label={"Additional type"}
                      options={additionalTypesOptions}
                      onChange={(type) =>
                        this.setState({ additionalType: type })
                      }
                      value={additionalType}
                    />
                  )}

                  {service && service.split("--")[5] === "true" && (
                    <TextField
                      type='number'
                      name={"amt"}
                      label={"Amount"}
                      value={amountServ.toString()}
                      onChange={(value) => this.setState({ amountServ: value })}
                    />
                  )}

                  {((service && isEPRReporting) ||
                    (selected && selected.yearly)) && (
                    <FormLayout.Group>
                      <Select
                        label={"Year from"}
                        options={years}
                        onChange={(year) => this.setState({ yearFrom: year })}
                        value={yearFrom}
                      />
                      <Select
                        label={"Year till"}
                        options={years}
                        onChange={(year) => this.setState({ yearTill: year })}
                        value={yearTill}
                      />
                    </FormLayout.Group>
                  )}
                  {service && isEPRReporting && (
                    <FormLayout.Group>
                      <Select
                        id='declarationType'
                        name='declaration_type'
                        label={"Declaration type"}
                        options={this.state.declarationTypes}
                        onChange={(value) =>
                          this.setState({ declarationType: value })
                        }
                        value={this.state.declarationType}
                      />
                      <br />
                    </FormLayout.Group>
                  )}

                  {service &&
                    service.split("--")[3] === "true" &&
                    !isEPRReporting &&
                    !(selected && selected.yearly) && (
                      <FormLayout.Group>
                        <Datepicker
                          label={"From"}
                          datepickerProps={{
                            showMonthYearPicker: true,
                            showFullMonthYearPicker: true,
                          }}
                          customFormatDate={(date) => formatMonth(date)}
                          placeholder='Not selected'
                          allowRange={false}
                          value={dateFrom}
                          onChange={(date) => {
                            const year = moment(date).year();
                            const month = moment(date).month() + 1;

                            this.setState({
                              dateFrom: date,
                              dateTill: date,
                              yearFrom: year,
                              yearTill: year,
                              monthFrom: month,
                              monthTill: month,
                            });
                          }}
                        />

                        <Datepicker
                          label={"Till"}
                          datepickerProps={{
                            showMonthYearPicker: true,
                            showFullMonthYearPicker: true,
                          }}
                          maxDatePeriodYear={3}
                          customFormatDate={(date) => formatMonth(date)}
                          placeholder='Not selected'
                          allowRange={false}
                          value={dateTill}
                          onChange={(date) => {
                            const year = moment(date).year();
                            const month = moment(date).month() + 1;

                            this.setState({
                              yearTill: year,
                              monthTill: month,
                              dateTill: date,
                            });
                          }}
                        />
                      </FormLayout.Group>
                    )}
                </FormLayout>
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    primary
                    disabled={
                      !service ||
                      (service &&
                        service.split("--")[3] === "true" &&
                        !yearFrom) ||
                      (service &&
                        service.split("--")[3] === "true" &&
                        !yearTill)
                    }
                    onClick={onAddServiceClick}
                  >
                    Add service
                  </Button>
                </div>
              </Card>
              {this.renderSubscribeDialog()}
            </Layout.Section>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  listForChargeInvoice: state.manager.listForChargeInvoice,
  countries: state.other.countriesAll,
  usStates: state.taxSettings.usStates,
  taxListCompany: state.tax.taxListCompany,
  banksList: state.manager.banksList,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createChargeInvoice: (params) => dispatch(createChargeInvoice(params)),
    fetchCalculateChargeInvoice: (params) =>
      dispatch(fetchCalculateChargeInvoice(params)),
    fetchListForChargeInvoice: () => dispatch(fetchListForChargeInvoice()),
    fetchBanksList: () => dispatch(fetchBanksList()),
    fetchAllCountries: () => dispatch(fetchAllCountries()),
    fetchStates: () => dispatch(fetchStates()),
    fetchFiltersEPR: () => dispatch(fetchFiltersEPR()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddInvoice))
);
