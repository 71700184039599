import React from "react";
import ArrowCircleLeftIMG from "img/arrow-circle-left.svg";
import { Box, Button} from "@shopify/polaris";
import "./index.scss";

const BackArrowWithComponent = ({ withComponent, onClick }) => {
  return (
    <Box display="flex" alignItems="center" gap="2">
      <Button
        plain
        onClick={onClick}
        icon={
          <img
            src={ArrowCircleLeftIMG}
            alt='arrow back'
            className='arrow-back-fixer'
          />
        }
      />
      {withComponent}
    </Box>
  );
};

export default BackArrowWithComponent;
