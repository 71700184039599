import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import * as XLSX from "xlsx";
import {
  Page,
  Button,
  ButtonGroup,
  Select,
  FormLayout,
  Card,
  Checkbox,
  TextField,
  ChoiceList,
  Layout,
  Banner,
  Tag,
  List,
  Tooltip,
  Text,
  DataTable,
  Box,
} from "@shopify/polaris";
import {
  fetchRegCountries,
  fetchRegQuestions,
  getAllNotCompletedOrders,
  orderRegNumber,
  sendRegOrderData,
} from "../../redux/features/registration/registrationSlice";
import Modal from "components/Modal";
import Stepper from "components/Stepper";
import SaveBar from "components/SaveBar/SaveBar";
import step1IMG from "img/step1.svg";
import formatRow from "utils/formatRow";
import Datepicker from "components/Datepicker/Datepicker";
import { formatDateForServer } from "utils/dates";
import { getCurrencySymbol } from "utils/currency";
import PageHelmet from "components/PageHelmet";
import { fetchAllCountries } from '../../redux/features/other/otherSlice'
import { fetchUser } from '../../redux/features/user/userSlice'
import Payment from "../Payment";
import { createCustomDispatch } from "helpers/customDispatch";

const personalInfoFields = {
  full_name: "Your name",
  did_you_change_the_name: "Did you change the name? If yes - previuos name",
  date_of_birth: "Date of birth",
  country_of_origin: "Country of origin",
  address: "Postcode and personal address (last 3 years)",
  email: "Your e-mail",
  phone: "Phone number",
  national_insurance_number: "National Insurance Number",
  self_assessment_unique_taxpayer_reference:
    "Self Assessment Unique Taxpayer Reference",
};

const businessInfoFields = {
  company_registration_number: "Company Registration Number (CRN)",
  date_of_incorporation: "Date of incorporation",
  name_of_corporate_body: "Name of corporate body",
};

const businessFields = {
  phone: "Company phone number",
  address: "Company address",
  email: "Company e-mail",
};

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class OrderRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      companyName: "",
      origCountry: "",

      stepIndex: 0,
      lovatAgent: false,
      registrationData: {},
      doc1: null,
      doc2: null,

      fileNameList: {
        primary_evidence: [],
        additional_evidence1: [],
        additional_evidence2: [],
      },

      actionDialogs: {
        continue: { open: false },
        pay: { open: false },
        notification: { open: false },
      },

      regReasons: [],
    };
  }

  componentDidMount() {
    this.props.fetchAllCountries();
    this.props.fetchRegCountries();
    this.props.fetchUser();
  }

  getStepContent(stepIndex) {
    const { blocks } = this.props;
    const shift = this.state.lovatAgent ? 2 : 3;
    const finalStepNumber = blocks ? blocks.length + shift + 1 : 0;
    const documentsStepNumber = blocks ? blocks.length + shift : 0;

    if (stepIndex === 1 && !this.state.lovatAgent) {
      return this.stepPersonal();
    }
    if (stepIndex === 2 && !this.state.lovatAgent) {
      return this.stepBusiness();
    }

    switch (stepIndex) {
      case 0:
        return this.step1();
      case 1:
        return this.stepBusiness();
      case documentsStepNumber:
        return this.stepDocs();
      case finalStepNumber:
        return this.stepSubmit();
      default:
        return this.stepDynamic(stepIndex - shift); // stepIndex ~ blockNum
    }
  }

  getSaveBarContent(stepIndex) {
    const { blocks } = this.props;
    const shift = this.state.lovatAgent ? 2 : 3;
    const finalStepNumber = blocks ? blocks.length + shift + 1 : 0;
    const documentsStepNumber = blocks ? blocks.length + shift : 0;

    if (stepIndex === 1 && !this.state.lovatAgent) {
      return this.stepNextSaveBar();
    } //!!!
    if (stepIndex === 2 && !this.state.lovatAgent) {
      return this.stepNextSaveBar();
    } //!!!!

    switch (stepIndex) {
      case 0:
        return this.step1SaveBar(); //!!!!
      case 1:
        return this.stepNextSaveBar(); // !!!
      case documentsStepNumber:
        return this.stepDocsSaveBar(); //!!
      case finalStepNumber:
        return this.stepSubmitSaveBar();
      default:
        return this.stepDynamicSaveBar(); // !!!!
    }
  }

  getRegQuestions = (params) => {
    this.props
      .fetchRegQuestions(params)
      .then((resp) => {
        this.setState({ blocks: resp });
      })
      .catch((result) => {
        this.setState({ gettingResult: result });
      });
  };

  getAllNotCompletedOrders(params) {
    this.props
      .getAllNotCompletedOrders(params)
      .then((resp) => {
        if (resp) {
          this.handleActionDialogsOpen("continue", resp);
        } else {
          this.handleNext();
        }
      })
      .catch((result) => {
        this.setState({ gettingError: result });
      });
  }

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;

    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const files = this.fileUploadInput.files;
        for (let i = 0; i < files.length; i++) {
          this.doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside 'doDeleteFile' function
          this.docsFiles[docsType][this.docsFiles[docsType].length] = files[i]; // add file
          fileNameList[docsType][fileNameList[docsType].length] = files[i].name; // add filename
        }
        this.setState({ fileNameList });
      };
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  addRegistrationData = (question, value) => {
    this.setState((prevState) => ({
      registrationData: {
        ...prevState.registrationData,
        [question]: value,
      },
    }));
  };

  /* fill empty fields by default data */
  fillEmptyFields = (user) => {
    const { registrationData } = this.state;
    const { personal_info } = user.company;
    const { info } = user.company;
    const { company } = user;
    /* personal info */
    if (!this.state.lovatAgent) {
      Object.keys(personalInfoFields).map((key) => {
        if (!registrationData[personalInfoFields[key]] && personal_info[key]) {
          registrationData[personalInfoFields[key]] = personal_info[key];
        }
      });
      this.setState({ registrationData });
    }
    /* business info */
    Object.keys(businessInfoFields).map((key) => {
      if (!registrationData[businessInfoFields[key]] && info[key]) {
        registrationData[businessInfoFields[key]] = info[key];
      }
    });

    Object.keys(businessFields).map((key) => {
      if (!registrationData[businessFields[key]] && company[key]) {
        registrationData[businessFields[key]] = company[key];
      }
    });
    this.setState({ registrationData });
  };

  uploadRegOrderInformation = (arr) => {
    const worksheet = XLSX.utils.aoa_to_sheet(arr); // registrationDataArr
    const CSV = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([CSV], { type: "text/csv;charset=utf-8;" });

    const formData = new FormData(); /*files*/

    formData.append(
      "vat_registration",
      blob,
      "vat_registration.csv"
    ); /*reg data in csv*/

    this.submitRegOrderInformation(formData);
  };

  submitRegOrderInformation(file) {
    this.setState({
      orderingError: null,
      jsonError: null,
      tokenError: null,
    });
    this.props
      .orderRegNumber({
        country: this.state.regCountry,
        reason: this.state.regReason.id,
        data: file,
      })
      .then(() => {
        this.setState({ orderingResult: "Request is sent" });
      })
      .catch((result) => {
        this.setState({ orderingError: result });
      });
  }

  /* send filled parts of request on each step */
  sendRegOrderPartialInfo(info) {
    const request = {
      country_id: this.state.regCountry,
      reason_for_registration_id: this.state.regReason.id,
      data: info,
    };

    if (this.state.lovatAgent) {
      request.vat_agent_id = 2; // lovat
    }

    this.props
      .sendRegOrderData(request)
      .then(() => {
        this.setState({ sendingResult: "sent" });
      })
      .catch((result) => {
        this.setState({ orderingError: result });
      });
  }

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.data = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs[name].data = data;
    this.setState({ actionDialogs });
  };

  handleNext = () => {
    const { stepIndex, registrationData } = this.state;
    registrationData.step = stepIndex + 1;
    this.setState({ stepIndex: stepIndex + 1 });
    this.sendRegOrderPartialInfo(registrationData);
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    this.setState({ stepIndex: stepIndex - 1 });

    if (stepIndex === 1) {
      this.setState({ lovatAgent: false });
    }
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formData = this.state.formData ? this.state.formData : new FormData();
    const docsTypes = [
      "primary_evidence",
      "additional_evidence1",
      "additional_evidence2",
    ];

    _.map(docsTypes, (type) => {
      for (let i = 0; i < docsFiles[type].length; i++) {
        formData.append(
          type + "_" + i,
          docsFiles[type][i],
          docsFiles[type][i].name
        );
      }
    });
    this.setState({ formData });
  };

  registrationPrice() {
    const { data, regReason } = this.state;
    const { t } = this.props;

    return (
      <div>
        {data.country && regReason && (
          <>
            <div style={{display: 'flex', flexDirection: 'column', gap: 'var(--spacing-extra-loose)'}}>
              <Text
                variant='headingLg'
                as='h5'
              >
                {t("orderReg.vatRegIn1")} {data.country.name}:
              </Text >
              <Card sectioned>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='4'
                  width='100%'
                >
                  <Box
                    display='flex'
                    alignItems='space-between'
                  >
                    <Text
                      as='h6'
                      variant='headingMd'
                      fontWeight='bold'
                    >
                      Registration price
                    </Text >
                    <Text
                      as='h6'
                      variant='headingMd'
                      fontWeight='bold'
                    >
                      {regReason.price}{" "}
                      {getCurrencySymbol(data.country.currency)}
                    </Text >
                  </Box >
                  {regReason.rep_service && (
                    <Box
                      display='flex'
                      alignItems='space-between'
                    >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >
                        Tax representative
                      </Text >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >
                        12 * {regReason.rep_service}
                        {getCurrencySymbol(data.country.currency)}
                      </Text >
                    </Box >
                  )}
                  {regReason.intermediary_service && (
                    <Box
                      display='flex'
                      alignItems='space-between'
                    >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >
                        Intermediary service
                      </Text >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >
                        12 * {regReason.intermediary_service}
                        {getCurrencySymbol(data.country.currency)}
                      </Text >
                    </Box >
                  )}
                  {regReason.total_amount && (
                    <Box
                      display='flex'
                      alignItems='space-between'
                    >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >Total</Text >
                      <Text
                        as='h6'
                        variant='headingMd'
                        fontWeight='bold'
                      >
                        {regReason.total_amount}{" "}
                        {getCurrencySymbol(data.country.currency)}
                      </Text >
                    </Box >
                  )}
                </Box >
              </Card >
            </div>
            <br />
          </>
        )}
      </div >
    );
  }
  
  step1() {
    const { data, registrationData, regReasons } = this.state;
    const { regCountries, t } = this.props;

    return (
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <Tooltip content={t("orderReg.countryWantReg")}>
              <Select
                id='taxRegisration-country'
                name='country'
                label={`${t("orderReg.country")}:`}
                options={[
                  {
                    label: "",
                    value: "",
                  },
                  ..._.map(regCountries, (country) => ({
                    label: country.name_no_article,
                    value: country.code,
                  })),
                ]}
                onChange={(value) => {
                  let dataTemp = {};
                  let reasons = [];
                  regCountries.forEach((element) => {
                    if (element.code === value) {
                      dataTemp = {
                        name: element.name,
                        currency: element.currency,
                        ppl_button_id: element.ppl_button_id,
                      };
                      reasons = element.reason_for_registration;
                    }
                  });
                  data.country = dataTemp;
                  registrationData["Registration Country"] = dataTemp.name;
                  this.setState({
                    data,
                    registrationData,
                    regCountry: value,
                    regReasons: reasons,
                    regReason: undefined,
                  });
                }}
                value={this.state.regCountry || ""}
              />
            </Tooltip>

            <Select
              id='reason'
              value={
                this.state.regReason ? this.state.regReason.id.toString() : ""
              }
              name='reason'
              label={`${t("orderReg.reasonForReg")}:`}
              onChange={(value) => {
                let dataTemp = {};
                regReasons.forEach((element) => {
                  if (element.id.toString() === value) {
                    dataTemp = {
                      ...element,
                    };
                  }
                });
                registrationData["Reason for registration"] = dataTemp.name
                  ? dataTemp.name
                  : "";
                this.setState({
                  registrationData,
                  regReason: dataTemp,
                });
              }}
              options={[
                {
                  label: "",
                  value: "",
                },
                ...regReasons.map((reason) => ({
                  label: reason.name,
                  value: reason.id.toString(),
                })),
              ]}
              disabled={!this.state.regCountry}
            />
          </FormLayout.Group>
        </FormLayout>
        <br />

        <Checkbox
          checked={this.state.lovatAgent}
          label={t("orderReg.lovatMyTaxRepr")}
          onChange={(isChecked) => {
            if (isChecked) {
              this.setState({ lovatAgent: true });
              registrationData["I want LOVAT to be my tax representative"] =
                "yes";
              this.setState({ registrationData });
            } else {
              this.setState({ lovatAgent: false });
              registrationData["I want LOVAT to be my tax representative"] =
                "no";
              this.setState({ registrationData });
            }
          }}
        />
      </Card>
    );
  }

  step1SaveBar = () => {
    const { t } = this.props;
    return (
      <ButtonGroup>
        <Button
          disabled={
            !this.state.lovatAgent &&
            !this.state.regReason &&
            !this.state.regCountry
              ? true
              : false
          }
          onClick={
            () =>
              this.setState({
                data: {},
                registrationData: {},
                regCountry: "",
                regReason: "",
                lovatAgent: false,
              }) /*this.props.navigate('/tax-settings')*/
          }
        >
          {t("orderReg.discard")}
        </Button>
        <Button
          primary
          disabled={!(this.state.regCountry && this.state.regReason)}
          onClick={() => {
            if (!this.state.lovatAgent)
              this.addRegistrationData(
                "I want LOVAT to be my tax representative",
                "no"
              ); // dafault data
            this.getRegQuestions({
              country_id: this.state.regCountry,
              reason_for_registration_id: this.state.regReason.id,
            });
            this.getAllNotCompletedOrders({
              country_id: this.state.regCountry,
              reason_for_registration_id: this.state.regReason.id,
            });
          }}
        >
          {t("orderReg.next")}
        </Button>
      </ButtonGroup>
    );
  };

  stepPersonal() {
    const { user, t } = this.props; //default values
    const { countries } = this.props;
    const { registrationData } = this.state;

    const countriesOptions = [
      {
        label: "",
        value: "",
      },
    ];
    _.map(countries, (country) => {
      countriesOptions.push({
        key: country.code,
        label: country.name_no_article,
        value: country.code,
      });
    });

    return (
      <div>
        <Card title={`${t("orderReg.steps.persInfo")}:`} sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id='name'
                name='name'
                label={`${t("orderReg.yourName")}:`}
                value={
                  registrationData?.["Your name"] ||
                  user.company.personal_info.full_name
                }
                onChange={(value) =>
                  this.addRegistrationData("Your name", value)
                }
              />

              <TextField
                id='previous_name'
                name='previous_name'
                label={`${t("orderReg.prevName")}:`}
                placeholder={t("orderReg.changeName")}
                value={
                  registrationData?.[
                    "Did you change the name? If yes - previuos name"
                  ] || undefined
                }
                onChange={(value) =>
                  this.addRegistrationData(
                    "Did you change the name? If yes - previuos name",
                    value
                  )
                }
              />

              <Datepicker
                label={`${t("orderReg.dateOfBirth")}:`}
                datepickerProps={{
                  showYearDropdown: true,
                  yearDropdownItemNumber: 80,
                  scrollableYearDropdown: true,
                  maxDate: Date.now(),
                }}
                value={
                  registrationData?.["Date of birth"] ||
                  user.company.personal_info.date_of_birth
                }
                allowRange={false}
                onChange={(value) =>
                  this.addRegistrationData(
                    "Date of birth",
                    formatDateForServer(value)
                  )
                }
                placeholder='DD-MM-YYYY'
              />

              <Select
                id='origin-country'
                name='origin-country'
                label={`${t("orderReg.origCountry")}:`}
                options={countriesOptions}
                onChange={(value) =>
                  this.addRegistrationData("Country of origin", value)
                }
                value={
                  registrationData?.["Country of origin"] ||
                  user.company.personal_info.country_of_origin
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>

        <Card title={`${t("orderReg.contacts")}:`} sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Tooltip content={t("orderReg.postPersAddress")}>
                <TextField
                  id='address'
                  name='address'
                  label={`${t("orderReg.persAddress")}:`}
                  value={
                    registrationData?.[
                      "Postcode and personal address (last 3 years)"
                    ] || user.company.personal_info.address
                  }
                  onChange={(value) =>
                    this.addRegistrationData(
                      "Postcode and personal address (last 3 years)",
                      value
                    )
                  }
                />
              </Tooltip>
              <br />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                id='email'
                name='email'
                label={`${t("orderReg.yourEmail")}:`}
                value={
                  registrationData?.["Your e-mail"] ||
                  user.company.personal_info.email
                }
                onChange={(value) =>
                  this.addRegistrationData("Your e-mail", value)
                }
              />

              <TextField
                id='phone'
                name='phone'
                label={`${t("orderReg.phoneNum")}:`}
                value={
                  registrationData?.["Phone number"] ||
                  user.company.personal_info.phone
                }
                onChange={(value) =>
                  this.addRegistrationData("Phone number", value)
                }
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
        </Card>
        <Card title={`${t("orderReg.ifAppl")}:`} sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id='insurance'
                name='insurance'
                label={`${t("orderReg.nin")}:`}
                value={
                  registrationData?.["National Insurance Number"] ||
                  user.company.personal_info.national_insurance_number
                }
                onChange={(value) =>
                  this.addRegistrationData("National Insurance Number", value)
                }
              />

              <TextField
                id='tax_ref'
                name='tax_ref'
                label={`${t("orderReg.utr")}:`}
                value={
                  registrationData?.[
                    "Self Assessment Unique Taxpayer Reference"
                  ] ||
                  user.company.personal_info
                    .self_assessment_unique_taxpayer_reference
                }
                onChange={(value) =>
                  this.addRegistrationData(
                    "Self Assessment Unique Taxpayer Reference",
                    value
                  )
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>
      </div>
    );
  }

  stepBusiness() {
    const { user, t } = this.props; //default values
    const { registrationData } = this.state;

    return (
      <div>
        <Card title={`${t("orderReg.bussInfo")}:`} sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id='registrationNumber'
                name='registrationNumber'
                label={`${t("orderReg.crn")}:`}
                value={
                  registrationData?.["Company Registration Number (CRN)"] ||
                  user.company.info.company_registration_number
                }
                onChange={(value) => {
                  this.addRegistrationData(
                    "Company Registration Number (CRN)",
                    value
                  );
                }}
              />
              <br />
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                label={`${t("orderReg.dateOfInc")}:`}
                datepickerProps={{
                  showYearDropdown: true,
                  yearDropdownItemNumber: 80,
                  scrollableYearDropdown: true,
                  maxDate: Date.now(),
                }}
                value={
                  registrationData?.["Date of incorporation"] ||
                  user.company.info.date_of_incorporation
                }
                allowRange={false}
                onChange={(value) =>
                  this.addRegistrationData(
                    "Date of incorporation",
                    formatDateForServer(value)
                  )
                }
                placeholder='DD-MM-YYYY'
              />

              <TextField
                id='companyName'
                name='companyName'
                label={`${t("orderReg.nameOfCorp")}:`}
                value={
                  registrationData?.["Name of corporate body"] ||
                  user.company.info.name_of_corporate_body
                }
                onChange={(value) => {
                  this.addRegistrationData("Name of corporate body", value);
                }}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>

        <Card title={`${t("orderReg.compContacts")}:`} sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id='corpEmail'
                name='corpEmail'
                label={`${t("orderReg.compEmail")}:`}
                value={
                  registrationData?.["Company e-mail"] ||
                  user.company.email ||
                  ""
                }
                onChange={(value) => {
                  this.addRegistrationData("Company e-mail", value);
                }}
              />
              <br />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                id='corpPhone'
                name='corpPhone'
                label={`${t("orderReg.compPhoneNum")}:`}
                placeholder='+7XXXXXXXXXXXX'
                value={
                  registrationData?.["Company phone number"] ||
                  user.company.phone ||
                  ""
                }
                onChange={(value) => {
                  this.addRegistrationData("Company phone number", value);
                }}
              />

              <TextField
                id='corpAddress'
                name='corpAddress'
                label={`${t("orderReg.compAddress")}:`}
                value={
                  registrationData?.["Company address"] ||
                  user.company.address ||
                  ""
                }
                onChange={(value) => {
                  this.addRegistrationData("Company address", value);
                }}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>
      </div>
    );
  }

  stepNextSaveBar = () => {
    const { user, t } = this.props;
    return (
      <ButtonGroup>
        <Button onClick={this.handlePrev}>{t("orderReg.back")}</Button>
        <Button
          primary
          onClick={() => {
            this.fillEmptyFields(user);
            this.handleNext();
          }}
        >
          {t("orderReg.next")}
        </Button>
      </ButtonGroup>
    );
  };

  stepDynamic = (stepNum) => {
    const { data, registrationData } = this.state;
    const { blocks, t } = this.props;
    const regQuestions = blocks.length > 0 ? blocks[stepNum]?.questions : [];

    return (
      <Card title={blocks?.[stepNum]?.name || ""} sectioned>
        <FormLayout>
          {_.map(regQuestions, (question, index) => {
            switch (question.type) {
              case "checkbox":
                return (
                  <div key={index + question.type}>
                    <div key={question.type + question.id + index}>
                      {question.text}
                    </div>
                    <ChoiceList
                      key={question.id + question.type}
                      name={question.id + question.type}
                      choices={[
                        { label: t("orderReg.yes"), value: "yes" },
                        { label: t("orderReg.no"), value: "no" },
                      ]}
                      selected={registrationData[question.text] || ""}
                      onChange={(value) =>
                        this.addRegistrationData(question.text, value)
                      }
                    />
                  </div>
                );
              case "date":
                return (
                  <div key={index + question.type} style={{ width: "50%" }}>
                    <TextField
                      key={question.id + question.type}
                      name={question.id + question.type}
                      label={question.text}
                      placeholder='DD-MM-YYYY'
                      value={registrationData[question.text]}
                      onChange={(value) =>
                        this.addRegistrationData(question.text, value)
                      }
                    />
                  </div>
                );
              case "select":
                const options = [{ value: "", label: "" }];
                _.map(question.select_data, (item, num) => {
                  options.push({
                    key: num,
                    value: item,
                    label: item,
                  });
                });

                return (
                  <div key={index + question.type} style={{ width: "50%" }}>
                    <Select
                      id={question.type + question.id}
                      name={question.type + question.id}
                      key={question.type + question.id + index}
                      label={question.text}
                      options={options}
                      onChange={(value) => {
                        const num = question.select_data.indexOf(value);
                        data[question.id] = question.select_data[num];
                        this.addRegistrationData(
                          question.text,
                          data[question.id]
                        );
                      }}
                      value={data[question.id] || ""}
                    />
                  </div>
                );
              case "amount":
                return (
                  <div key={index + question.type} style={{ width: "50%" }}>
                    <TextField
                      id='amount'
                      name='amount'
                      label={question.text}
                      type='number'
                      value={data[question.id]}
                      onChange={(value) => {
                        data[question.id] = value;
                        this.addRegistrationData(
                          question.text,
                          data[question.id] +
                            " " +
                            (data[question.id + "curr"] || "EUR")
                        );
                      }}
                      connectedRight={
                        <Select
                          id={question.type + question.id + "curr"}
                          key={question.type + question.id + index + "curr"}
                          value={data[question.id + "curr"]}
                          name={question.type + question.id}
                          options={_.map(question.currency, (item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })}
                          onChange={(value) => {
                            data[question.id + "curr"] = value;
                            this.addRegistrationData(
                              question.text,
                              data[question.id] +
                                " " +
                                data[question.id + "curr"]
                            );
                          }}
                        />
                      }
                    />
                  </div>
                );
              default: //string
                return (
                  <div key={index + question.type}>
                    <TextField
                      id={question.type + question.id}
                      name={question.type + question.id}
                      key={question.type + question.id + index}
                      label={question.text}
                      multiline
                      value={registrationData[question.text]}
                      onChange={(value) =>
                        this.addRegistrationData(question.text, value)
                      }
                    />
                  </div>
                );
            }
          })}
        </FormLayout>
      </Card>
    );
  };

  stepDynamicSaveBar = () => {
    const { t } = this.props;

    return (
      <ButtonGroup>
        <Button onClick={this.handlePrev}>{t("orderReg.back")}</Button>
        <Button onClick={() => this.handleNext()} primary submit>
          {t("orderReg.next")}
        </Button>
      </ButtonGroup>
    );
  };

  docsFiles = {
    primary_evidence: [],
    additional_evidence1: [],
    additional_evidence2: [],
  };

  stepDocs() {
    const { t } = this.props;

    return (
      <div>
        <Card title={"Documents"} sectioned>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="bodyMd" color="critical">
              {t("orderReg.stepDocs.attachDocs")}
            </Text>
            <p>{t("orderReg.stepDocs.whichCopy")}</p>
            <List type='bullet'>
              <List.Item>{t("orderReg.stepDocs.passport")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.photo")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.idCard")}</List.Item>
            </List>

            <p>
              <Text variant="bodyMd" color="critical">
                {t("orderReg.stepDocs.attachFiles")}
              </Text>
            </p>
            {this.renderMultipleFileSelect("primary_evidence")}

            <p>{t("orderReg.stepDocs.secEvid")}</p>
            <List type='bullet'>
              <List.Item>{t("orderReg.stepDocs.mortState")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.agree")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.birthCertif")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.partCertif")}</List.Item>
              <List.Item>{t("orderReg.stepDocs.dissCertif")}</List.Item>
            </List>

            <p>
              <Text variant="bodyMd" color="critical">
                {t("orderReg.stepDocs.attachFirst")}
              </Text>
            </p>
            {this.renderMultipleFileSelect("additional_evidence1")}

            <p>
              <Text variant="bodyMd" color="critical">
                {t("orderReg.stepDocs.attachSecond")}
              </Text>
            </p>
            {this.renderMultipleFileSelect("additional_evidence2")}
          </div>
        </Card>
      </div>
    );
  }

  stepDocsSaveBar = () => {
    const { t } = this.props;

    return (
      <ButtonGroup>
        <Button onClick={() => this.handlePrev()}>{t("orderReg.back")}</Button>
        <Button
          primary
          onClick={() => {
            this.attachFiles(this.docsFiles);
            this.handleNext();
          }}
        >
          {t("orderReg.next")}
        </Button>
      </ButtonGroup>
    );
  };

  stepSubmit() {
    const { t } = this.props;
    const registrationData = this.state.registrationData;
    delete registrationData.step;
    /*
    const registrationDataArr = Object.keys(registrationData).map((key) => {
      return [key, registrationData[key]];
    });*/

    const registrationDataPreview = Object.keys(registrationData).map(
      (key, index) => {
        return {
          index: index + 1,
          question: key,
          answer: registrationData[key],
        };
      }
    );

    const columns = [
      {
        property: "index",
        header: {
          label: "№",
        },
      },
      {
        property: "question",
        header: {
          label: t("orderReg.que"),
        },
      },
      {
        property: "answer",
        header: {
          label: t("orderReg.answ"),
        },
      },
    ];

    const sortedRows = formatRow(registrationDataPreview, columns);

    return (
      <div>
        {!(this.state.orderingResult === "Request is sent") && (
          <Card title={t("orderReg.submitInfo")} sectioned>
            {!_.isEmpty(sortedRows) && (
              <DataTable
                columnContentTypes={["text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={sortedRows}
              />
            )}
          </Card>
        )}

        {this.state.orderingResult === "Request is sent" && (
          <Banner title={t("orderReg.orderPlaced")} status='success'>
            <p>{t("orderReg.invWillBeSent")}</p>
            <p>{t("orderReg.await5wd")}</p>
          </Banner>
        )}
      </div>
    );
  }

  stepSubmitSaveBar = () => {
    const { t } = this.props;
    const { registrationData } = this.state;

    const registrationDataArr = Object.keys(registrationData).map((key) => {
      return [key, registrationData[key]];
    });

    return (
      <div>
        {!(this.state.orderingResult === "Request is sent") && (
          <ButtonGroup>
            <Button onClick={() => this.handlePrev()}>
              {t("orderReg.back")}
            </Button>
            <Button
              primary
              onClick={() => {
                this.uploadRegOrderInformation(registrationDataArr);
              }}
            >
              {t("orderReg.submitInfo")}
            </Button>
          </ButtonGroup>
        )}

        {this.state.orderingResult === "Request is sent" && (
          <ButtonGroup>
            <Button
              primary
              onClick={() => this.props.navigate("/tax-settings")}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </ButtonGroup>
        )}
      </div>
    );
  };

  renderMultipleFileSelect(docType) {
    const { fileNameList } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Button
          onClick={() => this.handleFileSelect(docType)}
          disabled={this.isUploading}
        >
          {t("orderReg.uploadFiles")}
          <input
            type='file'
            multiple
            style={{ display: "none" }}
            ref={(input) => (this.fileUploadInput = input)}
          />
        </Button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div key={index}>
                <Tag
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderContinueDialog = () => {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("orderReg.haveNotFinished")}
        visible={actionDialogs.continue.open}
        content={<p>{t("orderReg.wantCont")}</p>}
        footer={
          <ButtonGroup>
            <Button
              onClick={() => {
                this.handleNext();
                this.handleActionDialogsClose("continue");
              }}
            >
              {t("orderReg.createNewReq")}
            </Button>
            <Button
              primary
              onClick={() => {
                this.setState({
                  registrationData: actionDialogs.continue.data.data,
                  stepIndex:
                    !this.state.lovatAgent &&
                    !this.state.registrationData["Full name"]
                      ? 1
                      : actionDialogs.continue.data?.data?.step || 1,
                });
                this.handleActionDialogsClose("continue");
              }}
            >
              {t("orderReg.cont")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  renderPayDialog = () => {
    const { actionDialogs, data, regReason, regCountry } = this.state;
    const { user, t } = this.props;

    return (
      data.country &&
      regReason && (
        <Modal
          title={t("orderReg.vatRegPayment")}
          visible={actionDialogs.pay.open}
          onClose={() => this.handleActionDialogsClose("pay")}
        >
          <Payment
            currencyIcon={
              user.company.currency_code === "eur" ? " \u{20AC} " : " $ "
            }
            country={regCountry}
            regReason={regReason.name}
            service={"VAT registration"}
            sum={regReason.price}
            comment={`${t("orderReg.vatRegIn1p")} ${data.country.name}${t(
              "orderReg.vatRegIn2p"
            )}${regReason.name} ${t("orderReg.vatRegIn3")}`}
            pplButtonId={data.country.ppl_button_id}
            //closeDialog={this.handleActionDialogsClose('pay')}
            //openNextDialog={this.handleActionDialogsOpen('notification')}
            closeDialog={this.handleActionDialogsClose}
            openNextDialog={this.handleActionDialogsOpen}
            currentDialog='pay'
            nextDialog='notification'
          />
        </Modal>
      )
    );
  };

  renderNotificationDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("orderReg.payment")}
        visible={actionDialogs.notification.open}
        onClose={() => {
          this.handleActionDialogsClose("notification");
        }}
        description={
          <div style={{ maxWidth: "450px" }}>{t("vatReg.thankForReq")}</div>
        }
        footer={
          <Button
            primary
            onClick={() => {
              this.handleActionDialogsClose("notification");
            }}
          >
            Ok
          </Button>
        }
      />
    );
  }

  renderStepper = () => {
    const { t, blocks } = this.props;
    const { stepIndex } = this.state;
    //const stepIndex = 1;

    let steps = [{ st: 1, name: t("orderReg.steps.intro") }];

    if (blocks) {
      // blocks received
      if (!this.state.lovatAgent) {
        steps.push({ st: 2, name: t("orderReg.steps.persInfo") });
      }
      steps.push({ st: 3, name: t("orderReg.steps.business") });
      steps = steps.concat(blocks); // dynamic blocks
      steps.push({ st: 98, name: t("orderReg.steps.docs") });
      steps.push({ st: 99, name: t("orderReg.steps.submit") });
    }

    return (
      <div>
        <Stepper
          steps={_.map(steps, (step) => {
            return {
              title: step.name,
              icon: step1IMG,
            };
          })}
          activeStep={stepIndex}
          titleFontSize={14}
          activeColor='#E4F3FE'
          completeColor='#216DC5'
          circleFontColor='#212B36'
          defaultTitleColor='#919EAB'
          completeTitleColor='#919EAB'
          activeTitleColor='#212B36'
          completeBorderColor={"#367C41"}
          defaultBorderWidth={4}
          defaultBarColor='#ADADAD'
          size={24}
          circleFontSize={14}
        />
      </div>
    );
  };

  render() {
    const { stepIndex } = this.state;
    const { t } = this.props;

    const title =
      this.state.data.country &&
      this.state.data.country !== 0 &&
      ` in ${this.state.data.country.name}`;

    return (
      <Page
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>{`${t("orderReg.orderVatReg")}${
            title ? title : ""
          }`}</Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            Here you can order a VAT number
          </Text>
        }
        separator
      >
        <PageHelmet title={"Order VAT Registration"} />

        <br />
        <Layout>
          <Layout.Section secondary>{this.renderStepper()}</Layout.Section>
          <Layout.Section>
            <div style={{ marginBottom: 102 }}>
              {!this.state.orderingResult && this.registrationPrice()}
              {this.getStepContent(stepIndex)}
              <SaveBar title={t("orderReg.orderVatReg")}>
                {this.getSaveBarContent(stepIndex)}
              </SaveBar>
            </div>
          </Layout.Section>
        </Layout>

        {this.renderContinueDialog()}
        {this.renderPayDialog()}
        {this.renderNotificationDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countriesAll,
  regCountries: state.vatRegistration.regCountries,
  blocks: state.vatRegistration.blocks,
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchRegQuestions: (params) => dispatch(fetchRegQuestions(params)),
    fetchUser: () => dispatch(fetchUser()),
    orderRegNumber: (params) => dispatch(orderRegNumber(params)),
    sendRegOrderData: (params) => dispatch(sendRegOrderData(params)),
    getAllNotCompletedOrders: (params) =>
      dispatch(getAllNotCompletedOrders(params)),
    fetchAllCountries: () => dispatch(fetchAllCountries()),
    fetchRegCountries: () => dispatch(fetchRegCountries()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderRegistration))
);
