import React, { useEffect } from "react";
import moment from "moment";
import {
  Box,
  Card,
  Layout,
  Page,
  Spinner,
  Text,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchMessages,
  setMessageRead,
} from "../../redux/features/messages/messagesSlice";
import { downloadMessageFile } from "../../redux/features/messenger/messengerSlice";

import ClipIMG from "img/clip.svg";
import { isTaxAgentSelector } from "../../redux/selectors";
import useAppDispatch from "hooks/useAppDispatch";
import BackArrowWithComponent from "components/BackArrow";

const Message = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { user } = useSelector((state) => state.user);
  const IS_TAX_AGENT = isTaxAgentSelector(user);
  const { messages, fetchingMessages } = useSelector((state) => state.messages);
  const message = messages?.[0] || {};

  useEffect(() => {
    const message_id = params.messageId;
    dispatch(fetchMessages({ message_id })).then((msg) => {
      if (!user?.is_manager && !msg?.items[0].read) {
        dispatch(setMessageRead(message_id));
      }
    });
  }, []);

  const downloadFile = (data) => {
    dispatch(downloadMessageFile(data)).then((toDownload) => {
      const from = data.indexOf("/") + 1;
      const to = data.lastIndexOf(".");
      const fileName = data.substring(from, to);
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName); // data - filename
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  };

  return (
    <>
      {fetchingMessages ? (
        <div style={{ padding: "1rem" }}>
          <Spinner />
        </div>
      ) : (
        <Page
          fullWidth
          title={
            <BackArrowWithComponent
              onClick={() => navigate(-1)}
              withComponent={
                <Text variant='heading3xl' as='span'>
                  {t("messages.messageFrom")} {message.sender}
                </Text>
              }
            />
          }
        >
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                >
                  <Text variant='headingMd' as='h3'>
                    {message.subject}
                  </Text>
                  <span dangerouslySetInnerHTML={{ __html: message.text }} />
                  {message.files && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {message.files.map((f, index) => (
                        <div
                          key={`${index}-file`}
                          className='messages__message__file'
                        >
                          <img
                            className='Button__Prefix'
                            src={ClipIMG}
                            alt='icon save'
                          />
                          <a
                            className='Polaris-Link'
                            onClick={() => downloadFile(f)}
                          >
                            {f.slice(14)}
                          </a>
                        </div>
                      ))}
                    </Box>
                  )}
                  <Text variant='bodySm' color='subdued'>
                    {moment(message.sent_at).format("DD/MM/YYYY hh:mm")}
                  </Text>

                  {IS_TAX_AGENT && (
                    <>
                      <Text color='subdued'>
                        This is a message for the client: {message.company_name}
                      </Text>
                    </>
                  )}
                </Box>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      )}
    </>
  );
};

export default Message;
