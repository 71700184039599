import useAppDispatch from "hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchDocumentsMessages } from '../../../redux/features/documents/documentsSlice'

const useFetchDocumentsMessages = () => {
  const dispatch = useAppDispatch();
  const { messages } = useSelector((state) => state.documents);

  useEffect(() => {
    dispatch(fetchDocumentsMessages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { messages };
};

export default useFetchDocumentsMessages;
