import React from "react";
import formatRow from "utils/formatRow";
import {
  Card,
  DataTable,
  FormLayout,
  Text,
} from "@shopify/polaris";
import _ from "lodash";
import { t } from "i18next";

const TextilePreview = (props) => {
  const { data, tableProps, questions = [] } = props;

  const columns = [
    {
      property: "product",
      header: {
        label: t("eprReporting.product"),
      },
    },
    {
      property: "category",
      header: {
        label: t("eprReporting.category"),
      },
    },
    {
      property: "productTypology",
      header: {
        label: t("eprReporting.productTypologies"),
      },
    },
    {
      property: "number",
      header: {
        label: t("eprReporting.number"),
      },
    },
  ];
  const sortedRows = !_.isEmpty(data) ? formatRow(data, columns) : [];

  return (
    <>
      {questions.length && (
        <>
          <FormLayout>
            {questions.map(({ id, name, value }) => {
              return (
                <FormLayout.Group key={id}>
                  <Text>{name}</Text>
                  <Text color='subdued'>
                    {value ? t("eprReporting.yes") : t("eprReporting.no")}
                  </Text>
                </FormLayout.Group>
              );
            })}
          </FormLayout>
          <br />
        </>
      )}
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "text", "numeric"]}
          headings={columns.map(({ header }) => {
            return <Text fontWeight='semibold'>{header.label}</Text>;
          })}
          rows={sortedRows}
          hideScrollIndicator
          increasedTableDensity
          {...tableProps}
        />
      </Card>
    </>
  );
};

export default TextilePreview;
