import React, { Component } from "react";
import {
  Page,
  Layout,
  Card,
  Badge,
  Button,
  Select,
  FormLayout,
  Banner,
  DataTable,
  Text,
  Spinner,
  Tooltip,
  TextField,
  Box,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  editDialogStatus,
  fetchListCompanySupport,
} from "../../../redux/features/manager/managerSlice";
import Modal from "components/Modal/Modal";
import { formatDate } from "utils/dates";
import { formatTime } from "utils/dates";
import { Link } from "react-router-dom";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import Pagination from "components/Pagination/Pagination";
import _ from "lodash";

import editIMG from "img/settings.svg";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { createCustomDispatch } from "helpers/customDispatch";
import cx from "classnames";

import "./index.scss";
import { fetchHistoryDialogs } from '../../../redux/features/messenger/messengerSlice'

const statusArr = [
  {
    value: "info",
    label: "info",
  },
  {
    value: "pending lovat action",
    label: "pending lovat action",
  },
  {
    value: "action required",
    label: "action required",
  },
  {
    value: "answered",
    label: "answered",
  },
];

const defaultLimit = 25;
const DEBOUNCE_DELAY_MS = 500;

class SupportMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCompany: {},
      sortingColumns: [],
      searchColumn: "all",
      pagination: {
        page: 1,
        perPage: defaultLimit,
      },

      query: {
        limit: defaultLimit,
        searchQuery: "",
      },

      subject: "",
      errorSubject: false,
      message: "",
      errorMessage: false,

      actionDialogs: {
        status: { open: false },
      },
    };

    this.onSelectPage = this.onSelectPage.bind(this);
  }

  componentDidMount() {
    this.props.fetchHistoryDialogs();
    this.props.fetchListCompanySupport({ limit: defaultLimit });
  }

  debouncedFetchCompanies = _.debounce((searchTerm) => {
    this.props.fetchListCompanySupport({ query: searchTerm });
  }, DEBOUNCE_DELAY_MS);

  handleSearch = (searchTerm) => {
    this.setState(
      (prevState) => ({
        query: {
          ...prevState.query,
          searchQuery: searchTerm,
        },
      }),
      () => this.debouncedFetchCompanies(searchTerm)
    );
  };

  onSelectPage = (inputPage, count) => {
    const pages = Math.ceil(count / this.state.pagination.perPage) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchListCompanySupport(this.state.query);
      }
    );
  };

  doEditDialogStatus = (cellData) => {
    const { status, selectedCompany } = this.state;

    const data = {
      dialog_id: cellData.id,
      status,
    };
    this.props
      .editDialogStatus({ data })
      .then(() => {
        this.props.fetchHistoryDialogs({
          unread: false,
          company_id: selectedCompany.id,
        });
        this.setState({ changeDialogSuccess: true });
      })
      .catch((err) => {
        this.setState({ errorChangeDialog: err.error });
      });
  };

  handleCompanySelect = (selectedCompany) => {
    this.setState({ selectedCompany }, () =>
      this.props.fetchHistoryDialogs({
        unread: false,
        company_id: selectedCompany.id,
      })
    );
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({
      actionDialogs,
      status: "",
      changeDialogSuccess: false,
      errorChangeDialog: false,
    });
  };

  renderChangeStatusDialog = () => {
    const { t } = this.props;
    const { actionDialogs, status, changeDialogSuccess, errorChangeDialog } =
      this.state;

    const onClose = () => {
      this.handleActionDialogsClose("status");
    };

    return (
      <Modal
        title={`${t("supportMessages.caseNum")}${" "}
            ${
              actionDialogs.cellData &&
              actionDialogs.cellData.id &&
              actionDialogs.cellData.id.toString().padStart(10, "0")
            }`}
        visible={actionDialogs.status.open}
        onClose={onClose}
        content={
          <FormLayout>
            <FormLayout.Group>
              <Select
                id='status'
                label='Please select the status to change'
                options={statusArr}
                onChange={(value) => this.setState({ status: value })}
                value={
                  status
                    ? status
                    : actionDialogs.cellData &&
                      actionDialogs.cellData.dialog_status
                }
              />
            </FormLayout.Group>
            {changeDialogSuccess && (
              <div>
                <Banner status='success'>
                  Status has been successfully changed
                </Banner>
                <br />
              </div>
            )}
            {errorChangeDialog && (
              <div>
                <Banner status='critical'>{errorChangeDialog}</Banner>
                <br />
              </div>
            )}
          </FormLayout>
        }
        footer={
          <Button
            primary
            disabled={!status}
            onClick={() => this.doEditDialogStatus(actionDialogs.cellData)}
          >
            Change
          </Button>
        }
      />
    );
  };

  renderManagerStepIfNeeded = () => {
    const {
      pagination,
      selectedCompany,
      query: { searchQuery },
    } = this.state;
    const {
      user,
      fetchingListCompany,
      listCompanySupport: { companies, count },
    } = this.props;

    const shouldRenderManagerStep = user.is_manager && !selectedCompany.id;

    if (!shouldRenderManagerStep) {
      return;
    }

    const columns = [
      {
        property: "id",
        header: {
          label: "Merchant ID",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Button
                  plain
                  onClick={() =>
                    this.handleCompanySelect({
                      id: rowData.id,
                      name: rowData.name,
                    })
                  }
                >
                  {value.toString().padStart(10, "0")}
                </Button>
              );
            },
          ],
        },
      },
      {
        property: "name",
        header: {
          label: "Name",
        },
      },
      {
        property: "message_count",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value) => (
              <div
                className={cx("message-count", {
                  active: Boolean(value),
                })}
              >
                {value}
              </div>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(companies ?? [], columns);

    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <Layout.Section>
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
          width="100%"
        >
          <TextField
            placeholder='Search for companies by name...'
            onChange={(query) => this.handleSearch(query)}
            value={searchQuery}
          />
          {fetchingListCompany ? (
            <Card sectioned>
              <Spinner />
            </Card>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              width="100%"
            >
              <Card>
                <DataTable
                  columnContentTypes={["text", "text", "text"]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={paginatedRows.rows}
                  hideScrollIndicator
                />
              </Card>
              <Pagination
                total={count}
                current={pagination.page}
                pageSize={defaultLimit}
                onChange={(current) => this.onSelectPage(current, count)}
              />
            </Box>
          )}
        </Box>
      </Layout.Section>
    );
  };

  renderUserStepIfNeeded = () => {
    const { t, fetchingHistory, historyDialogs, user } = this.props;
    const { selectedCompany } = this.state;

    const shouldRenderUserStep = !user.is_manager || selectedCompany.id;

    if (!shouldRenderUserStep) {
      return;
    }

    const rows = _.cloneDeep(historyDialogs);

    if (rows.length) {
      rows.forEach((item) => {
        const d = new Date(item.modified);
        item.modified = `${formatDate(d)} ${formatTime(d)}`;

        item.messagesCount = item.messages.reduce((count, message) => {
          return !message.read && message.sender_id !== user.id
            ? count + 1
            : count;
        }, 0);
      });
    }

    const columns = [
      {
        property: "id",
        header: {
          label: t("supportMessages.caseNum"),
        },
        cell: {
          formatters: [
            (value) => (
              <Link className='Polaris-Link' to={`/help/messenger/${value}`}>
                {value.toString().padStart(10, "0")}
              </Link>
            ),
          ],
        },
      },
      {
        property: "dialog_status",
        header: {
          label: t("supportMessages.status"),
        },
        cell: {
          formatters: [
            (value) => {
              let status = "";
              switch (value.toLowerCase()) {
                case "info":
                  status = (
                    <Badge status={"info"}>{t("supportMessages.info")}</Badge>
                  );
                  break;
                case "action required":
                  status = (
                    <Badge status={"warning"}>
                      {t("supportMessages.actionR")}
                    </Badge>
                  );
                  break;
                case "pending lovat action":
                  status = (
                    <Badge status={"success"}>
                      {t("supportMessages.pendingLovat")}
                    </Badge>
                  );
                  break;
                default:
                  status = <Badge>{t("supportMessages.answered")}</Badge>;
              }
              return status;
            },
          ],
        },
      },
      {
        property: "case_group",
        header: {
          label: "Case group",
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ textTransform: "capitalize" }}>{value}</span>
            ),
          ],
        },
      },
      {
        property: "short_description",
        header: {
          label: t("supportMessages.desc"),
        },
        cell: {
          formatters: [(value) => <Text color='subdued'>{value}</Text>],
        },
      },
      {
        property: "modified",
        header: {
          label: t("supportMessages.dateUp"),
        },
        cell: {
          formatters: [(value) => value],
        },
      },
      {
        property: "messagesCount",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value) => (
              <div
                className={cx("message-count", {
                  active: Boolean(value),
                })}
              >
                {value}
              </div>
            ),
          ],
        },
      },
    ];

    if (user.is_manager) {
      columns.push({
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                <Tooltip content='Edit'>
                  <Button
                    plain
                    onClick={() =>
                      this.handleActionDialogsOpen("status", rowData)
                    }
                  >
                    <img src={editIMG} alt='edit' />
                  </Button>
                </Tooltip>
              </div>
            ),
          ],
        },
      });
    }

    const sortedRows = formatRow(rows, columns);

    return (
      <Layout.Section>
        {fetchingHistory ? (
          <Card sectioned>
            <Spinner />
          </Card>
        ) : (
          <>
            <Card>
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={sortedRows}
                hideScrollIndicator
                truncate
              />
              {_.isEmpty(sortedRows) && (
                <NoDataMessage
                  title={"All your support requests will be there"}
                  description={"Create a request first"}
                  buttonText={"Create a request"}
                />
              )}
            </Card>
          </>
        )}
      </Layout.Section>
    );
  };

  render() {
    const { selectedCompany } = this.state;

    return (
      <Page
        title={`Requests ${selectedCompany.name ?? ""}`}
        primaryAction={{
          content: "Create a request",
          onAction: () => this.props.navigate("/help/messenger/case"),
        }}
        secondaryActions={
          selectedCompany.id
            ? [
                {
                  content: "View all companies",
                  onAction: () => this.setState({ selectedCompany: {} }),
                },
              ]
            : []
        }
        fullWidth
      >
        <Layout>
          {this.renderManagerStepIfNeeded()}
          {this.renderUserStepIfNeeded()}
          {this.renderChangeStatusDialog()}
        </Layout>
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  historyDialogs: state.messenger.historyDialogs,
  fetchingHistory: state.messenger.fetchingHistory,
  user: state.user.user,
  listCompanySupport: state.manager.listCompanySupport,
  defaultLanguage: state.user.defaultLanguage,
  fetchingListCompany: state.manager.fetchingListCompanySupport,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchHistoryDialogs: (params) => dispatch(fetchHistoryDialogs(params)),
    fetchListCompanySupport: (params) =>
      dispatch(fetchListCompanySupport(params)),
    editDialogStatus: (params) => dispatch(editDialogStatus(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SupportMessages))
);
