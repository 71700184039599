import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory4 } from "../../../../redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Text,
  DataTable,
  Checkbox, Box,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";
import isEqual from '../Step_3/helper';

const SubcategoryStep4 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory3, selectedCategory4 },
  } = useSelector((state) => state.declarations);
  
  const handleChange = (
    checked,
    category,
    subCategory,
    subSubCategory,
    subSubSubCategory
  ) => {
    dispatch(
      selectCategory4({
        checked,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory
      })
    );
  };
  
  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => rowData[0],
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{rowData[1]}</div>,
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
              {rowData[2]?.name ? `${rowData[2]?.name} ${rowData[2]?.units}` : rowData[2]}
            </div>
          ),
        ],
      },
    },
    {
      header: {
        label: "Subcategory 4",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const [category, subCategory, subSubCategory] = rowData;
            const checkboxes = subcategories_data.subcategory_4[subSubCategory?.name ? subSubCategory?.name : subCategory];
            return (
              <Box fullWidth style={{ display: 'flex', flexDirection: 'column', gap: '16px', whiteSpace: 'normal' }}>
                {
                  checkboxes && typeof checkboxes === 'object'
                    ?
                      checkboxes.map((checkbox, index) => {
                        const id = `${checkbox}-${category}-${subCategory}-${subSubCategory?.name || subSubCategory}-${subSubCategory?.units || ''}-${index}`;
                        
                        return (
                          <Checkbox
                            key={id}
                            id={id}
                            value={checkbox}
                            label={checkbox}
                            checked={selectedCategory4.some((c) => {
                              const isCategoryMatch = c[0] === category;
                              const isSubCategoryMatch = c[1] === subCategory;
                              const isSubSubCategoryMatch = typeof c[2] === 'object' && typeof subSubCategory === 'object'
                                ? c[2].name === subSubCategory.name && c[2].units === (subSubCategory.units || '')
                                : c[2] === subSubCategory;
                              const isCheckboxMatch = c[3] === checkbox;
                              return isCategoryMatch && isSubCategoryMatch && isSubSubCategoryMatch && isCheckboxMatch;
                            })}
                            onChange={(checked) => handleChange(checked, category, subCategory, subSubCategory, checkbox)}
                          />
                        );
                      })
                    : !!subcategories_data?.subcategory_4?.Import
                      ?
                      subcategories_data?.subcategory_4?.Import?.map((checkbox) => {
                        const id = `${checkbox}-${category}-${subCategory}-${subSubCategory.name}-${subSubCategory.units || ''}`;
                        return (
                          <Checkbox
                            key={id}
                            id={id}
                            value={checkbox}
                            label={`${checkbox}`}
                            checked={selectedCategory4.some(
                              (c) =>
                                c[0] === category &&
                                c[1] === subCategory &&
                                isEqual(c[2], subSubCategory) &&
                                isEqual(c[3], checkbox)
                            )}
                            onChange={(newCheckedValue) => {
                              handleChange(newCheckedValue, category, subCategory, subSubCategory, checkbox);
                            }}
                          />
                        );
                      })
                      : // Обрабатываем другие случаи
                      Array.isArray(subcategories_data?.subcategory_4) &&
                      subcategories_data?.subcategory_4.map((checkbox) => {
                        const id = `${checkbox.name}-${checkbox.units || ''}-${category}-${subCategory}-${subSubCategory}`;
                        return (
                          <Checkbox
                            key={id}
                            id={id}
                            value={checkbox.name}
                            label={`${checkbox.name} (${checkbox.units || ''})`}
                            checked={selectedCategory4.some(
                              (c) =>
                                c[0] === category &&
                                c[1] === subCategory &&
                                c[2] === subSubCategory &&
                                c[3]?.name === checkbox.name &&
                                c[3]?.units === (checkbox.units || '')
                            )}
                            onChange={(newCheckedValue) => {
                              handleChange(newCheckedValue, category, subCategory, subSubCategory, checkbox);
                            }}
                          />
                        );
                      })
                }
              </Box>
            );
          },
        ],
      },
    },
  ];
  
  const rows = formatRow(selectedCategory3, columns);
  
  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight="semibold">{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep4;
