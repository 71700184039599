import { Banner, Box, Button, Inline, Layout } from '@shopify/polaris'
import useAppDispatch from 'hooks/useAppDispatch'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	fetchAutosubmitCountries,
	fetchAutosubmitStores,
	saveAutosubmit, updateTaxSettingsAutopilotCountriesList
} from '../../../redux/features/tax-settings/taxSettingsSlice'
import AutopilotCountries from './AutopilotCountries'
import AutopilotStores from './AutopilotStores'
import ModalForAutopilotDetails from './Modals/ModalForAutopilotDetails'
import ModalForChangeStatusOneEl from './Modals/ModalForChangeStatusOneEl'

const AutopilotDetailsBuilder = () => {
	const {
		autoCountries,
		savingAutoError,
		savingAuto,
		autoStores,
		fetchingAutoStores,
		fetchingAutoCountries,
	} = useSelector(state => state.taxSettings)
	const dispatch = useAppDispatch()
	const [countriesList, setCountriesList] = useState([])
	const [storeList, setStoreList] = useState([])
	const [countriesCheckedList, setCountriesCheckedList] = useState([])
	const [storeCheckedList, setStoreCheckedList] = useState([])
	const [showSaveModal, setShowSaveModal] = useState(false)
	const [showChangeModalStatusOneEL, setShowChangeModalStatusOneEL] = useState(false)
	const [checkedStatus, setCheckedStatus] = useState(false)
	const [changeCountryData, setChangeCountryData] = useState({})
	const [errorMessageStatusCountries, setErrorMessageStatusCountries] = useState(false)
	const [errorMessageStatusStore, setErrorMessageStatusStore] = useState(false)
	
	const toggleShowChangeStatusOneElModal = () => {
		setShowChangeModalStatusOneEL(!showChangeModalStatusOneEL)
	}
	useEffect(() => {
		const getData = async () => {
			try {
				await dispatch(fetchAutosubmitCountries())
				await dispatch(fetchAutosubmitStores())
			} catch (e) {
				console.log(e)
			}
		}
		getData()
	}, [])
	
	useEffect(() => {
		setCountriesList(autoCountries)
		setCountriesCheckedList(
			autoCountries?.map(country => ({
				code: country.country.code,
				autosubmit: country.type_of_report.autosubmit,
				type_of_report_id: country.type_of_report.id
			}))
		)
		
		setStoreList(autoStores)
		setStoreCheckedList(
			autoStores?.map(store => ({
				title: store.title,
				id: store.id,
				autosubmit: store.autosubmit,
			})))
	}, [autoCountries, autoStores])
	
	const errorMessageStatusCountriesHandler = () => {
		const allStoresAutoSubmit = storeCheckedList.every(item => item.autosubmit)
		const hasSelectedCountry = countriesCheckedList.some(item => item.autosubmit)
		if (allStoresAutoSubmit && hasSelectedCountry) {
			setErrorMessageStatusCountries(false)
			setErrorMessageStatusStore(false)
			setShowSaveModal(true)
		} else {
			if (!hasSelectedCountry) {
				setErrorMessageStatusCountries(true)
			} else {
				setErrorMessageStatusCountries(false)
			}
			if (!allStoresAutoSubmit) {
				setErrorMessageStatusStore(true)
			} else {
				setErrorMessageStatusStore(false)
			}
			setShowSaveModal(false)
		}
	}
	
	const handleCheckboxChange = (
		checked,
		code,
		store) => {
		if (store === 'store') {
			setStoreCheckedList(prevList =>
				prevList.map(item =>
					item.id === code ? {...item, autosubmit: checked} : item
				)
			)
			setErrorMessageStatusStore(false)
		} else {
			setCountriesCheckedList(prevList =>
				prevList.map(country =>
					country.code === code ? {...country, autosubmit: checked} : country
				)
			)
			setErrorMessageStatusCountries(false)
		}
	}
	
	const saveConfirmedHandler = async () => {
		const data = {
			countries: countriesCheckedList.map((item) => ({
				autosubmit: item.autosubmit,
				country_code: item.code,
				type_of_report_id: item.type_of_report_id,
			})),
			stores: storeCheckedList.map((item) => ({
				autosubmit: item.autosubmit,
				id: item.id,
			})),
		}
		
		try {
			const res = await dispatch(saveAutosubmit(data))
			if (res.status === 200) {
				setShowSaveModal(false)
				await dispatch(updateTaxSettingsAutopilotCountriesList(data, autoCountries))
			}
		} catch (e) {
			setShowSaveModal(false)
		}
	}
	
	const saveOneChangeCountryHandler = async (value) => {
		const updatedData = autoCountries.map(item => {
			if (item.country.code === value.country.code && item.type_of_report.id === value.type_of_report.id) {
				return {
					...item,
					type_of_report: {
						...item.type_of_report,
						autosubmit: !value.type_of_report.autosubmit
					}
				};
			}
			return item;
		});
		const dataTeh = {
			countries: updatedData.map((item) => ({
				autosubmit: item.type_of_report.autosubmit,
				country_code: item.country.code,
				type_of_report_id: item.type_of_report.id,
			})),
			stores: storeCheckedList.map((item) => ({
				autosubmit: item.autosubmit,
				id: item.id,
			})),
		}
		try {
			const res = await dispatch(saveAutosubmit(dataTeh))
			if (res.status === 200) {
				showChangeModalStatusOneEL && toggleShowChangeStatusOneElModal()
				await dispatch(updateTaxSettingsAutopilotCountriesList(dataTeh, autoCountries))
			}
		} catch (e) {
			showChangeModalStatusOneEL && toggleShowChangeStatusOneElModal()
		}
	}
	
	return (
		<React.Fragment >
			{
				showSaveModal &&
				<ModalForAutopilotDetails
					showSaveModal
					saveConfirmed={saveConfirmedHandler}
					onHide={setShowSaveModal}
					checkedStatus={checkedStatus}
					setCheckedStatus={setCheckedStatus}
				/>
			}
			{
				showChangeModalStatusOneEL &&
				<ModalForChangeStatusOneEl
					data={changeCountryData}
					toggleShowChangeStatusOneElModal={toggleShowChangeStatusOneElModal}
					actionDialogs={showChangeModalStatusOneEL}
					saveOneChangeCountryHandler={saveOneChangeCountryHandler}
				/>
			}
			
			{
				savingAutoError && (
					<Layout.AnnotatedSection >
						<Banner status='critical'>{savingAutoError}</Banner >
					</Layout.AnnotatedSection >
				)}
			
			{
				savingAuto && (
					<Layout.AnnotatedSection >
						<Banner status='success'>{t("taxSettings.autoSubSaved")}</Banner >
					</Layout.AnnotatedSection >
				)}
			<Box >
				<Box
					display="flex"
					alignItems="end"
				>
					<Button
						primary
						loading={savingAuto}
						// onClick={() => setShowSaveModal(true)}
						onClick={errorMessageStatusCountriesHandler}
					>
						{t("taxSettings.save")}
					</Button >
				</Box >
			</Box >
			
			<AutopilotCountries
				countriesList={countriesList}
				setChangeCountryData={setChangeCountryData}
				toggleShowChangeStatusOneElModal={toggleShowChangeStatusOneElModal}
				countriesCheckedList={countriesCheckedList}
				handleCheckboxChange={handleCheckboxChange}
				fetchingAutoCountries={fetchingAutoCountries}
				errorMessageStatus={errorMessageStatusCountries}
			/>
			<AutopilotStores
				storeList={storeList}
				storeCheckedList={storeCheckedList}
				handleCheckboxChange={handleCheckboxChange}
				fetchingAutoStores={fetchingAutoStores}
				errorMessageStatus={errorMessageStatusStore}
			/>
		</React.Fragment >
	)
}

export default AutopilotDetailsBuilder
