import { Button, ButtonGroup } from "@shopify/polaris";
import { t } from "i18next";
import Modal from "../Modal";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router";

const LogoutModal = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loggingOut } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout())
      .then(() => navigate("/login"))
      .catch(_.noop);
  };

  return (
    <Modal
      title={t("profile.logOut")}
      visible={visible}
      contentOnCenter
      onClose={onClose}
      iconType={"warning"}
      description={t("profile.sureLogout")}
      footer={
        <ButtonGroup fullWidth>
          <Button size='large' onClick={onClose}>
            {t("profile.no")}
          </Button>
          <Button primary size='large' onClick={onLogout} loading={loggingOut}>
            {t("profile.yes")}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default LogoutModal;
