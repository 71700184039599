import React from "react";
import "./index.scss";
import { Box, Text } from "@shopify/polaris";

const ToggleSwitch = ({ label, onChange, checked }) => {
  return (
    <Box display="flex" alignItems="start" gap="4">
      <label className='switch'>
        <input
          type='checkbox'
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
        <span className='slider round'></span>
      </label>
      {label && <Text>{label}</Text>}
    </Box>
  );
};

export default ToggleSwitch;
