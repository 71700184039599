import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Box, Button, Text } from "@shopify/polaris";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import ParrotIMG from "img/userGuideParrot.svg";
import closeIMG from "img/close.svg";

import "./index.scss";
import {
  fetchUserGuide,
  skipStepUserGuide,
} from "../../redux/features/user/userSlice";
import useAppDispatch from "hooks/useAppDispatch";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#root");

const GuideCompletionPopup = ({ title, description, visible, step }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const doSkipStep = () => {
    return dispatch(skipStepUserGuide(step))
      .then(() => {
        dispatch(fetchUserGuide());
      })
      .catch(_.noop);
  };

  const onClose = () => {
    doSkipStep();
  };

  const onNavigate = async () => {
    await doSkipStep();

    navigate("/dashboard");
  };

  return (
    <ReactModal
      closeTimeoutMS={100}
      isOpen={visible}
      onRequestClose={onClose}
      className='guideCompletionModal'
      overlayClassName='reactModalOverlay'
    >
      <img
        src={closeIMG}
        alt='close'
        onClick={onClose}
        className='guideCompletionModal__close'
      />
      <Box display="flex" alignItems="start" gap="4">
        <img src={ParrotIMG} alt='parrot' />
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
        >
          <Text fontWeight='bold' variant='bodyLg'>
            {title}
          </Text>
          <Text>
            <div style={{ maxWidth: "290px" }}>{description}</div>
          </Text>
          <Button plain onClick={onNavigate}>
            {t("userGuide.goToUserGuide")}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

GuideCompletionPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default GuideCompletionPopup;
