import { Checkbox } from '@shopify/polaris'
import { getInvoiceTypeColumns } from './ShablonLineForTable'

export const getColumns = (
	selectedRows,
	formatDate,
	documents,
	handleSelectAll,
	handleRowSelect,
	query,
	getBadgeStatus,
	renderActions,
	invoiceTypes,
	getCurrencySymbol,
	formatMoney
) => {
	const checkedStyle = (value) => {
		return selectedRows.includes(value.id)
	}
	const commonColumns = [
		{
			property: "checkbox",
			header: {
				label: (
					<Checkbox
						label=''
						checked={
							selectedRows.length > 0 &&
							selectedRows.length === documents.length
						}
						onChange={handleSelectAll}
					/>
				),
			},
			cell: {
				formatters: [
					(value, { rowData }) => (
						<Checkbox
							label=''
							checked={selectedRows.includes(rowData.id)}
							onChange={() => handleRowSelect(rowData.id)}
						/>
					),
				],
			},
		},
		{
			property: "created_date",
			header: { label: "Created date" },
			cell: {
				formatters: [(value,
					{rowData}) => {
					const bgc = checkedStyle(rowData)
					const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
					return (<div
						className='changeLineInTableColor-ischecked'
						style={{backgroundColor}}
					>
				          {formatDate(value)}
				        </div >);
				},],
			},
		},
		{
			property: "due_date",
			header: { label: "Due date" },
			cell: {
				formatters: [(value,
					{rowData}) => {
					const bgc = checkedStyle(rowData)
					const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
					return (<div
						className='changeLineInTableColor-ischecked'
						style={{backgroundColor}}
					>
				          {formatDate(value)}
					</div >);
				},],
			},
		},
	]
	const columns = getInvoiceTypeColumns(query?.invoice_type, commonColumns, query, documents, getBadgeStatus, renderActions, getCurrencySymbol, formatMoney, selectedRows);
	
	const rows = documents.map((document, index) => ({
		id: document.id, // Add a unique ID for each row
		cells: columns.map(column => ({
			content: document[column.property] || ''
		})),
	}));
	
	return {
		columns,
		rows,
	};
}
