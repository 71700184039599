import { Button } from '@shopify/polaris'
import Modal from 'components/Modal'
import React from 'react'

const ModalForChangeStatusOneEl = ({
	data,
	toggleShowChangeStatusOneElModal,
	actionDialogs,
	saveOneChangeCountryHandler
}) => {
	return (
		<Modal
			title={"Edit active status"}
			onClose={toggleShowChangeStatusOneElModal}
			iconType='warning'
			description={
				<p style={{display: "flex", alignItems: "center"}}>
				  {`Do you want to edit active status for `}<p
					style={{
						padding: '0 0 0 5px',
						fontWeight: 'bold'
					}}
				>{data?.type_of_report?.name}</p >{`, `}<p
					style={{
						padding: '0 0 0 5px',
						fontWeight: 'bold'
					}}
				>{data?.country?.name}</p >{`?`}
				</p >
			}
			visible={actionDialogs}
			content={
				<>
					<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
						status
					</div>
				</>
			}
			footer={
				<Button
					primary
					onClick={() => saveOneChangeCountryHandler(data)}
				>
					{data?.type_of_report?.autosubmit ? "Deactivate" : "Activate"}
				</Button >
			}
		/>
	)
}

export default ModalForChangeStatusOneEl