import React, { Component } from "react";
import { Button, FormLayout, TextField } from "@shopify/polaris";
import withRouter from "helpers/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { login } from '../../../../redux/features/auth/authSlice'
import AuthContainer from "../../AuthContainer";
import { createCustomDispatch } from "helpers/customDispatch";

class LogInAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: false,
      password: "",
      errorPassword: false,
    };
  }

  handleChangeCheckedRememberPassword = (value) => {
    this.setState({ passwordChecked: value });
  };

  validateLogin = (email) => {
    if (email.length === 0) {
      this.setState({
        errorEmail: `Enter your login`,
      });
    } else {
      this.setState({
        errorEmail: false,
      });
    }
  };

  doLogin = () => {
    const { email, password } = this.state;
    if (!password) {
      this.setState({ errorPassword: "Please enter your password and login" });
      return;
    }

    this.props
      .login(email, password)
      .then(() => {
        this.props.navigate("/dashboard");
      })
      .catch((err) => {
        this.setState({ errorPassword: "Email or password is incorrect" });
      });
  };

  render() {
    const { loggingIn } = this.props;
    const { email, errorEmail, password, errorPassword } = this.state;

    return (
      <AuthContainer
        title={"Authorization"}
        description={"Please login to the system"}
        content={
          <FormLayout>
            <TextField
              name='email'
              type='text'
              placeholder='Login'
              value={email}
              error={errorEmail}
              onBlur={() => this.validateLogin(email)}
              onChange={(value) => {
                this.setState({ email: value });
              }}
            />
            <TextField
              name='password'
              type='password'
              placeholder='Password'
              value={password}
              error={errorPassword}
              onChange={(value) => {
                this.setState({ password: value, errorPassword: false });
              }}
            />
            <br />
            <Button
              disabled={loggingIn}
              loading={loggingIn}
              primary
              onClick={this.doLogin}
            >
              Sign In
            </Button>
          </FormLayout>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.auth.loggingInLoading,
  loginError: state.auth.loginError,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    login: (email, password) => dispatch(login({ email, password })),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(LogInAdmin))
);
