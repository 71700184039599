import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Card, DataTable, Text } from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { withTranslation } from "react-i18next";

class IOSSPreview extends Component {
  renderSummary = () => {
    const { sum, vat, currency } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          gap: "2rem",
        }}
      >
        <Text variant='bodyMd'>
          Import of Low value goods: {formatMoney(sum)} {currency}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vat)} {currency}
        </Text>
      </div>
    );
  };

  getRows = () => {
    const { data } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item, index) =>
            item.vat_rate.map((country, ind) => {
              return [
                ind === 0 ? item.country : "",
                formatMoney(country.total_sum),
                `${country.vat_percent}%`,
                formatMoney(country.total_vat),
              ];
            })
          )
          .flat()
      : [["-", "0", "-", "0"]];

    return rows;
  };

  columns = [
    this.props.t("createReturns.country"),
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  render() {
    const { data } = this.props;

    return (
      <div>
        {this.renderSummary()}
        <br />
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold'>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(IOSSPreview);
