import React from 'react'

const SelectComponent = ({data, line, label, handler, arr}) => {
	return (
		<label style={{marginTop: 15, width: 300, display: 'flex', flexDirection: 'column'}}>
      <span style={{paddingRight: 20, marginBottom: 4}}>
	      {label}
	      <span style={{color: 'red', marginLeft: 4}}>*</span >
			</span >
      <select
	      id={line}
	      value={String(data?.[line] || '')}
	      onChange={(event) => handler(line, event.target.value)}
	      style={{
		      borderColor: 'rgba(186, 191, 196, 1)',
		      borderRadius: 4,
		      height: 36,
		      padding: 5
	      }}
      >
			  {arr.map((item) => (
				  <option
					  key={item.value}
					  value={item.value}
				  >
			      {item.label}
			    </option >
			  ))}
			</select >
    </label >
	)
}

export default SelectComponent