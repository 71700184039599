import {
	ActionList,
	Box,
	Button,
	ButtonGroup,
	Card,
	DataTable,
	Layout,
	Popover,
	Spinner,
	Text
} from '@shopify/polaris'
import NoDataMessage from 'components/NoDataMessage'
import Pagination from 'components/Pagination/Pagination'
import useAppDispatch from 'hooks/useAppDispatch'
import AddIcon from 'img/add-white.svg'
import importIMG from 'img/import.svg'
import settingsIMG from 'img/settings.svg'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchDocumentsExemptionCertificates } from '../../../redux/features/documents/documentsSlice'
import formatRow from '../../../utils/formatRow'
import usePagination from '../tabs/Invoices/hooks/usePagination'
import GetColumnsEx from './GetColumnsEx'

const defaultLimit = 25;

const ExemptionTable = () => {
	const {documentsExemptionCertificates, fetchingDocuments, countDocumentsExemption} = useSelector(state => state.documents)
	const [changeInOrOut, setChangeInOrOut] = useState('in')
	const [saveDocumentLength, setSaveDocumentLength] = useState(0)
	
	const navigate = useNavigate();
	const dispatch = useAppDispatch()
	
	const [query, setQuery] = useState({
		invoice_type: 'all',
		type: 'exemption-certificate',
		offset: 0,
		limit: 25
	});
	
	const { pagination, onPageChange } = usePagination(countDocumentsExemption, defaultLimit);
	

	
	useEffect(() => {
		const req = async () => {
			try {
				await dispatch(fetchDocumentsExemptionCertificates(query))
			} catch (e) {
				console.error(e)
			}
		}
		req()
	}, []);
	
	const filteredForTypeDocuments = () => {
		let res = []
		if (changeInOrOut === 'in') res = documentsExemptionCertificates.filter((item) => item.type === 'incoming')
		else res = documentsExemptionCertificates.filter((item) => item.type === 'outgoing')
		return res
	}
	
	const resFilForDocument= filteredForTypeDocuments()
	
	useEffect(() => {
		setSaveDocumentLength(resFilForDocument.length)
	}, [documentsExemptionCertificates, changeInOrOut]);
	
	const columns = GetColumnsEx(changeInOrOut, saveDocumentLength, resFilForDocument)
	
	const rows = formatRow(resFilForDocument, columns) || []
	
	return (
		<>
			<Layout.Section >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <ButtonGroup segmented>
          <Button
	          primary={changeInOrOut === 'in'}
	          onClick={() => setChangeInOrOut('in')}
          >
            Incoming
          </Button >
          <Button
	          primary={changeInOrOut === 'out'}
	          onClick={() => setChangeInOrOut('out')}
          >
            Outgoing
          </Button >
        </ButtonGroup >
        <div style={{
	        display: 'flex',
	        flexDirection: 'row',
	        justifyContent: 'space-between',
        }}>
          <Button
	          primary
	          onClick={() => navigate(`${changeInOrOut === 'in' ? '/documents/exemption/add' : '/documents/exemption/create'}`)}
	          icon={
		          <img
			          src={AddIcon}
			          alt='add'
			          width='15'
			          style={{marginTop: 4, marginRight: 5}}
		          />
	          }
          >
            {`${changeInOrOut === 'in' ? 'Add Exemption' : 'Create certificate'}`}
          </Button >
	        <Popover
		        active={false}
		        preferredPosition='below'
		        fullWidth
		        activator={
			        <button
				        fullWidth
				        className={'button-upload-options-style'}
			        >
                <img
	                src={importIMG}
	                alt='upload'
	                width='15'
	                style={{marginTop: 4, marginRight: 5}}
                />
                Upload options
              </button >
		        }
		        // onClose={() => setActive(null)}
	        >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Upload from Xero",
			            // onAction: () =>
			            // handleActionDialogsOpen(actionDialogsKeys.uploadFromXero),
		            },
		            {
			            content: "Scan files",
			            // onAction: () =>
			            // handleActionDialogsOpen(actionDialogsKeys.scanFiles),
		            },
		            {
			            content: "Convert to transactions",
			            // onAction: () =>
			            //   handleActionDialogsOpen(actionDialogsKeys.convertToTransactions),
		            },
	            ]}
            />
          </Popover >
          
          <Popover
	          // active={active === "bulk-actions-popover"}
	          activator={
		          <Button
			          // onClick={() =>
			          //   setActive(
			          //     active === "bulk-actions-popover"
			          //       ? null
			          //       : "bulk-actions-popover"
			          //   )
			          // }
			          icon={
				          <img
					          src={settingsIMG}
					          alt='edit'
					          width='15'
					          style={{marginTop: 2, marginRight: 3}}
				          />
			          }
		          >
                Group Actions
              </Button >
	          }
	          // onClose={() => setActive(null)}
          >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Delete",
			            // onAction: () => handleBulkAction("delete"),
		            },
		            {
			            content: "Mark as Paid",
			            // onAction: () => handleBulkAction("markPaid"),
		            },
		            {
			            content: "Mark as Sent",
			            // onAction: () => handleBulkAction("markSent"),
		            },
		            {
			            content: "Approve",
			            // onAction: () => handleBulkAction("approve"),
		            },
	            ]}
            />
          </Popover >
        </div >
      </div >
      <br />
      <Card >
        {fetchingDocuments ? (
	        <Card.Section >
            <Spinner />
          </Card.Section >
        ) : (
	        <>
            <DataTable
	            columnContentTypes={Array(columns.length).fill("text")}
	            headings={columns.map(({header}) => (
		            <Text fontWeight='semibold'>{header.label}</Text >
	            ))}
	            rows={rows}
            />
		        {rows.length === 0 && (
			        <NoDataMessage
				        description={"To see documents in the list, first add them."}
				        buttonText={"Add Documents from templates"}
			        />
		        )}
          </>
        )}
      </Card >
				
				{rows.length > 0 && (
					<Box padding='4'>
          <Pagination
	          total={countDocumentsExemption}
	          current={pagination.page}
	          pageSize={defaultLimit}
	          // onChange={(current) => onPageChange(current)}
          />
        </Box>
				)}
				
				{/*<DeleteInvoiceModal*/}
				{/*	isOpen={actionDialogs[actionDialogsKeys.deleteVatInvoice].open}*/}
				{/*	data={actionDialogs[actionDialogsKeys.deleteVatInvoice].cellData}*/}
				{/*	onClose={() =>*/}
				{/*		handleActionDialogsClose(actionDialogsKeys.deleteVatInvoice)*/}
				{/*	}*/}
				{/*	afterDelete={fetchDocuments}*/}
				{/*/>*/}
	      
	      {/*<UploadFromXero*/}
		    {/*  isOpen={actionDialogs[actionDialogsKeys.uploadFromXero].open}*/}
		    {/*  data={actionDialogs[actionDialogsKeys.uploadFromXero].cellData}*/}
		    {/*  onClose={() =>*/}
			  {/*    handleActionDialogsClose(actionDialogsKeys.uploadFromXero)*/}
		    {/*  }*/}
		    {/*  afterUpload={fetchDocuments}*/}
	      {/*/>*/}
	      {/*<ConvertToTransactions*/}
		    {/*  isOpen={actionDialogs[actionDialogsKeys.convertToTransactions].open}*/}
		    {/*  data={actionDialogs[actionDialogsKeys.convertToTransactions].cellData}*/}
		    {/*  onClose={() =>*/}
			  {/*    handleActionDialogsClose(actionDialogsKeys.convertToTransactions)*/}
		    {/*  }*/}
		    {/*  afterUpload={fetchDocuments}*/}
	      {/*/>*/}
	      
	      {/*<ScanFiles*/}
		    {/*  isOpen={actionDialogs[actionDialogsKeys.scanFiles].open}*/}
		    {/*  data={actionDialogs[actionDialogsKeys.scanFiles].cellData}*/}
		    {/*  onClose={() => handleActionDialogsClose(actionDialogsKeys.scanFiles)}*/}
		    {/*  afterScan={fetchDocuments}*/}
	      {/*/>*/}
	    </Layout.Section >
  </ >
	)
}

export default ExemptionTable