import moment from "moment";
import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Layout,
  Card,
  Button,
  ButtonGroup,
  FormLayout,
  Banner,
  Select,
  TextField,
  Tag,
  Tooltip,
  Tabs,
  Checkbox,
  Text,
  DataTable,
  Box,
} from "@shopify/polaris";
import {
  editActivateVatReg,
  editExemption,
  editSalesTaxPermit,
  editWaiver,
} from "../../redux/features/manager/managerSlice";
import { fetchMerch } from "../../redux/features/merch-settings/merchSettingsSlice";
import {
  fetchMerchSettingsMassages,
  fetchNewMessagesCount,
} from "../../redux/features/messenger/messengerSlice";
import {
  fetchRegCountries,
  fetchRegNumbers,
  getAllNotCompletedOrders,
} from "../../redux/features/registration/registrationSlice";
import {
  switchCalculateRealTime,
  switchCalculateThresholdsNexus,
} from "../../redux/features/subscription/subscriptionSlice";
import {
  createExeption,
  createSalesTaxPermit,
  createWaiver,
  deleteExeption,
  deleteSalesTaxPermit,
  deleteWaiver,
  downloadExemptionFile,
  downloadWaiverFile,
  fetchCertTypes,
  fetchExemptions,
  fetchExTypes,
  fetchSalesTaxPermit,
  fetchStates,
  fetchWaivers,
} from "../../redux/features/tax-settings/taxSettingsSlice";
import { fetchUndisclosedScheme } from "../../redux/features/uk-settings/ukSettingsSlice";
import { fetchUserGuide } from "../../redux/features/user/userSlice";
import { parseServerError } from "utils/errors";
import { formatDate, formatDateForServer } from "utils/dates";
import _ from "lodash";
import AutopilotDetailsBuilder from './Autopilot/AutopilotDetailsBuilder'
import VatRegistration from "./VatRegistration";
import AutoSubSettings from "./AutoSubSettings";
import Datepicker from "components/Datepicker/Datepicker";
import Modal from "components/Modal/Modal";
import EPRSettings from "./EPRSettings";
import formatRow from "utils/formatRow";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import PageHelmet from "components/PageHelmet";
import GuideCompletionPopup from "components/GuideCompletionPopup/GuideCompletionPopup";
import MerchSettings from "./MerchSettings";
import UKSettings from "./UKSettings";

// Icons
import deleteIMG from "img/delete.svg";
import editIMG from "img/settings.svg";
import checkmarkIMG from "img/checkmark.svg";
import crossIMG from "img/cross.svg";
import plusGreyIMG from "img/plusGrey.svg";
import clipIMG from "img/clip.svg";
import { createCustomDispatch } from "helpers/customDispatch";

import "./taxSettings.scss";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class TaxSettings extends Component {
  constructor(props) {
    super(props);

    const defaultDates = {
      from: moment().startOf("year").toDate(),
      till: null,
    };

    this.state = {
      isOpenAddPermit: false,
      isOpenAddExemp: false,
      isOpenAddWaiver: false,

      permitState: "",
      permitMonth: new Date().getMonth(),
      permitYear: new Date().getFullYear(),
      permitSelected: {
        start: defaultDates.from,
        end: defaultDates.till,
      },
      permitCert: "",
      permitNexusDate: "",
      permitBusinessName: "",
      permitPeriodicity: "",
      send_reminder: false,

      exempState: "",
      exempMonth: new Date().getMonth(),
      exempYear: new Date().getFullYear(),
      exempSelected: {
        start: defaultDates.from,
        end: defaultDates.till,
      },
      exempCert: "",

      waiverState: "",
      waiverMonth: new Date().getMonth(),
      waiverYear: new Date().getFullYear(),
      waiverSelected: {
        start: defaultDates.from,
        end: defaultDates.till,
      },
      waiverCert: "",

      fileNameList: {
        exemp_doc: [],
        waiver_doc: [],
      },

      actionDialogs: {
        deletePermit: { open: false },
        deleteExemp: { open: false },
        deleteWaiver: { open: false },
        editActivate: { open: false },
        edit: { open: false },
      },

      selected: 0,
    };
  }

  componentDidMount() {
    const { user, location } = this.props;

    this.setState({
      selected: location.state?.defaultTab ?? 0,
    });

    if (user.is_omp) {
      this.props.fetchUndisclosedScheme();
    }
    this.props.fetchUserGuide();
    this.props.fetchSalesTaxPermit();
    this.props.fetchMerch();
    this.props.fetchCertTypes();
    this.props.fetchExemptions();
    this.props.fetchExTypes();
    this.props.fetchStates();
    this.props.fetchWaivers();
    this.props.fetchRegNumbers();
    this.props.getAllNotCompletedOrders();
    this.props.fetchRegCountries();
    this.props.fetchNewMessagesCount();
    this.props.fetchMerchSettingsMassages();
  }

  periodicityOptions = [
    {
      label: "Annual",
      value: "year",
    },
    {
      label: "Quartertly",
      value: "quarter",
    },
    {
      label: "Monthly",
      value: "month",
    },
    {
      label: "Bi-Annual",
      value: "bi-annual",
    },
  ];

  getStatesOption = () => {
    const { usStates } = this.props;
    return [
      {
        value: "",
        label: "",
      },
      ...usStates.map((state) => ({
        value: state.code,
        label: state.name,
      })),
    ];
  };

  editActivate = (type, id, activate) => {
    const formData = {
      type,
      id,
      activate,
    };

    this.props
      .editActivateVatReg(formData)
      .then(() => {
        if (type === "sales_tax_permit") {
          this.props.fetchSalesTaxPermit().then(() => {
            this.handleActionDialogsClose("editActivate");
          });
        }
        if (type === "waiver") {
          this.props.fetchWaivers().then(() => {
            this.handleActionDialogsClose("editActivate");
          });
        }
        if (type === "exempt") {
          this.props.fetchExemptions().then(() => {
            this.handleActionDialogsClose("editActivate");
          });
        }
      })
      .catch((err) => {
        this.setState({ activateError: parseServerError(err) });
      });
  };

  editData = (type) => {
    const data = this.state.actionDialogs.cellData;
    const formData = {
      id: data.id,
      state_code: data.state_code,
      from_date: formatDateForServer(data.from_date),
      till_date: data.till_date ? formatDateForServer(data.till_date) : null,
    };

    if (type === "sales_tax_permit") {
      formData.certificate_type_id = +data.certificate_type_id;
      formData.sales_tax_id = data.sales_tax_id;
      this.props
        .editSalesTaxPermit(formData)
        .then(() => {
          this.props.fetchSalesTaxPermit();
          this.handleActionDialogsClose("edit");
        })
        .catch((err) => {
          this.setState({ editError: parseServerError(err) });
        });
    }
    if (type === "waiver") {
      formData.certificate_number = data.certificate_number;
      this.props
        .editWaiver(formData)
        .then(() => {
          this.props.fetchWaivers();
          this.handleActionDialogsClose("edit");
        })
        .catch((err) => {
          this.setState({ editError: parseServerError(err) });
        });
    }
    if (type === "exempt") {
      formData.tax_exemption_certificate = data.tax_exemption_certificate;
      this.props
        .editExemption(formData)
        .then(() => {
          this.props.fetchExemptions();
          this.handleActionDialogsClose("edit");
        })
        .catch((err) => {
          this.setState({ editError: parseServerError(err) });
        });
    }
  };

  attachFiles = (docsFiles, fileListName) => {
    const formDataFiles = new FormData();
    formDataFiles.append(
      "file_",
      docsFiles[fileListName][0],
      docsFiles[fileListName][0].name
    );
    return formDataFiles;
  };

  docsFiles = {
    exemp_doc: [],
    waiver_doc: [],
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    if (name === "edit") {
      this.updateDialogData(data);
    }
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const file = this.fileUploadInput.files[0];
        this.docsFiles[docType][0] = file; // add file
        fileNameList[docType][0] = file.name; // add filename
        this.setState({ fileNameList });
      };
    }
  };

  handleDateChange = (startDate, endDate) => {
    const selectedDlgFrom = {
      start: startDate,
      end: startDate,
    };
    const selectedDlgTill = {
      start: endDate,
      end: endDate,
    };

    const newActionDialogs = {
      ...this.state.actionDialogs,
    };
    newActionDialogs.cellData.from_date = formatDateForServer(startDate);
    newActionDialogs.cellData.till_date = formatDateForServer(endDate);

    this.setState({
      selectedDlgFrom,
      selectedDlgTill,
      yearDlgTill: endDate.getFullYear(),
      monthDlgTill: endDate.getMonth(),
      actionDialogs: newActionDialogs,
    });
  };

  handleFromDateChange = (startDate) => {
    this.setState((state) => ({
      selectedDlgFrom: {
        ...state.selectedDlgFrom,
        start: startDate,
      },
      selectedDlgTill: {
        ...state.selectedDlgTill,
        start: startDate,
      },
      actionDialogs: {
        ...state.actionDialogs,
        cellData: {
          ...state.actionDialogs.cellData,
          from_date: formatDateForServer(startDate),
        },
      },
    }));
  };

  handleTillDateChange = (endDate) => {
    this.setState((state) => ({
      selectedDlgFrom: {
        ...state.selectedDlgFrom,
        end: endDate,
      },
      selectedDlgTill: {
        ...state.selectedDlgTill,
        end: endDate,
      },
      actionDialogs: {
        ...state.actionDialogs,
        cellData: {
          ...state.actionDialogs.cellData,
          till_date: endDate ? formatDateForServer(endDate) : null,
        },
      },
    }));
  };

  doDeleteFile = (docType) => {
    const fileNameList = { ...this.state.fileNameList };
    fileNameList[docType] = [];
    this.setState({ fileNameList });
  };

  doDeleteExemp = (id) => {
    this.props
      .deleteExeption(id)
      .then(() => {
        this.props.fetchExemptions();
        this.handleActionDialogsClose("deleteExemp");
      })
      .catch((err) => {
        this.setState({ errorDelExemp: parseServerError(err) });
      });
  };

  doDeletePermit = (id) => {
    this.props
      .deleteSalesTaxPermit(id)
      .then(() => {
        this.props.fetchSalesTaxPermit();
        this.handleActionDialogsClose("deletePermit");
      })
      .catch((err) => {
        this.setState({ errorDelPermit: parseServerError(err) });
      });
  };

  doDeleteWaiver = (id) => {
    this.props
      .deleteWaiver(id)
      .then(() => {
        this.props.fetchWaivers();
        this.handleActionDialogsClose("deleteWaiver");
      })
      .catch((err) => {
        this.setState({ errorDelWaiver: parseServerError(err) });
      });
  };

  doDownloadFile = (toDownload, fileName) => {
    const a = document.createElement("a");
    a.setAttribute("href", toDownload.link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(toDownload.link);
    document.body.removeChild(a);
  };

  downloadExempFile(data) {
    const fileName = `Exemption - ${data.tax_exemption_certificate}`;
    this.props.downloadExemptionFile(data.id).then((toDownload) => {
      this.doDownloadFile(toDownload, fileName);
    });
  }

  downloadWaiverFile(data) {
    const fileName = `Waiver - ${data.certificate_number}`;
    this.props.downloadWaiverFile(data.id).then((toDownload) => {
      this.doDownloadFile(toDownload, fileName);
    });
  }

  doAddSalesTaxPermit = () => {
    const { userGuide } = this.props;
    const {
      permitSelected,
      permitCert,
      permitState,
      permitTaxId,
      permitBusinessName,
      permitNexusDate,
      permitPeriodicity,
      send_reminder,
    } = this.state;

    if (!permitCert || !permitState || !permitTaxId || !permitPeriodicity) {
      this.setState({
        errorPermitState: permitState ? false : "Please select state",
        errorPermitTaxId: permitTaxId ? false : "Please enter sales tax id",
        errorPermitCert: permitCert ? false : "Please select certificate type",
        errorPermitPeriodicity: permitPeriodicity
          ? false
          : "Please select permit periodicity",
      });
      return;
    }

    const formData = {
      certificate_type_id: +permitCert,
      from_date: formatDateForServer(permitSelected.start),
      sales_tax_id: permitTaxId,
      state_code: permitState,
      business_name: permitBusinessName,
      periodicity: permitPeriodicity,
      send_reminder,
    };

    if (permitNexusDate) {
      formData.nexus_date = formatDateForServer(permitNexusDate);
    }

    if (permitSelected.end) {
      formData.till_date = formatDateForServer(permitSelected.end);
    }

    this.props
      .createSalesTaxPermit(formData)
      .then(() => {
        this.props.fetchSalesTaxPermit().then(() => {
          if (userGuide && userGuide.show_user_guide) {
            this.props.fetchUserGuide();
          }
          this.resetSalesTaxPermitData();
        });
      })
      .catch((err) => {
        this.setState({ errorAddPermit: parseServerError(err) });
      });
  };

  doAddExemption = () => {
    const { exempSelected, exempCert, exempState } = this.state;
    if (!exempCert || !exempState) {
      this.setState({
        errorExempState: exempState ? false : "Please select state",
        errorExempCert: exempCert
          ? false
          : "Please select tax-exemption certificate",
      });
      return;
    }

    const files = !_.isEmpty(this.docsFiles.exemp_doc)
      ? this.attachFiles(this.docsFiles, "exemp_doc")
      : false;
    this.props
      .createExeption(exempCert, exempState, exempSelected, files)
      .then(() => {
        this.props.fetchExemptions().then(() => {
          this.resetExempData();
        });
      })
      .catch((err) => {
        this.setState({ errorAddExemp: parseServerError(err) });
      });
  };

  doAddWaiver = () => {
    const { waiverSelected, waiverCert, waiverState } = this.state;
    if (!waiverCert || !waiverState) {
      this.setState({
        errorWaiverState: waiverState ? false : "Please select state",
        errorWaiverCert: waiverCert
          ? false
          : "Please select certificate number",
      });
      return;
    }

    const files = !_.isEmpty(this.docsFiles.waiver_doc)
      ? this.attachFiles(this.docsFiles, "waiver_doc")
      : false;
    this.props
      .createWaiver(waiverCert, waiverState, waiverSelected, files)
      .then(() => {
        this.props.fetchWaivers().then(() => {
          this.resetWaiverData();
        });
      })
      .catch((err) => {
        this.setState({ errorAddWaiver: parseServerError(err) });
      });
  };

  handleChange = (value, id, errorName, errorText) => {
    if (value.length > 0) {
      this.setState({
        [id]: value,
        [errorName]: false,
      });
    } else {
      this.setState({
        [id]: "",
        [errorName]: errorText,
      });
    }
  };

  handlePermitMonthChange = (month, year) => {
    this.setState({
      permitMonth: month,
      permitYear: year,
    });
  };

  handlePermitChangeStartData = (date) => {
    const selected = { ...this.state.permitSelected };

    selected.start = date;

    this.setState({
      permitSelected: selected,
      permitVisibilityDatePickerFrom: false,
    });
  };

  handlePermitChangeEndData = (date) => {
    const selected = { ...this.state.permitSelected };

    selected.end = date;

    this.setState({
      permitSelected: selected,
      permitVisibilityDatePickerTill: false,
    });
  };

  handleExempMonthChange = (month, year) => {
    this.setState({
      exempMonth: month,
      exempYear: year,
    });
  };

  handleExempChangeStartData = (date) => {
    const selected = { ...this.state.exempSelected };
    if (selected.end) {
      selected.start = date.start;
    }
    if (!selected.end) {
      selected.start = date.end;
    }

    this.setState({
      exempSelected: selected,
      exempVisibilityDatePickerFrom: false,
    });
  };

  handleExempChangeEndData = (date) => {
    const selected = { ...this.state.exempSelected };
    selected.end = date.end;
    this.setState({
      exempSelected: selected,
      exempVisibilityDatePickerTill: false,
    });
  };

  handleWaiverMonthChange = (month, year) => {
    this.setState({
      waiverMonth: month,
      waiverYear: year,
    });
  };

  handleWaiverChangeStartData = (date) => {
    const selected = { ...this.state.waiverSelected };
    selected.start = date;

    this.setState({
      waiverSelected: selected,
      waiverVisibilityDatePickerFrom: false,
    });
  };

  handleWaiverChangeEndData = (date) => {
    const selected = { ...this.state.waiverSelected };
    selected.end = date;

    this.setState({
      waiverSelected: selected,
      waiverVisibilityDatePickerTill: false,
    });
  };

  updateDialogData = (data) => {
    this.setState({
      monthDlgTill: data.till_date
        ? +moment(data.till_date).month()
        : +moment().month(),
      yearDlgTill: data.till_date
        ? +moment(data.till_date).year()
        : +moment().year(),
      selectedDlgTill: data.till_date
        ? {
            start: new Date(
              moment(data.till_date).year(),
              moment(data.till_date).month(),
              moment(data.till_date).date()
            ),
            end: new Date(
              moment(data.till_date).year(),
              moment(data.till_date).month(),
              moment(data.till_date).date()
            ),
          }
        : undefined,
      monthDlgFrom: data.from_date
        ? +moment(data.from_date).month()
        : +moment().month(),
      yearDlgFrom: data.from_date
        ? +moment(data.from_date).year()
        : +moment().year(),
      selectedDlgFrom: data.from_date
        ? {
            start: new Date(
              moment(data.from_date).year(),
              moment(data.from_date).month(),
              moment(data.from_date).date()
            ),
            end: new Date(
              moment(data.from_date).year(),
              moment(data.from_date).month(),
              moment(data.from_date).date()
            ),
          }
        : undefined,
    });
  };

  resetSalesTaxPermitData = () =>
    this.setState({
      permitState: "",
      errorPermitState: false,
      permitTaxId: "",
      errorPermitTaxId: false,
      permitMonth: new Date().getMonth(),
      permitYear: new Date().getFullYear(),
      permitSelected: {
        start: moment().startOf("year").toDate(),
        end: undefined,
      },
      permitCert: "",
      permitBusinessName: "",
      permitNexusDate: "",
      permitPeriodicity: "",
      errorPermitPeriodicity: false,
      errorPermitCert: false,
      send_reminder: false,
    });

  resetExempData = () => {
    this.doDeleteFile("exemp_doc");
    this.setState({
      exempState: "",
      errorExempState: false,
      exempMonth: new Date().getMonth(),
      exempYear: new Date().getFullYear(),
      exempSelected: {
        start: moment().startOf("year").toDate(),
        end: undefined,
      },
      exempCert: "",
      errorExempCert: false,
    });
  };

  resetWaiverData = () => {
    this.doDeleteFile("waiver_doc");
    this.setState({
      waiverState: "",
      errorWaiverState: false,
      waiverMonth: new Date().getMonth(),
      waiverYear: new Date().getFullYear(),
      waiverSelected: {
        start: moment().startOf("year").toDate(),
        end: undefined,
      },
      waiverCert: "",
      errorWaiverCert: false,
    });
  };

  updateCellData = (value, name) => {
    const newActionDialogs = { ...this.state.actionDialogs };
    newActionDialogs.cellData[name] = value;
    this.setState({ actionDialogs: newActionDialogs });
  };

  renderEditActivateDialog = () => {
    const { actionDialogs, activateError } = this.state;
    const data = actionDialogs.cellData;

    return (
      data && (
        <Modal
          title={"Edit active status"}
          onClose={() => {
            this.handleActionDialogsClose("editActivate");
            this.setState({ activateError: false });
          }}
          description={
            <p>
              Do you want to {data.activate ? "deactivate" : "activate"}
              {data.type === "sales_tax_permit" &&
                ` Sales Tax Permit ${data.sales_tax_id} `}
              {data.type === "waiver" && ` Waiver ${data.certificate_number} `}
              {data.type === "exempt" &&
                ` Exemption ${data.tax_exemption_certificate} `}
              in {data.state} ?
            </p>
          }
          iconType='warning'
          visible={actionDialogs.editActivate.open}
          content={
            <>
              {activateError && (
                <div>
                  <Banner title={"Error"} status='critical' />
                  <br />
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                status
              </div>
            </>
          }
          footer={
            <Button
              primary
              onClick={() =>
                this.editActivate(data.type, data.id, !data.activate)
              }
            >
              {data.activate ? "Deactivate" : "Activate"}
            </Button>
          }
        />
      )
    );
  };

  renderEditDialog = () => {
    const { certTypes, usStates, t } = this.props;
    const { actionDialogs, editError } = this.state;
    const data = actionDialogs.cellData;

    return (
      data && (
        <Modal
          title={t("vatReg.edit")}
          onClose={() => {
            this.handleActionDialogsClose("edit");
            this.setState({
              editError: false,
              dlgVisibilityDatePickerFrom: false,
              dlgVisibilityDatePickerTill: false,
            });
          }}
          visible={actionDialogs.edit.open}
          content={
            <>
              {editError && (
                <div>
                  <Banner title={"Error"} status='critical' />
                </div>
              )}
              <br />
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    id='dlgState'
                    name='state'
                    label={t("taxSettings.state")}
                    options={usStates.map((state) => ({
                      value: state.code,
                      label: state.name,
                    }))}
                    onChange={(value) =>
                      this.updateCellData(value, "state_code")
                    }
                    value={data.state_code}
                  />
                  {data.type === "sales_tax_permit" && (
                    <TextField
                      id='dlgTaxId'
                      type='text'
                      label={`Sales tax id`}
                      onChange={(value) =>
                        this.updateCellData(value, "sales_tax_id")
                      }
                      value={data.sales_tax_id}
                    />
                  )}
                  {data.type === "exempt" && (
                    <TextField
                      id='dlgCert'
                      type='text'
                      label={`Tax-exemption certificate`}
                      onChange={(value) =>
                        this.updateCellData(value, "tax_exemption_certificate")
                      }
                      value={data.tax_exemption_certificate}
                    />
                  )}
                  {data.type === "waiver" && (
                    <TextField
                      id='dlgWaiverCert'
                      type='text'
                      label={t("taxSettings.certNumber")}
                      onChange={(value) =>
                        this.updateCellData(value, "certificate_number")
                      }
                      value={data.certificate_number}
                    />
                  )}
                </FormLayout.Group>
                <FormLayout.Group>
                  {data.type === "sales_tax_permit" && (
                    <Select
                      id='dlgCert'
                      name='cert'
                      label={t("taxSettings.certType")}
                      options={[
                        {
                          value: "",
                          label: "",
                        },
                        ...certTypes.map((cert) => ({
                          value: cert.id.toString(),
                          label: cert.name,
                        })),
                      ]}
                      onChange={(value) =>
                        this.updateCellData(value, "certificate_type_id")
                      }
                      value={data.certificate_type_id.toString()}
                    />
                  )}
                </FormLayout.Group>
                <FormLayout.Group>
                  <Datepicker
                    value={data.from_date}
                    onChange={this.handleFromDateChange}
                    allowRange={false}
                    label={t("taxSettings.validFrom")}
                  />
                  <Datepicker
                    value={data.till_date}
                    onChange={this.handleTillDateChange}
                    allowRange={false}
                    label={t("taxSettings.validTill")}
                    isClearable={data.till_date}
                  />
                </FormLayout.Group>
              </FormLayout>
            </>
          }
          footer={
            <Button primary onClick={() => this.editData(data.type)}>
              Edit
            </Button>
          }
        />
      )
    );
  };

  renderDeletePermitDialog() {
    const { actionDialogs, errorDelPermit } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    return !_.isEmpty(data) ? (
      <Modal
        title={t("taxSettings.deleteSalesTaxPermit")}
        contentOnCenter
        iconType='danger'
        description={
          <>
            Do you want delete {data.certificate_type}{" "}
            <Text as="h6" variant="headingMd"  fontWeight="bold">{data.sales_tax_id} </Text>
            from date {data.from_date}?
          </>
        }
        onClose={() => {
          this.handleActionDialogsClose("deletePermit");
          this.setState({ errorDelPermit: false });
        }}
        visible={actionDialogs.deletePermit.open}
        content={
          <>
            {errorDelPermit && (
              <div>
                <Banner
                  status='critical'
                  onDismiss={() => this.setState({ errorDelPermit: false })}
                >
                  {errorDelPermit}
                </Banner>
                <br />
              </div>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={() => {
                this.handleActionDialogsClose("deletePermit");
                this.setState({ errorDelPermit: false });
              }}
              size='large'
            >
              {t("taxSettings.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.doDeletePermit(data.id)}
              size='large'
              destructive
            >
              {"Delete"}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderDeleteExempDialog() {
    const { actionDialogs, errorDelExemp } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    return !_.isEmpty(data) ? (
      <Modal
        title={t("taxSettings.deleteExemption")}
        description={
          <>
            Do you want delete tax exemption certificate{" "}
            <Text as="h6" variant="headingMd"  fontWeight="bold">
              {data.tax_exemption_certificate}{" "}
            </Text>
            from date {data.from_date}?
          </>
        }
        contentOnCenter
        iconType={"danger"}
        onClose={() => {
          this.handleActionDialogsClose("deleteExemp");
          this.setState({ errorDelExemp: false });
        }}
        visible={actionDialogs.deleteExemp.open}
        content={
          <>
            {errorDelExemp && (
              <div>
                <Banner
                  status='critical'
                  onDismiss={() => this.setState({ errorDelExemp: false })}
                >
                  {errorDelExemp}
                </Banner>
              </div>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={() => {
                this.handleActionDialogsClose("deleteExemp");
                this.setState({ errorDelExemp: false });
              }}
              size='large'
            >
              {t("taxSettings.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.doDeleteExemp(data.id)}
              size='large'
              destructive
            >
              {t("vatReg.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderDeleteWaiverDialog() {
    const { actionDialogs, errorDelWaiver } = this.state;
    const { t } = this.props;
    const data = actionDialogs.cellData;

    return !_.isEmpty(data) ? (
      <Modal
        title={t("taxSettings.deleteWaiver")}
        description={
          <>
            Do you want delete waiver{" "}
            <Text as="h6" variant="headingMd"  fontWeight="bold">{data.certificate_number} </Text>
            from date {data.from_date}?
          </>
        }
        iconType='danger'
        contentOnCenter
        content={
          <>
            {errorDelWaiver && (
              <div>
                <Banner
                  status='critical'
                  onDismiss={() => this.setState({ errorDelWaiver: false })}
                >
                  {errorDelWaiver}
                </Banner>
              </div>
            )}
          </>
        }
        onClose={() => this.handleActionDialogsClose("deleteWaiver")}
        visible={actionDialogs.deleteWaiver.open}
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={() => this.handleActionDialogsClose("deleteWaiver")}
              size='large'
            >
              {t("taxSettings.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.doDeleteWaiver(data.id)}
              size='large'
              destructive
            >
              {"Delete"}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderSalesTaxPermit = () => {
    const { salesTaxPermit, user, t } = this.props;
    const {
      isOpenAddPermit,
      isCheckedThresholdsNexus,
      errorCheckingThresholdsNexus,
    } = this.state;

    const columns = [
      {
        property: "state",
        header: {
          label: t("taxSettings.state"),
        },
      },
      {
        property: "sales_tax_id",
        header: {
          label: t("taxSettings.salesTaxID"),
        },
      },
      {
        property: "from_date",
        header: {
          label: t("taxSettings.validFrom"),
        },
        cell: {
          formatters: [(value) => formatDate(value)],
        },
      },
      {
        property: "till_date",
        header: {
          label: t("taxSettings.validTill"),
        },
        cell: {
          formatters: [(value) => (value ? formatDate(value) : "")],
        },
      },
      {
        property: "activate",
        header: {
          label: t("taxSettings.active"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="3"
              >
                {this.props.user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("editActivate", {
                          ...rowData,
                          type: "sales_tax_permit",
                        })
                      }
                    >
                      <img src={editIMG} />
                    </Button>
                  </Tooltip>
                )}
                {value ? (
                  <img src={checkmarkIMG} />
                ) : (
                  <span style={{ padding: "0 5px" }}>
                    <img src={crossIMG} />
                  </span>
                )}
              </Box>
            ),
          ],
        },
      },
      {
        property: "certificate_type",
        header: {
          label: "Certificate type",
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex" }}>
                {user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("edit", {
                          ...rowData,
                          type: "sales_tax_permit",
                        })
                      }
                    >
                      <img src={editIMG} />
                    </Button>
                  </Tooltip>
                )}
                {user.is_manager && <span>&nbsp;&nbsp;&nbsp;</span>}
                <Tooltip content={t("vatReg.delete")}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleActionDialogsOpen("deletePermit", {
                        ...rowData,
                        type: "sales_tax_permit",
                      })
                    }
                  >
                    <img src={deleteIMG} />
                  </a>
                </Tooltip>
              </span>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(salesTaxPermit, columns);

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("taxSettings.salesTaxPer")}
          </Text>
        }
        description={
          <Text variant="bodyMd" color="critical">
            {t("taxSettings.salesTaxPerDesc")}
          </Text>
        }
      >
        <Box display="flex" alignItems="space-between">
          <Checkbox
            label={t("taxSettings.calcThresh")}
            checked={
              isCheckedThresholdsNexus || isCheckedThresholdsNexus === false
                ? isCheckedThresholdsNexus
                : this.props.user.company.calculate_threshold_nexus
            }
            onChange={(value) => {
              this.props
                .switchCalculateThresholdsNexus(value)
                .then(() => this.setState({ isCheckedThresholdsNexus: value }))
                .catch((err) =>
                  this.setState({ errorCheckingThresholdsNexus: err.error })
                );
            }}
          />
          <Button
            onClick={() => this.setState({ isOpenAddPermit: true })}
            icon={<img src={plusGreyIMG} alt='plus' style={{ marginTop: 3 }} />}
          >
            {t("taxSettings.addSalesTaxPer")}
          </Button>
        </Box>
        <br />

        {isOpenAddPermit && this.renderAddSalesTaxPermit()}

        <Card>
          {errorCheckingThresholdsNexus && (
            <div style={{ padding: "0 2rem" }}>
              <Banner status='critical'>{errorCheckingThresholdsNexus}</Banner>
              <br />
            </div>
          )}
          {!_.isEmpty(salesTaxPermit) ? (
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={columns.map(({ header }) => (
                <Text fontWeight='semibold'>{header.label}</Text>
              ))}
              rows={sortedRows}
            />
          ) : (
            <NoDataMessage
              style={{ height: "auto", padding: "1.5rem 0px" }}
              buttonText={t("taxSettings.addSalesTaxPer")}
              description={t("taxSettings.salesTaxPermitNoData")}
              onClick={() => this.setState({ isOpenAddPermit: true })}
            />
          )}
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  renderAddSalesTaxPermit() {
    const { certTypes, creatingTaxPermit, t } = this.props;
    const {
      permitState,
      errorPermitState,
      permitTaxId,
      errorPermitTaxId,
      permitSelected,
      permitCert,
      errorPermitCert,
      errorAddPermit,
      permitNexusDate,
      permitBusinessName,
      permitPeriodicity,
      errorPermitPeriodicity,
    } = this.state;

    return (
      <Card title={t("taxSettings.addSalesTaxPer")} sectioned>
        {errorAddPermit && (
          <div>
            <Banner
              status='critical'
              onDismiss={() => this.setState({ errorAddPermit: false })}
            >
              {errorAddPermit}
            </Banner>
            <br />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <Select
              id='permitState'
              name='state'
              label={t("taxSettings.state")}
              options={this.getStatesOption()}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorPermitState",
                  "Please select state"
                )
              }
              value={permitState.toString()}
              error={errorPermitState}
            />
            <TextField
              id='permitTaxId'
              type='text'
              label={t("taxSettings.salesTaxID")}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorPermitTaxId",
                  "Please enter sales tax id"
                )
              }
              value={permitTaxId}
              error={errorPermitTaxId}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Datepicker
              label={t("taxSettings.validFrom")}
              value={permitSelected.start}
              allowRange={false}
              onChange={this.handlePermitChangeStartData}
            />
            <Datepicker
              label={t("taxSettings.validTill")}
              value={permitSelected.end ? permitSelected.end : null}
              allowRange={false}
              onChange={this.handlePermitChangeEndData}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              id='permitCert'
              name='cert'
              label={t("taxSettings.certType")}
              options={[
                {
                  value: "",
                  label: "",
                },
                ...certTypes.map((cert) => ({
                  value: cert.id.toString(),
                  label: cert.name,
                })),
              ]}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorPermitCert",
                  "Please select certificate type"
                )
              }
              value={permitCert.toString()}
              error={errorPermitCert}
            />
            <Datepicker
              label={t("taxSettings.nexusEstablished")}
              value={permitNexusDate}
              allowRange={false}
              onChange={(date) => {
                this.setState({
                  permitNexusDate: date,
                });
              }}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Select
              id='permitPeriodicity'
              name='stateAssignedPaymentSchedule'
              label={t("taxSettings.stateAssignedPayment")}
              options={[
                {
                  value: "",
                  label: "",
                },
                ...this.periodicityOptions,
              ]}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorPermitPeriodicity",
                  "Please select permit periodicity"
                )
              }
              value={permitPeriodicity}
              error={errorPermitPeriodicity}
            />

            <TextField
              id='permitBusinessName'
              type='text'
              label={t("taxSettings.permitBusinessName")}
              onChange={(value) =>
                this.setState({
                  permitBusinessName: value,
                })
              }
              value={permitBusinessName}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              checked={this.state.send_reminder}
              label={t("vatReg.taxRepThroughLovat")}
              onChange={(isChecked) => {
                this.setState({ send_reminder: isChecked });
              }}
            />
          </FormLayout.Group>
        </FormLayout>
        <br />
        <ButtonGroup>
          <Button
            loading={creatingTaxPermit}
            primary
            onClick={this.doAddSalesTaxPermit}
          >
            {t("taxSettings.addSalesTaxPer")}
          </Button>
          <Button
            onClick={() => {
              this.resetSalesTaxPermitData();
              this.setState({ isOpenAddPermit: false });
            }}
          >
            {t("taxSettings.cancel")}
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  renderExemptins = () => {
    const { exemptions, user, t } = this.props;
    const { isOpenAddExemp, isCheckedRealTime, errorCheckingRealTime } =
      this.state;

    const columns = [
      {
        property: "exemption_document",
        header: {
          label: t("taxSettings.file"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex", alignItems: "center" }}>
                {rowData.exemption_document ? (
                  <Tooltip content={"Download"}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.downloadExempFile(rowData);
                      }}
                    >
                      <img src={clipIMG} />
                    </a>
                  </Tooltip>
                ) : (
                  <span>&mdash;</span>
                )}
              </span>
            ),
          ],
        },
      },
      {
        property: "state",
        header: {
          label: t("taxSettings.state"),
        },
      },
      {
        property: "tax_exemption_certificate",
        header: {
          label: t("taxSettings.taxExSert"),
        },
      },
      {
        property: "from_date",
        header: {
          label: t("taxSettings.validFrom"),
        },
        cell: {
          formatters: [(value) => formatDate(value)],
        },
      },
      {
        property: "till_date",
        header: {
          label: t("taxSettings.validTill"),
        },
        cell: {
          formatters: [(value) => (value ? formatDate(value) : "")],
        },
      },
      {
        property: "activate",
        header: {
          label: t("taxSettings.active"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="3"
              >
                {this.props.user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("editActivate", {
                          ...rowData,
                          type: "exempt",
                        })
                      }
                    >
                      <img src={editIMG} alt='edit' />
                    </Button>
                  </Tooltip>
                )}
                {value ? (
                  <img src={checkmarkIMG} alt='checkmark' />
                ) : (
                  <span style={{ padding: "0 5px" }}>
                    <img src={crossIMG} alt='cross' />
                  </span>
                )}
              </Box>
            ),
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex" }}>
                {user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("edit", {
                          ...rowData,
                          type: "exempt",
                        })
                      }
                    >
                      <img src={editIMG} alt='edit' />
                    </Button>
                  </Tooltip>
                )}
                {user.is_manager && <span>&nbsp;&nbsp;&nbsp;</span>}
                <Tooltip content={t("vatReg.delete")}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleActionDialogsOpen("deleteExemp", rowData)
                    }
                  >
                    <img src={deleteIMG} alt='delete' />
                  </a>
                </Tooltip>
              </span>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(exemptions, columns);

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("taxSettings.exemp")}
          </Text>
        }
        description={
          <Text variant="bodyMd" color="critical">
            {t("taxSettings.myExemp")}
          </Text>
        }
      >
        <Box display="flex" alignItems="space-between">
          <Checkbox
            label={t("taxSettings.acceptRT")}
            checked={
              isCheckedRealTime || isCheckedRealTime === false
                ? isCheckedRealTime
                : this.props.user.company.real_time_exemptions
            }
            onChange={(value) => {
              this.props
                .switchCalculateRealTime(value)
                .then(() => this.setState({ isCheckedRealTime: value }))
                .catch((err) =>
                  this.setState({ errorCheckingRealTime: err.error })
                );
            }}
          />
          <Button
            onClick={() => this.setState({ isOpenAddExemp: true })}
            icon={<img src={plusGreyIMG} alt='plus' style={{ marginTop: 3 }} />}
          >
            {t("taxSettings.addExemp")}
          </Button>
        </Box>
        <br />

        {isOpenAddExemp && this.renderAddExemption()}

        <Card>
          {errorCheckingRealTime && (
            <div style={{ padding: "0 2rem" }}>
              <Banner status='critical'>{errorCheckingRealTime}</Banner>
              <br />
            </div>
          )}
          {!_.isEmpty(exemptions) ? (
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={columns.map(({ header }) => (
                <Text fontWeight='semibold'>{header.label}</Text>
              ))}
              rows={sortedRows}
            />
          ) : (
            <NoDataMessage
              style={{ height: "auto", padding: "1.5rem 0px" }}
              buttonText={t("taxSettings.addExemp")}
              description={t("taxSettings.exemptionNoData")}
              onClick={() => this.setState({ isOpenAddExemp: true })}
            />
          )}
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  renderMultipleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    const { t } = this.props;

    return (
      <div>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={(evt) => {
              evt.target.value = null;
              this.handleFileSelect(docType);
            }}
          >
            {t("taxSettings.addFile")}
            <input
              type='file'
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <Text variant="bodyMd" color="critical">
            {t("taxSettings.chooseFile")}
          </Text>
        </div>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag key={index} onRemove={() => this.doDeleteFile(docType)}>
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderAddExemption() {
    const { creatingEx, t } = this.props;
    const {
      exempState,
      errorExempState,
      exempSelected,
      exempCert,
      errorExempCert,
      errorAddExemp,
    } = this.state;

    return (
      <Card title={t("taxSettings.addExempDoc")} sectioned>
        {errorAddExemp && (
          <div>
            <Banner
              status='critical'
              onDismiss={() => this.setState({ errorAddExemp: false })}
            >
              {errorAddExemp}
            </Banner>
            <br />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <Select
              id='exempState'
              name='state'
              label={t("taxSettings.state")}
              options={this.getStatesOption()}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorExempState",
                  "Please select state"
                )
              }
              value={exempState.toString()}
              error={errorExempState}
            />
            <TextField
              id='exempCert'
              type='text'
              label={t("taxSettings.taxExSert")}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorExempCert",
                  "Please enter tax-exemption certificate"
                )
              }
              value={exempCert}
              error={errorExempCert}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Datepicker
              label={t("taxSettings.validFrom")}
              value={exempSelected.start}
              allowRange={false}
              onChange={(start) => {
                this.setState((state) => ({
                  exempSelected: {
                    ...state.exempSelected,
                    start,
                  },
                }));
              }}
            />
            <Datepicker
              label={t("taxSettings.validTill")}
              value={exempSelected.end}
              allowRange={false}
              onChange={(end) => {
                this.setState((state) => ({
                  exempSelected: {
                    ...state.exempSelected,
                    end,
                  },
                }));
              }}
            />
          </FormLayout.Group>
        </FormLayout>
        {this.renderMultipleFileSelect("exemp_doc")}
        <br />
        <ButtonGroup>
          <Button loading={creatingEx} primary onClick={this.doAddExemption}>
            {t("taxSettings.addExemp")}
          </Button>
          <Button
            onClick={() => {
              this.resetExempData();
              this.setState({ isOpenAddExemp: false });
            }}
          >
            {t("taxSettings.cancel")}
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  renderWaivers = () => {
    const { waivers, user, t } = this.props;
    const { isOpenAddWaiver } = this.state;

    const columns = [
      {
        property: "exemption_document",
        header: {
          label: t("taxSettings.file"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex", alignItems: "center" }}>
                {rowData.exemption_document ? (
                  <Tooltip content={"Download"}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.downloadWaiverFile(rowData);
                      }}
                    >
                      <img src={clipIMG} alt='clip' />
                    </a>
                  </Tooltip>
                ) : (
                  <span>&mdash;</span>
                )}
              </span>
            ),
          ],
        },
      },
      {
        property: "state",
        header: {
          label: t("taxSettings.state"),
        },
      },
      {
        property: "certificate_number",
        header: {
          label: t("taxSettings.certNumber"),
        },
      },
      {
        property: "from_date",
        header: {
          label: t("taxSettings.validFrom"),
        },
        cell: {
          formatters: [(value) => (value ? formatDate(value) : "")],
        },
      },
      {
        property: "till_date",
        header: {
          label: t("taxSettings.validTill"),
        },
        cell: {
          formatters: [(value) => (value ? formatDate(value) : "")],
        },
      },
      {
        property: "activate",
        header: {
          label: t("taxSettings.active"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="3"
              >
                {this.props.user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("editActivate", {
                          ...rowData,
                          type: "waiver",
                        })
                      }
                    >
                      <img src={editIMG} />
                    </Button>
                  </Tooltip>
                )}
                {value ? (
                  <img src={checkmarkIMG} />
                ) : (
                  <span style={{ padding: "0 5px" }}>
                    <img src={crossIMG} />
                  </span>
                )}
              </Box>
            ),
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex" }}>
                {user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      plain
                      onClick={() =>
                        this.handleActionDialogsOpen("edit", {
                          ...rowData,
                          type: "waiver",
                        })
                      }
                    >
                      <img src={editIMG} />
                    </Button>
                  </Tooltip>
                )}
                {user.is_manager && <span>&nbsp;&nbsp;&nbsp;</span>}
                <Tooltip content={t("vatReg.delete")}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleActionDialogsOpen("deleteWaiver", rowData)
                    }
                  >
                    <img src={deleteIMG} />
                  </a>
                </Tooltip>
              </span>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(waivers, columns);

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("taxSettings.waivForMP")}
          </Text>
        }
      >
        <Box
          display="flex"
          alignItems="end"
        >
          <Button
            onClick={() => this.setState({ isOpenAddWaiver: true })}
            icon={<img src={plusGreyIMG} alt='plus' style={{ marginTop: 3 }} />}
          >
            {t("taxSettings.addWaiv")}
          </Button>
        </Box>
        <br />

        {isOpenAddWaiver && this.renderAddWaiver()}

        <Card>
          {!_.isEmpty(waivers) ? (
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
              ]}
              headings={columns.map(({ header }) => (
                <Text fontWeight='semibold'>{header.label}</Text>
              ))}
              rows={sortedRows}
            />
          ) : (
            <NoDataMessage
              style={{ height: "auto", padding: "1.5rem 0px" }}
              buttonText={t("taxSettings.addWaiv")}
              description={t("taxSettings.waiverNoData")}
              onClick={() => this.setState({ isOpenAddWaiver: true })}
            />
          )}
        </Card>
      </Layout.AnnotatedSection>
    );
  };

  renderAddWaiver() {
    const { creatingWaivers, t } = this.props;
    const {
      waiverState,
      errorWaiverState,
      waiverSelected,
      waiverCert,
      errorWaiverCert,
      errorAddWaiver,
    } = this.state;

    return (
      <Card title={t("taxSettings.addWaiv")} sectioned>
        {errorAddWaiver && (
          <div>
            <Banner
              status='critical'
              onDismiss={() => this.setState({ errorAddWaiver: false })}
            >
              {errorAddWaiver}
            </Banner>
            <br />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <Select
              id='waiverState'
              name='state'
              label={t("taxSettings.state")}
              options={this.getStatesOption()}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorWaiverState",
                  "Please select state"
                )
              }
              value={waiverState.toString()}
              error={errorWaiverState}
            />
            <TextField
              id='waiverCert'
              type='text'
              label={t("taxSettings.certNumber")}
              onChange={(value, id) =>
                this.handleChange(
                  value,
                  id,
                  "errorWaiverCert",
                  "Please enter certificate number"
                )
              }
              value={waiverCert}
              error={errorWaiverCert}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Datepicker
              label={t("taxSettings.validFrom")}
              value={waiverSelected.start}
              onChange={this.handleWaiverChangeStartData}
              allowRange={false}
            />
            <Datepicker
              label={t("taxSettings.validTill")}
              value={waiverSelected.end}
              onChange={this.handleWaiverChangeEndData}
              allowRange={false}
            />
          </FormLayout.Group>
        </FormLayout>
        {this.renderMultipleFileSelect("waiver_doc")}
        <br />
        <ButtonGroup>
          <Button loading={creatingWaivers} primary onClick={this.doAddWaiver}>
            {t("taxSettings.addWaiv")}
          </Button>
          <Button
            onClick={() => {
              this.resetWaiverData();
              this.setState({ isOpenAddWaiver: false });
            }}
          >
            {t("taxSettings.cancel")}
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  render() {
    const { userGuide, user, t } = this.props;
    const { selected } = this.state;
    let tabs = [
      {
        id: "vat",
        content: t("taxSettings.vatReg"),
        accessibilityLabel: t("taxSettings.vatReg"),
        panelID: "vat",
      },
      {
        id: "sales",
        content: t("taxSettings.salesTax"),
        accessibilityLabel: t("taxSettings.salesTax"),
        panelID: "sales",
      },
      {
        id: "epr",
        content: t("taxSettings.eprSettings"),
        accessibilityLabel: t("taxSettings.eprSettings"),
        panelID: "epr",
      },
    ];

    if (user.is_omp) {
      tabs = [
        ...tabs,
        {
          id: "merch",
          content: (
            <span>
              Merchants settings
              {this.props.newMessagesCount.merchant_settings !== 0 && (
                <span className='drawer__mess' style={{ marginLeft: "10px" }}>
                  {this.props.newMessagesCount.merchant_settings}
                </span>
              )}
            </span>
          ),
          accessibilityLabel: "Merchants settings",
          panelID: "merch",
        },
        {
          id: "uk",
          content: "UK settings",
          accessibilityLabel: "UK settings",
          panelID: "uk",
        },
      ];
    }

    tabs = [
      ...tabs,
      {
        id: "auto",
        content: t("taxSettings.autopilotDetails"),
        accessibilityLabel: "Autopilot (details)",
        panelID: "auto",
      },
    ];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            {t("taxSettings.taxSettings")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("taxSettings.vatReturnsAndSalesTax")}
          </Text>
        }
        fullWidth
      >
        <PageHelmet title={"Tax Settings"} />

        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={(v) => this.setState({ selected: v })}
        />

        <GuideCompletionPopup
          title={t("userGuide.completed3")}
          description={t("userGuide.completed3_desc")}
          visible={
            userGuide &&
            userGuide.show_user_guide &&
            userGuide.steps[2].show_banner
          }
          step={3}
        />

        <div className='taxSettings-section'>
          {selected === 0 && <VatRegistration {...this.props} />}

          {selected === 1 && this.renderSalesTaxPermit()}
          {selected === 1 && this.renderExemptins()}
          {selected === 1 && this.renderWaivers()}

          {selected === 2 && <EPRSettings />}
          
          {user.company.is_omp && selected === 3 && <MerchSettings />}
          {user.company.is_omp && selected === 4 && <UKSettings />}
          {((user.company.is_omp && selected === 5) ||
            (!user.company.is_omp && selected === 3)) && <AutopilotDetailsBuilder />}
          {/*{((user.company.is_omp && selected === 5) ||*/}
          {/*  (!user.company.is_omp && selected === 3)) && <AutoSubSettings />}*/}
        </div>

        {this.renderDeletePermitDialog()}
        {this.renderDeleteExempDialog()}
        {this.renderDeleteWaiverDialog()}
        {this.renderEditActivateDialog()}
        {this.renderEditDialog()}
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  salesTaxPermit: state.taxSettings.salesTaxPermit,
  creatingTaxPermit: state.taxSettings.creatingTaxPermit,
  certTypes: state.taxSettings.certTypes,
  exemptions: state.taxSettings.exemptions,
  creatingEx: state.taxSettings.creatingEx,
  exTypes: state.taxSettings.exTypes,
  editingTaxPermit: state.manager.editingTaxPermit,
  usStates: state.taxSettings.usStates,
  waivers: state.taxSettings.waivers,
  creatingWaivers: state.taxSettings.creatingWaivers,
  defaultLanguage: state.user.defaultLanguage,
  merchants: state.merchSettings.merchants,
  newMessagesCount: state.messenger.historyNewMessagesCount,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    editSalesTaxPermit: (params) => dispatch(editSalesTaxPermit(params)),
    createSalesTaxPermit: (params) => dispatch(createSalesTaxPermit(params)),
    createExeption: (exempCert, exempState, exempSelected, files) =>
      dispatch(createExeption({ exempCert, exempState, exempSelected, files })),
    editExemption: (params) => dispatch(editExemption(params)),
    fetchWaivers: () => dispatch(fetchWaivers()),
    createWaiver: (waiverCert, waiverState, waiverSelected, files) =>
      dispatch(
        createWaiver({ waiverCert, waiverState, waiverSelected, files })
      ),
    fetchSalesTaxPermit: () => dispatch(fetchSalesTaxPermit()),
    fetchExemptions: () => dispatch(fetchExemptions()),
    downloadExemptionFile: () => dispatch(downloadExemptionFile()),
    deleteExeption: (params) => dispatch(deleteExeption(params)),
    deleteSalesTaxPermit: (params) => dispatch(deleteSalesTaxPermit(params)),
    downloadWaiverFile: () => dispatch(downloadWaiverFile()),
    deleteWaiver: (params) => dispatch(deleteWaiver(params)),
    switchCalculateThresholdsNexus: (params) =>
      dispatch(switchCalculateThresholdsNexus(params)),
    switchCalculateRealTime: (params) =>
      dispatch(switchCalculateRealTime(params)),
    editActivateVatReg: (params) => dispatch(editActivateVatReg(params)),
    editWaiver: (params) => dispatch(editWaiver(params)),
    fetchMerch: () => dispatch(fetchMerch()),
    fetchCertTypes: () => dispatch(fetchCertTypes()),
    fetchExTypes: () => dispatch(fetchExTypes()),
    fetchStates: () => dispatch(fetchStates()),
    fetchRegNumbers: () => dispatch(fetchRegNumbers()),
    getAllNotCompletedOrders: () => dispatch(getAllNotCompletedOrders()),
    fetchRegCountries: () => dispatch(fetchRegCountries()),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchMerchSettingsMassages: () => dispatch(fetchMerchSettingsMassages()),
    fetchUndisclosedScheme: () => dispatch(fetchUndisclosedScheme()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxSettings))
);
