import React from "react";
import classNames from "classnames";
import "./index.scss";

const SaveBar = (props) => (
  <div className={classNames("SaveBar", { fullWidth: props.fullWidth })}>
    <div className='SaveBarWrapper'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {props.children}
      </div >
    </div >
  </div >
);

export default SaveBar;
