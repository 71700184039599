import { Badge } from '@shopify/polaris'

export const getInvoiceTypeColumns = (invoiceType,
	commonColumns,
	query,
	documents,
	getBadgeStatus,
	renderActions,
	getCurrencySymbol,
	formatMoney,
	selectedRows) => {
	const checkedStyle = (value) => {
		return selectedRows.includes(value.id)
	}
	
	switch (invoiceType) {
		case 'invoice':
			return [...commonColumns, {
				property: "company", header: {label: "To"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.company || '-'}
				        </div >);
					},],
				},
			}, {
				property: "inv_number", header: {label: "Invoice Number"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.inv_number || '-'}
				        </div >);
					},],
				},
			}, {property: "invoice_type", header: {label: "Invoice Type"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.invoice_type || '-'}
				        </div >);
					},],
				},}, {
				property: "tax_number",
				header: {label: `${query?.type === 'invoice-sales-tax' ? 'Customer Tax Number' : 'Customer VAT Number'}`},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.tax_number || '-'}
				        </div >);
					},],
				},
			}, {
				property: "supplier_country", header: {label: "Customer Country"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.country?.name || "Unknown Country"}
				        </div >);
					},],
				},
			}, {
				property: "total", header: {label: "Total"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {`${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`}
				        </div >);
					},],
				},
			}, {
				property: "status", header: {label: "Status"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {<Badge status={getBadgeStatus(value)}>{value}</Badge >}
				        </div >);
					},],
				},
			}, {
				property: "actions", header: {label: ""}, cell: {
					formatters: [(value,
						{rowData}) => renderActions(rowData)],
				},
			},];
		
		case 'bill':
			return [...commonColumns, {
				property: "company", header: {label: "From"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.company || '-'}
				        </div >);
					},],
				},
			}, {
				property: "inv_number", header: {label: "Invoice Number"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.inv_number || '-'}
				        </div >);
					},],
				},
			}, {
				property: "invoice_type", header: {label: "Invoice Type"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.invoice_type || '-'}
				        </div >);
					},],
				},
			}, {
				property: "tax_number",
				header: {label: `${query?.type === 'invoice-sales-tax' ? 'Supplier Tax Number' : 'Supplier VAT Number'}`},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.tax_number || "-"}
				        </div >);
					},],
				},
			}, {
				property: "supplier_country", header: {label: "Supplier Country"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.country?.name || "Unknown Country"}
				        </div >);
					},],
				},
			}, {
				property: "total", header: {label: "Total"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {`${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`}
				        </div >);
					},],
				},
			}, {
				property: "status", header: {label: "Status"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {<Badge status={getBadgeStatus(value)}>{value}</Badge >}
				        </div >);
					},],
				},
			}, {
				property: "actions", header: {label: ""}, cell: {
					formatters: [(value,
						{rowData}) => renderActions(rowData)],
				},
			},];
		
		default:
			return [...commonColumns, {
				property: "company", header: {label: "Company"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData?.company || '-'}
				        </div >);
					},],
				},
			}, {
				property: "inv_number", header: {label: "Invoice Number"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData?.inv_number || ''}
				        </div >);
					},],
				},
			}, {
				property: "invoice_type", header: {label: "Invoice Type"}, cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData?.invoice_type || ''}
				        </div >);
					},],
				},
			}, {
				property: "tax_number",
				header: {label: `${query?.type === 'invoice-sales-tax' ? 'Tax Number' : 'VAT Number'}`},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.tax_number || "-"}
				        </div >);
					},],
				},
			}, {
				property: "supplier_country", header: {label: "Country"},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {rowData.country?.name || "Unknown Country" || "-"}
				        </div >);
					},],
				},
			}, {
				property: "total", header: {label: "Total"},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {`${getCurrencySymbol(rowData.currency_id)} ${formatMoney(value)}`}
				        </div >);
					},],
				},
			}, {
				property: "status", header: {label: "Status"},
				cell: {
					formatters: [(value,
						{rowData}) => {
						const bgc = checkedStyle(rowData)
						const backgroundColor = bgc ? 'rgba(225, 227, 229, 0.5)' : 'transparent'
						return (<div
							className='changeLineInTableColor-ischecked'
							style={{backgroundColor}}
						>
				          {<Badge status={getBadgeStatus(value)}>{value}</Badge >}
				        </div >);
					},],
				},
			}, {
				property: "actions", header: {label: ""}, cell: {
					formatters: [(value,
						{rowData}) => renderActions(rowData)],
				},
			},];
	}
};
