import React, { useEffect, useState } from "react";
import { checkAuthenticationCode } from '../../redux/features/auth/authSlice'
import AuthContainer from "../Authentication/AuthContainer";
import { Box, Button, Text, TextField } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "hooks/useAppDispatch";

const validateCode = (code) => {
  return +code && code.length >= 4;
};

const CodeConfirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    checkAuthenticationCodeError: error,
    checkAuthenticationCodeLoading: loading,
  } = useSelector((state) => state.auth);
  const [code, setCode] = useState("");

  const handleConfirm = () => {
    dispatch(checkAuthenticationCode({ code, api_key: state.api_key })).then(
      (res) => {
        if (res.api_key) {
          navigate("/dashboard");
        }
      }
    );
  };

  useEffect(() => {
    if (!state || !state.api_key) {
      navigate("/login");
    }
  }, [state, navigate]);

  return (
    <AuthContainer
      title={"Complete Two-Factor Authentication"}
      description={"The code has been sent to your mobile device."}
      secondaryContent={
        // <Box align='end' alignY='center' spacing='4'>
        <Box display="flex" alignItems="center" gap="4">
          <Text color='subdued'>Return to login page?</Text>
          <Button onClick={() => navigate("/login")}>Go back</Button>
        </Box>
      }
      content={
        <TextField
          label='Code'
          value={code}
          onChange={(code) => setCode(code)}
          error={error}
          connectedRight={
            <Button
              primary
              onClick={handleConfirm}
              disabled={!validateCode(code)}
              loading={loading}
            >
              Confirm
            </Button>
          }
        />
      }
    />
  );
};

export default CodeConfirmation;
