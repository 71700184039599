import React, { useState } from "react";
import {
  Button,
  TextField,
  Banner,
  ButtonGroup,
  Spinner,
  Text,
  Checkbox, Box,
} from "@shopify/polaris";
import _ from "lodash";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { deleteCompany } from '../../../../redux/features/other/otherSlice'

const DeleteCompany = (props) => {
  const dispatch = useAppDispatch();
  const { deletingCompany, errorDeletingCompany } = useSelector(
    (state) => state.other
  );
  const [confirm, setConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [blurList, setBlurList] = useState([]);
  const [actionDialogs, setActionDialogs] = useState({
    whatYouNeedToKnow: { open: false },
    confirmPassword: { open: false },
  });

  const handleBlur = (input) => {
    setBlurList((prevBlurList) => [...prevBlurList, input.target.id]);
  };

  const handleActionDialogsClose = (name) => {
    setActionDialogs((prevActionDialogs) => ({
      ...prevActionDialogs,
      [name]: {
        ...prevActionDialogs[name],
        open: false,
      },
      cellData: {},
    }));
  };

  const handleActionDialogsOpen = (name, data = {}) => {
    setActionDialogs((prevActionDialogs) => ({
      ...prevActionDialogs,
      [name]: {
        open: true,
      },
      cellData: data,
    }));
  };

  const renderWhatYouNeedToKnow = () => {
    const onClose = () => handleActionDialogsClose("whatYouNeedToKnow");

    const onDelete = () => {
      onClose();

      handleActionDialogsOpen("confirmPassword");
    };

    return (
      <Modal
        title={"What you need to know"}
        iconType={"danger"}
        description={<></>}
        onClose={onClose}
        visible={actionDialogs.whatYouNeedToKnow.open}
        content={
          <div style={{ maxWidth: "400px" }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="6"
              width="100%"
            >
              <Text>
                Please note that if you choose to Delete your Lovat account, you
                will immediately lose access to your account. Your data will
                also be scheduled for permanent deletion thirty days later and
                cannot be recovered after that date. As per our terms of
                service, we are unable to offer a pro-rata refund for any
                remaining period of unused subscription.
              </Text>
              <Text>
                Alternatively, if you'd like to stop paying for Lovat for now
                but think you might want to use it again in the future, you can
                cancel your subscription instead. If you do this, you will lose
                access to your account immediately but we'll store your account
                data securely for one year before automatically deleting it.
                This option would allow you to reactivate your account at any
                time within one year of the cancellation date.
              </Text>
              <Text>
                Please note that you’ll need Owner access to your Lovat account
                to be able to delete your account.
              </Text>
              <Checkbox
                label={
                  <Text color='subdued' variant='bodySm'>
                    I have read the above terms & conditions and agree to delete
                    my Lovat account.
                  </Text>
                }
                checked={confirm}
                onChange={() => setConfirm((confirm) => !confirm)}
              />
            </Box>
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' fullWidth onClick={onClose}>
              Cancel
            </Button>
            <Button
              destructive
              size='large'
              fullWidth
              disabled={!confirm}
              onClick={onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderConfirmPassword = () => {
    const onClose = () => handleActionDialogsClose("confirmPassword");

    const onDelete = () => {
      dispatch(deleteCompany(password)).then((res) => {
        onClose();

        window.location.reload();
      });
    };

    return (
      <Modal
        title={"Confirm your password"}
        iconType={"danger"}
        description={<></>}
        onClose={onClose}
        visible={actionDialogs.confirmPassword.open}
        content={
          <div style={{ maxWidth: "400px" }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="6"
              width="100%"
            >
              {errorDeletingCompany && (
                <Banner status='critical'>{errorDeletingCompany}</Banner>
              )}
              <Text fontWeight='medium'>
                Please verify that you understand the following:
              </Text>
              <Text>
                All of your account information will be permanently erased.
              </Text>
              <Text>
                All of your transactions, tax returns, files, attachments, and
                uploaded documents will be permanently erased.
              </Text>
              <Text>
                Any Direct Debit mandates you've created through Lovat will be
                permanently erased.
              </Text>
              <TextField
                id='password'
                type='password'
                value={password}
                onChange={(value) => setPassword(value)}
                label={
                  <Text color='subdued' variant='bodySm'>
                    Confirm your password
                  </Text>
                }
                error={blurList.includes("password") && !password}
                onBlur={handleBlur}
              />
            </Box>
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' fullWidth onClick={onClose}>
              Cancel
            </Button>
            <Button
              destructive
              size='large'
              fullWidth
              disabled={!password}
              onClick={onDelete}
              loading={deletingCompany}
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  return (
    <div>
      {_.isEmpty(props.user) ? (
        <Spinner />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap="2"
          >
            <Text variant='headingMd'>
              Want to{" "}
              <Button
                destructive
                plain
                onClick={() => handleActionDialogsOpen("whatYouNeedToKnow")}
              >
                delete
              </Button>{" "}
              your account?
            </Text>
            <Text variant='bodyMd' color='subdued'>
              Please note that you’ll need Owner access to your Lovat account to
              be able to delete your account.
            </Text>
          </Box>
        </>
      )}
      {renderWhatYouNeedToKnow()}
      {renderConfirmPassword()}
    </div>
  );
};

export default DeleteCompany;
