import useAppDispatch from "hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchDocuments } from '../../../../../redux/features/documents/documentsSlice'

const useFetchDocuments = (query) => {
  const dispatch = useAppDispatch();
  const { documents, fetchingDocuments: loading } = useSelector(
    (state) => state.documents
  );

  const fetchDocumentsData = () => {
    dispatch(fetchDocuments(query));
  };

  useEffect(() => {
    fetchDocumentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { documents, loading, fetchDocuments: fetchDocumentsData };
};

export default useFetchDocuments;
