import {
	ActionList,
	Box,
	Button,
	ButtonGroup,
	Card,
	DataTable,
	Inline,
	Layout,
	Popover,
	Spinner,
	Text,
	Tooltip,
} from "@shopify/polaris"
import NoDataMessage from "components/NoDataMessage"
import Pagination from "components/Pagination/Pagination"
import { DocumentType } from "containers/DocumentTemplate/common/constants"
import useActionDialogs from "hooks/useActionDIalogs"
import AddIcon from "img/add-white.svg"
import continueIMG from "img/continue.svg"
import deleteIMG from "img/delete.svg"
import downloadIMG from "img/download.svg"
import importIMG from "img/import.svg"
import settingsIMG from "img/settings.svg"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { formatDate } from "utils/dates"
import formatRow from "utils/formatRow"
import paginate from "utils/paginate"
import useAppDispatch from '../../../../hooks/useAppDispatch'
import { deleteVatInvoiceBulk } from '../../../../redux/features/documents/documentsSlice'
import { getCurrencySymbol } from '../../../../utils/currency'
import { formatMoney } from '../../../../utils/numbers'
import ConvertToTransactions from '../../modals/ConvertToTransactions'
import DeleteInvoiceModal from "../../modals/DeleteInvoice"
import ScanFiles from "../../modals/ScanFiles"
import SendFormEInvoicesBuilder from '../../modals/SendFormEInvoicesFunctional/SendFormEInvoicesBuilder'
import UploadFromXero from '../../modals/UploadFromXero'
import DeleteInvoiceBulkModal from './DeleteInvoiceBulkModal'
import useFetchDocuments from "./hooks/useFetchDocuments"
import usePagination from "./hooks/usePagination"
import useSelectedRows from "./hooks/useSelectedRows"
import { getColumns } from './ShablonColumnsTable'
import './styleInvoices.scss'

const defaultLimit = 25

const actionDialogsKeys = {
	deleteVatInvoice: "deleteVatInvoice",
	deleteVatInvoiceBulk: "deleteVatInvoiceBulk",
	uploadFromXero: "uploadFromXero",
	sendEInvoices: "sendEInvoices",
	scanFiles: "scanFiles",
	convertToTransactions: 'convertToTransactions'
}

const invoiceTypes = {
	all: "all",
	invoice: "invoice",
	bill: "bill",
}

const Invoices = ({documentType}) => {
	const navigate = useNavigate()
	const {t} = useTranslation()
	const [active, setActive] = useState(null)
	const {countDocuments} = useSelector((state) => state.documents)
	const {actionDialogs, handleActionDialogsOpen, handleActionDialogsClose} =
		useActionDialogs(Object.values(actionDialogsKeys))
	const {pagination, onPageChange} = usePagination(countDocuments, defaultLimit)
	const dispatch = useAppDispatch()
	const [query, setQuery] = useState({
		invoice_type: invoiceTypes.all,
		type: documentType,
		offset: 0,
		limit: 25
	})
	const {documents, loading, fetchDocuments} = useFetchDocuments(query)
	const {selectedRows, handleSelectAll, handleRowSelect} = useSelectedRows(documents)
	
	const handleInvoiceTypeChange = (type) => {
		setQuery((prev) => ({...prev, invoice_type: type}))
	}
	const handleInvoicePageChange = () => {
		setQuery((prev) => ({...prev, offset: pagination.page - 1}))
	}
	
	useEffect(() => {
		handleInvoicePageChange()
	}, [pagination.page])
	
	const handleBulkAction = (action) => {
		if (action === 'delete') {
			const filterDocs = documents.filter(doc => {
				const res = selectedRows.find(item => item === doc.id)
				return res
			})
			filterDocs.length !== 0 && handleActionDialogsOpen(actionDialogsKeys.deleteVatInvoiceBulk, filterDocs)
		}
	}
	
	// const downloadFile = (link) => {
	// 	const from = link.indexOf("/") + 1
	// 	// const fileName = link.substring(from)
	// 	const fileName = link.split("/").pop()
	// 	const a = document.createElement("a")
	// 	console.log('fileName', fileName)
	// 	console.log('link.substring(from)', link.substring(from))
	// 	console.log('a', a)
	// 	a.setAttribute("href", link)
	// 	a.setAttribute("download", fileName)
	// 	a.style.display = "none"
	// 	document.body.appendChild(a)
	// 	a.click()
	// 	window.URL.revokeObjectURL(link)
	// 	document.body.removeChild(a)
	// }
	
	const downloadFile = (link) => {
		const fileName = link.split("/").pop(); // Корректное извлечение имени файла
		const absoluteLink = new URL(link, window.location.origin).href; // Абсолютная ссылка
		const a = document.createElement("a");
		console.log("fileName", fileName);
		console.log("absoluteLink", absoluteLink);
		
		a.setAttribute("href", absoluteLink);
		a.setAttribute("download", fileName);
		a.style.display = "none";
		
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a); // Удаление элемента после клика
	};
	
	const getBadgeStatus = (status) => {
		switch (status) {
			case "sent":
			case "paid":
				return "success"
			case "draft":
				return "new"
			default:
				return "attention"
		}
	}
	
	const renderCreateDocumentButton = (documentType) => {
		switch (documentType) {
			case DocumentType.InvoiceVAT:
				return (
					<Button
						style={{ width: '250px' }}
						primary
						onClick={() => navigate("/documents/invoice-vat/create")}
						icon={
							<img
								src={AddIcon}
								alt='add'
								width='15'
								style={{marginTop: 4, marginRight: 5}}
							/>
						}
					>
            Create VAT Invoice
          </Button >
				)
			
			case DocumentType.InvoiceSalesTax:
				return (
					<Button
						style={{ maxWidth: 250}}
						primary
						onClick={() => navigate("/documents/invoice-sales-tax/create")}
						icon={
							<img
								src={AddIcon}
								alt='add'
								width='15'
								style={{marginTop: 4, marginRight: 5}}
							/>
						}
					>
            Create Sales Tax Invoice
          </Button >
				)
			case DocumentType.Exemption:
				return (
					<Button
						style={{ maxWidth: 250}}
						primary
						onClick={() => navigate("/documents/exemption/create")}
						icon={
							<img
								src={AddIcon}
								alt='add'
								width='15'
								style={{marginTop: 4, marginRight: 5}}
							/>
						}
					>
            Create Exemption
          </Button >
				)
			
			default:
				return null
		}
	}
	
	const renderActions = (rowData) => (
		<Box
			display="flex"
			alignItems="end"
			justifyContent="center"
			gap="4"
		>
      {rowData.status === "draft" && (
	      <Tooltip content={t("vatReturns.continue")}>
          <Button
	          plain
	          onClick={() =>
		          navigate(`/documents/${documentType}/create`, {
			          state: rowData.data,
		          })
	          }
          >
            <img
	            src={continueIMG}
	            alt='continue'
            />
          </Button >
        </Tooltip >
      )}
			{rowData.status === "draft" && (
				<Tooltip content={t("vatReturns.delete")}>
          <Button
	          plain
	          onClick={() =>
		          handleActionDialogsOpen(
			          actionDialogsKeys.deleteVatInvoice,
			          rowData
		          )
	          }
          >
            <img
	            src={deleteIMG}
	            alt='delete'
            />
          </Button >
        </Tooltip >
			)}
			<Tooltip content={t("vatReturns.downloadFile")}>
        <Button
	        plain
	        onClick={() => downloadFile(rowData.file)}
        >
          <img
	          src={downloadIMG}
	          alt='download'
          />
        </Button >
      </Tooltip >
    </Box >
	)
	
	const columns = getColumns(selectedRows, formatDate, documents, handleSelectAll, handleRowSelect, query, getBadgeStatus, renderActions, invoiceTypes, getCurrencySymbol, formatMoney)
	const sortedRows = formatRow(documents, columns?.columns)
	const paginatedRows = paginate(pagination)(sortedRows)
	const {rows} = paginatedRows
	return (
		<Layout.Section >
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <ButtonGroup segmented>
          <Button
	          primary={query.invoice_type === invoiceTypes.all}
	          onClick={() => handleInvoiceTypeChange(invoiceTypes.all)}
          >
            All
          </Button >
          <Button
	          primary={query.invoice_type === invoiceTypes.invoice}
	          onClick={() => handleInvoiceTypeChange(invoiceTypes.invoice)}
          >
            Invoices
          </Button >
          <Button
	          primary={query.invoice_type === invoiceTypes.bill}
	          onClick={() => handleInvoiceTypeChange(invoiceTypes.bill)}
          >
            Bills
          </Button >
        </ButtonGroup >
        <div
	        style={{
						display: 'flex',
		        flexDirection: 'row',
		        justifyContent: 'space-between',
	        }}
        >
          {renderCreateDocumentButton(documentType)}
	        <Popover
		        active={active === "uploads-popover"}
		        preferredPosition='below'
		        fullWidth
		        activator={
			        <button
				        fullWidth
				        className={'button-upload-options-style'}
				        onClick={() =>
					        setActive(
						        active === "uploads-popover" ? null : "uploads-popover"
					        )
				        }
			        >
                <img
	                src={importIMG}
	                alt='upload'
	                width='15'
	                style={{marginTop: 4, marginRight: 5}}
                />
                Upload options
              </button >
		        }
		        onClose={() => setActive(null)}
	        >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Sending E-invoices",
			            onAction: () =>
				            handleActionDialogsOpen(actionDialogsKeys.sendEInvoices),
		            },
		            {
			            content: "Upload from Xero",
			            onAction: () =>
				            handleActionDialogsOpen(actionDialogsKeys.uploadFromXero),
		            },
		            {
			            content: "Scan files",
			            onAction: () =>
				            handleActionDialogsOpen(actionDialogsKeys.scanFiles),
		            },
		            {
			            content: "Convert to transactions",
			            onAction: () =>
				            handleActionDialogsOpen(actionDialogsKeys.convertToTransactions),
		            },
	            ]}
            />
          </Popover >
          
          <Popover
	          active={active === "bulk-actions-popover"}
	          activator={
		          <Button
			          onClick={() =>
				          setActive(
					          active === "bulk-actions-popover"
						          ? null
						          : "bulk-actions-popover"
				          )
			          }
			          icon={
				          <img
					          src={settingsIMG}
					          alt='edit'
					          width='15'
					          style={{marginTop: 2, marginRight: 3}}
				          />
			          }
		          >
                Group Actions
              </Button >
	          }
	          onClose={() => setActive(null)}
          >
            <ActionList
	            actionRole='menuitem'
	            items={[
		            {
			            content: "Delete",
			            onAction: () => handleBulkAction('delete')
		            },
		            {
			            content: "Mark as Paid",
			            onAction: () => handleBulkAction("markPaid"),
		            },
		            {
			            content: "Mark as Sent",
			            onAction: () => handleBulkAction("markSent"),
		            },
		            {
			            content: "Approve",
			            onAction: () => handleBulkAction("approve"),
		            },
	            ]}
            />
          </Popover >
        </div >
      </div >
      <br />
      <Card >
        {loading ? (
	        <Card.Section >
            <Spinner />
          </Card.Section >
        ) : (
	        <div className='changeLineInTableColor'>
		        <DataTable
			        columnContentTypes={Array(columns?.columns?.length).fill('text')}
			        verticalAlign='center'
			        stickyHeader={true}
			        headings={columns?.columns?.map(({ header }) => (
				        <Text fontWeight="semibold" key={header.label}>
					        {header.label}
								</Text>
			        ))}
			        rows={rows}
		        />
		        {rows.length === 0 && (
			        <NoDataMessage
				        description={"To see documents in the list, first add them."}
				        buttonText={"Add Documents from templates"}
			        />
		        )}
          </div>
        )}
      </Card >
			
			{rows.length > 0 && (
				<Box padding='4'>
          <Pagination
	          total={countDocuments}
	          current={pagination.page}
	          pageSize={defaultLimit}
	          onChange={(current) => onPageChange(current)}
          />
        </Box >
			)}
			
			<DeleteInvoiceModal
				isOpen={actionDialogs[actionDialogsKeys.deleteVatInvoice].open}
				data={actionDialogs[actionDialogsKeys.deleteVatInvoice].cellData}
				onClose={() =>
					handleActionDialogsClose(actionDialogsKeys.deleteVatInvoice)
				}
				afterDelete={fetchDocuments}
			/>

      <SendFormEInvoicesBuilder
	      isOpen={actionDialogs[actionDialogsKeys.sendEInvoices].open}
	      data={actionDialogs[actionDialogsKeys.sendEInvoices].cellData}
	      onClose={() =>
		      handleActionDialogsClose(actionDialogsKeys.sendEInvoices)
	      }
	      afterUpload={fetchDocuments}
	      pagination={pagination}
	      documents={documents}
	      query={query}
	      getBadgeStatus={getBadgeStatus}
	      renderActions={renderActions}
	      invoiceTypes={invoiceTypes}
	      selectedRows={selectedRows}
	      handleSelectAll={handleSelectAll}
	      handleRowSelect={handleRowSelect}
      />
      <UploadFromXero
	      isOpen={actionDialogs[actionDialogsKeys.uploadFromXero].open}
	      data={actionDialogs[actionDialogsKeys.uploadFromXero].cellData}
	      onClose={() =>
		      handleActionDialogsClose(actionDialogsKeys.uploadFromXero)
	      }
	      afterUpload={fetchDocuments}
      />
      <ConvertToTransactions
	      isOpen={actionDialogs[actionDialogsKeys.convertToTransactions].open}
	      data={actionDialogs[actionDialogsKeys.convertToTransactions].cellData}
	      onClose={() =>
		      handleActionDialogsClose(actionDialogsKeys.convertToTransactions)
	      }
	      afterUpload={fetchDocuments}
      />

      <ScanFiles
	      isOpen={actionDialogs[actionDialogsKeys.scanFiles].open}
	      data={actionDialogs[actionDialogsKeys.scanFiles].cellData}
	      onClose={() => handleActionDialogsClose(actionDialogsKeys.scanFiles)}
	      afterScan={fetchDocuments}
      />
      <DeleteInvoiceBulkModal
	      isOpen={actionDialogs[actionDialogsKeys.deleteVatInvoiceBulk].open}
	      data={actionDialogs[actionDialogsKeys.deleteVatInvoiceBulk].cellData}
	      onClose={() => handleActionDialogsClose(actionDialogsKeys.deleteVatInvoiceBulk)}
	      query={query}
      />
    </Layout.Section >
	)
}

export default Invoices
