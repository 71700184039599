
import useAppDispatch from "hooks/useAppDispatch";
import { fetchDocumentsMessages } from '../../../redux/features/documents/documentsSlice'
import { fetchNewMessagesCount, resetSystemMessage } from '../../../redux/features/messenger/messengerSlice'

const useBannerActions = () => {
  const dispatch = useAppDispatch();

  const getBannerStatus = (status) => {
    switch (status) {
      case "ready":
        return "success";
      case "error":
        return "critical";
      default:
        return "warning";
    }
  };

  const onBannerDismiss = (message) => {
    dispatch(
      resetSystemMessage({
        modelsName: `documents`,
        id: message.id,
      })
    )
      .then(() => dispatch(fetchDocumentsMessages()))
      .then(() => dispatch(fetchNewMessagesCount()));
  };

  return { getBannerStatus, onBannerDismiss };
};

export default useBannerActions;
