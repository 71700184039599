import { Card, ChoiceList, Layout, Text } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import useAppDispatch from "hooks/useAppDispatch";
import { switchCompanyDefaultLanguage } from '../../../../redux/features/user/userSlice'

const Language = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { defaultLanguage } = useSelector(({ user }) => user);

  const switchDefaultLanguage = (lang) => {
    dispatch(switchCompanyDefaultLanguage({ lang }))
      .then(() => {
        i18n.changeLanguage(lang);
      })
      .catch(_.noop);
  };

  return (
    <Layout.AnnotatedSection
      title={
        <Text variant='headingLg' as='p'>
          {t("profileMemb.lang")}
        </Text>
      }
    >
      <Card title={t("profileMemb.lang")} sectioned>
        <ChoiceList
          choices={[
            { label: "English", value: "en" },
            { label: "Español", value: "es" },
            // { label: '汉语', value: 'ch' },
          ]}
          selected={defaultLanguage ? defaultLanguage.lang : "en"}
          onChange={(arr) => switchDefaultLanguage(arr[0])}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default Language;
