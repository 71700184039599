import { Button, ButtonGroup } from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { StepIndex } from "containers/DocumentTemplate/common/constants";
import useAppDispatch from "hooks/useAppDispatch";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createInvoice } from '../../../../redux/features/documents/documentsSlice'

const InvoicePreviewSaveBar = ({
  attachment,
  setStepIndex,
  data,
  previewInfo,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    invoice: { loading },
  } = useSelector((state) => state.documents);

  const getIsSubmitDisabled = () => {
    const { send_to, reply_to, subject, body } = previewInfo;

    return [send_to, reply_to, subject, body].some((item) => !Boolean(item));
  };

  const onSendInvoiceClick = () => {
    const { customerId, bankId, currency, taxId, ...restData } = data;

    const params = {
      action: "send",
      bank_id: bankId,
      tax_id: taxId,
      customer_id: customerId,
      ...previewInfo,
      ...restData,
    };

    dispatch(createInvoice({ params, attachments: attachment })).then(() => {
      navigate("/documents");
    });
  };

  const onEditInvoiceClick = () => setStepIndex(StepIndex.Edit);

  return (
    <SaveBar fullWidth>
      <ButtonGroup>
        <Button size='large' onClick={onEditInvoiceClick}>
          Edit Invoice
        </Button>
        <Button
          primary
          size='large'
          onClick={onSendInvoiceClick}
          loading={loading.send}
          disabled={getIsSubmitDisabled()}
        >
          Send Invoice
        </Button>
      </ButtonGroup>
    </SaveBar>
  );
};

export default InvoicePreviewSaveBar;
