import { Button, Text } from "@shopify/polaris";
import AuthContainer from "containers/Authentication/AuthContainer";
import React from "react";
import "./Unsubscribe.scss";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { unsubscribeNewsletter } from "../../redux/features/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";

const Unsubscribe = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { unsubscribedSuccessfully, loadingUnsubscribe } = useSelector(
    (state) => state.user
  );

  const onUnsubscribe = () => {
    dispatch(unsubscribeNewsletter(token)).then(() => {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    });
  };

  return (
    <AuthContainer
      title='Unsubscribe'
      description={
        !unsubscribedSuccessfully && "Are you sure want want to unsubscribe?"
      }
      content={
        !unsubscribedSuccessfully &&
        "We're sorry to see you go but if you no longer want to receive LOVAT newsletters please click on the button below."
      }
      actions={
        <div className='unsubscribe-button'>
          {unsubscribedSuccessfully ? (
            <Text>Unsubscribed!</Text>
          ) : (
            <Button onClick={onUnsubscribe} loading={loadingUnsubscribe}>
              Unsubscribe
            </Button>
          )}
        </div>
      }
    />
  );
};

export default Unsubscribe;
