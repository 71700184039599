import React, { useState } from "react";
import classNames from "classnames";
import Video from "components/Video/Video";
import { Box, Grid, Text } from "@shopify/polaris";
import Accordion from "components/Accordion/Accordion";

import GeneralImg from "img/faq/general.svg";
import VatImg from "img/faq/vat.svg";
import SalesTaxImg from "img/faq/salesTax.svg";
import SubscriptionImg from "img/faq/subscription.svg";
import TransactionsImg from "img/faq/transactions.svg";

import "./index.scss";

const RenderContentWithVideo = ({ text, src }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
    >
      <Text>{text}</Text>
      <Video iframeSrc={src} />
      <Text color='subdued'>
        Still have questions?{" "}
        <a
          className='Polaris-Link'
          href='mailto:support@vatcompliance.co'
          style={{ textDecoration: "none" }}
        >
          Contact us
        </a>
      </Text>
    </Box>
  );
};

const FAQSections = [
  {
    id: 1,
    title: "General",
    icon: GeneralImg,
    childrens: [
      {
        title: "How to complete your Profile",
        content: (
          <RenderContentWithVideo
            text='Make filling out your Lovat profile easier with our video tutorial. In this video, you will see how and in what sequence to fill in the data on the website, as well as supplement and change existing information. Also, you can add a new user who can manage information'
            src='https://www.youtube.com/embed/RJhPDrGTybU'
          />
        ),
      },
      {
        title: "How to order VAT registration",
        content: (
          <RenderContentWithVideo
            text="In this video, you will find step-by-step instructions for registering a VAT payer on the Lovat platform. It will be much easier for you to register with it, regardless of the country and type of business. If you still have questions after watching the video, just contact the Lovat's manager."
            src='https://www.youtube.com/embed/5ZZznZBZaSI'
          />
        ),
      },
      {
        title: "Stores and tax settings",
        content: (
          <RenderContentWithVideo
            text={`Find a step-by-step video tutorial to easily complete the "Shops" and "Tax Setup" sections. Here we review in detail each step you need to take during the filling process. Watch the video for all the details.`}
            src='https://www.youtube.com/embed/POcUjyb827M'
          />
        ),
      },
    ],
  },
  {
    id: 2,
    title: "VAT returns",
    icon: VatImg,
    childrens: [
      {
        title: "VAT return filing",
        content: (
          <RenderContentWithVideo
            text='Discover how to simplify VAT return filing with our innovative Lovat software! As a leading tax compliance and automation company, we empower online sellers, marketplaces, and digital sellers to streamline tax processes worldwide. Benefit from improved accuracy, time and cost savings, and user-friendly features. Our secure platform ensures data protection, and our dedicated support team is always ready to assist you. Join countless satisfied clients who vouch for the reliability and effectiveness of our solutions. Subscribe for more tax compliance and automation updates!'
            src='https://www.youtube.com/embed/Mri6-0Jfi7I'
          />
        ),
      },
      {
        title: "OSS VAT return filling",
        content: (
          <RenderContentWithVideo
            text='If you are interested in how to file an OSS tax return using the Lovat platform? Watch our step-by-step video tutorial that explains all the intricacies of the process and makes it as easy as possible for you. In the video you will find all the information you need, from verifying a registration number and adding it, to the detailed declaration process.'
            src='https://www.youtube.com/embed/R297KtX8QQU'
          />
        ),
      },
      {
        title: "IOSS VAT return filling",
        content: (
          <RenderContentWithVideo
            text='We leave you a step-by-step instructive video on the IOSS VAT return. Here you will find everything from verifying an IOSS registration number and transactions to filing a return. In addition, from the video you will learn a lot of information about the IOSS VAT return, which you definitely need to know for successful work.'
            src='https://www.youtube.com/embed/2oNTwgRbL2Q'
          />
        ),
      },
      {
        title: "Intra-community acquisitions and supplies filling - step 4",
        content: (
          <RenderContentWithVideo
            text={`We offer a step-by-step tutorial for filling out the “Intra-Community Acquisitions and Supplies” section through the Lovat's program. This is the fourth step in filing a VAT return - our video will greatly simplify and speed up the filling process. This includes instructions for “Intra-Community Acquisition” and “Intra-community Supplies”.`}
            src='https://www.youtube.com/embed/fro2lXQSe9A'
          />
        ),
      },
      {
        title: "How to file German annual VAT return",
        content: (
          <RenderContentWithVideo
            text={`At Lovat, we have created a detailed video tutorial for you on how to file your German VAT return. With its help, you can easily fill out a declaration on the Lovat’s website, greatly facilitating and speeding up the process. Each step comes with a detailed explanation and answers to common questions. For more details watch our video.`}
            src='https://www.youtube.com/embed/3wEEPjkK6Lo'
          />
        ),
      },
      {
        title: "How to add your incoming invoices to the VAT return",
        content: (
          <RenderContentWithVideo
            text={`If you are unable to add incoming invoices on the Lovat platform, we recommend that you watch this video tutorial. Here we clearly explain all the actions step by step so that you do not have any additional problems or questions. If you still need help, please contact the Lovat team.`}
            src='https://www.youtube.com/embed/fF-zbUwWFww'
          />
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Sales tax returns",
    icon: SalesTaxImg,
    childrens: [
      {
        title: "How to file sales tax return",
        content: (
          <RenderContentWithVideo
            text={`In this tutorial, we will show you how you can prepare and file your Sales Tax Return on the Lovat platform. If you want to learn how to prepare and file your sales tax return, watch this detailed video tutorial. In which we provide you with detailed information on the entire procedure, as well as answer any questions that may arise in the process. There are two options available to file the declaration through the Lovat platform.`}
            src='https://www.youtube.com/embed/hHvHz5pdybw'
          />
        ),
      },
      {
        title: "Sales tax economic nexus thresholds tracking",
        content: (
          <RenderContentWithVideo
            text={`Here is a detailed video tutorial on tracking sales tax economic nexus thresholds. In this video you will find all the necessary information on how to download sales data using the Lovat platform. In addition, you will find how to fill a profile up to the current sales threshold in different states of America.`}
            src='https://www.youtube.com/embed/oeX-NJEspak'
          />
        ),
      },
      {
        title:
          "How to File California Sales Tax with Stripe Integration: Step-by-Step Guide",
        content: (
          <RenderContentWithVideo
            text={`In this video, we'll walk you through the entire process of preparing and filing your sales tax return, specifically for the state of California. Whether you're a small business owner or an accountant, this guide will help you streamline your tax filing process.`}
            src='https://www.youtube.com/embed/hvjmi1cyKns'
          />
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Subscription",
    icon: SubscriptionImg,
    childrens: [
      {
        title: "How to choose your subscription plan",
        content: (
          <RenderContentWithVideo
            text={`Make filling out your Lovat profile easier with our video tutorial. In this video, you will see how and in what sequence to fill in the data on the website, as well as supplement and change existing information. Also, you can add a new user who can manage information.`}
            src='https://www.youtube.com/embed/RJhPDrGTybU'
          />
        ),
      },
    ],
  },
  {
    id: 5,
    title: "Transactions",
    icon: TransactionsImg,
    childrens: [
      {
        title: "How to upload your Shopify transactions",
        content: (
          <RenderContentWithVideo
            text={`Here is a step by step video tutorial on how to upload your Shopify transactions using the Lovat software. All actions are performed in the "Transactions" section - it is convenient, fast and clear. Watch our video for more details.`}
            src='https://www.youtube.com/embed/7fjhqMP1M5Q'
          />
        ),
      },
      // TODO: Change the link and uncomment once a new link is available.

      // {
      //   title: "Manual upload of transactions",
      //   content: (
      //     <RenderContentWithVideo
      //       text={`Never filling out and submitting a VAT return has been easier than with the Lovat software. By watching this video, you will understand why. In the tutorial, we'll walk you through how to quickly and manually fill out a VAT return so that you don't have any problems or questions.`}
      //       src='https://www.youtube.com/embed/qouT_7A4reY'
      //     />
      //   ),
      // },
    ],
  },
  {
    id: 6,
    title: "EPR (Extended Producer Responsibility)",
    icon: VatImg,
    childrens: [
      {
        title: "4 easy steps to EPR reporting",
        content: (
          <RenderContentWithVideo
            text={`Extended Producer Responsibility (EPR) is a new type of environmental policy that places responsibility on the shoulders of the manufacturer to manage the waste caused by the different types of products they market in order to reduce their environmental impact.`}
            src='https://www.youtube.com/embed/ma4U5PSRBBk'
          />
        ),
      },
    ],
  },
  {
    id: 7,
    title: "Marketplaces",
    icon: SubscriptionImg,
    childrens: [
      {
        title: "DAC7 Return Filing",
        content: (
          <RenderContentWithVideo
            text={`Easily file DAC7 reports with Lovat. Gather all data smoothly in your platform, and file your DAC7, VAT reports — in one place. Get set by following these four simple steps. DAC7 reporting`}
            src='https://www.youtube.com/embed/sPX6B1o5AoM'
          />
        ),
      },
    ],
  },
  {
    id: 8,
    title: "Integrations",
    icon: SubscriptionImg,
    childrens: [
      {
        title: "How to Add Stripe Integration with Lovat: Step-by-Step Guide",
        content: (
          <RenderContentWithVideo
            text={`In this video, we’ll guide you through the entire process of connecting your Stripe account to Lovat, ensuring seamless VAT compliance for your business.`}
            src='https://www.youtube.com/embed/s5e6WjrhTtc'
          />
        ),
      },
    ],
  },
];

const FAQ = () => {
  const [activeSection, setActiveSection] = useState(FAQSections[0]);

  return (
    <div className='FAQ'>
      <Box maxWidth='500'>
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
        >
          <Text variant='headingLg' as='h2'>
            Find the answer quickly
          </Text>
          <Text variant='bodyMd' as='span' color='subdued'>
            In this section you can find the most frequently asked questions and
            answers on them. Also, you can watch our videos with the
            instructions
          </Text>
        </Box>
      </Box>

      <br />
      <Box>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 2, md: 2, lg: 3, xl: 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="2"
            >
              {FAQSections.map((section) => {
                const { title, icon, id } = section;

                return (
                  <div
                    className={classNames("FAQ__category", {
                      active: id === activeSection.id,
                    })}
                    onClick={() => setActiveSection(section)}
                    key={id}
                  >
                    <img alt='img' src={icon} />
                    <Text variant='headingMd' as='h3'>
                      {title}
                    </Text>
                    <svg
                      className='FAQ__category-arrows'
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 11L6 6L1 1M8 11L13 6L8 1'
                        stroke='#C2C2C2'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                );
              })}
            </Box>
            <br />
            <Box
              display="flex"
            >
              <Text variant='bodyMd' as='span' color='subdued'>
                Looking for something else?
              </Text>
              <a
                className='Polaris-Link'
                style={{ textDecoration: "none" }}
                href='mailto:support@vatcompliance.co'
              >
                Contact us
              </a>
            </Box>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 4, md: 4, lg: 9, xl: 9 }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="2"
            >
              {activeSection.childrens?.map((item) => (
                <Accordion {...item} key={item.title} />
              ))}
            </Box>
          </Grid.Cell>
        </Grid>
      </Box>
      <br />
    </div>
  );
};

export default FAQ;
