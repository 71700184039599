import { Box, Checkbox, Inline } from '@shopify/polaris'
import Datepicker from 'components/Datepicker'
import { t } from 'i18next'
import React from 'react'

const StepChangeDate = ({fromDate, setFromDate, toDate, setToDate}) => {
	return (
		<>
			<Box
				display="flex"
			>
        <Datepicker
	        label={t("subscription.from")}
	        allowRange={false}
	        value={fromDate}
	        onChange={(date) => {
		        setFromDate(date);
	        }}
	        autoComplete='off'
        />
        <Datepicker
	        label={t("subscription.till")}
	        allowRange={false}
	        value={toDate}
	        onChange={(value) => setToDate(value)}
	        autoComplete='off'
        />
      </Box >
			<br/>
		</ >
	)
}

export default StepChangeDate