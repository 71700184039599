import React from "react";
import { Text, Link, Box } from "@shopify/polaris";

export default function InlineText(props) {
  return (
    <Box display="flex" alignItems="start">
      <Text>{props.label}:</Text>
      {props.linkText ? (
        <Link onClick={props.onLinkNavigate}>{props.linkText}</Link>
      ) : (
        <Text color='subdued'>{props.text}</Text>
      )}
    </Box>
  );
}
