import React, { useState } from "react";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Layout,
  Text,
  TextField,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useAppDispatch from "hooks/useAppDispatch";
import { editUser } from '../../../../redux/features/user/userSlice'

const PersonalDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, editing } = useSelector(({ user }) => user);
  const [{ name, surname, email }, setPersonalDetails] = useState({
    name: user?.first_name || "",
    surname: user?.last_name || "",
    email: user?.email || "",
  });
  const [{ isSuccess, isError }, setStatus] = useState({
    isSuccess: false,
    isError: false,
  });

  const onEditPersonalDetails = (key, value) => {
    setPersonalDetails((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onEditSubmit = () => {
    dispatch(
      editUser({
        id: user.id,
        email,
        first_name: name,
        last_name: surname,
      })
    )
      .then(() => setStatus((state) => ({ ...state, isSuccess: true })))
      .catch(() => setStatus((state) => ({ ...state, isError: true })));
  };

  return (
    <Layout.AnnotatedSection
      title={
        <Text variant='headingLg' as='p'>
          {t("profile.personalSettings.personalDetails")}
        </Text>
      }
      description={
        <Text color='subdued'>
          {t("profile.personalSettings.manageProfileDets")}
        </Text>
      }
    >
      <Card sectioned>
        <FormLayout>
          {isSuccess && (
            <Banner
              title='Personal details were successfully changed!'
              status='success'
              onDismiss={() =>
                setStatus((state) => ({ ...state, isSuccess: false }))
              }
            />
          )}
          {isError && (
            <Banner
              title='Something went wrong!'
              status='critical'
              onDismiss={() =>
                setStatus((state) => ({ ...state, isError: false }))
              }
            />
          )}
          <FormLayout.Group>
            <TextField
              fullWidth
              label={t("profileUsers.name")}
              value={name}
              onChange={(value) => onEditPersonalDetails("name", value)}
            />
            <TextField
              fullWidth
              label={t("profileUsers.surname")}
              value={surname}
              onChange={(value) => onEditPersonalDetails("surname", value)}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              fullWidth
              label={t("profileUsers.email")}
              type='email'
              value={email}
              onChange={(value) => onEditPersonalDetails("email", value)}
            />
            <br />
          </FormLayout.Group>
          <ButtonGroup>
            <Button primary onClick={onEditSubmit} loading={editing}>
              {t("reports.save")}
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default PersonalDetails;
